<div class="page">
    <div class="page-padding">
        <div class="page-header-custom secondary mb-5">
            <svg-icon routerLink="/physical-security" src="assets/svg/arrow.svg" class="is-cursor"></svg-icon>
            <h1 class="page-title secondary"><span>Segurança </span>Fisica</h1>
        </div>
        <div class="row  justify-content-between align-items-center">
            <div class="col-sm-4">
                <div class="page-card p-3 secondary">
                    <dl class="d-flex justify-content-between align-items-center">
                        <dt class="page-lbl">Status <br><span>da empresa</span></dt>
                        <dd>
                            {{physicalSecurity.companyStatus ? 'Ativo' : 'Inativo'}}
                        </dd>
                    </dl>
                </div>
            </div>
            <!--<span class="p-span">Escala</span>-->
            <div class="col-sm-4 text-right" *ngIf="actionUserProfile()">
                <a class="btn btn-gray-fill flex-grow-1 mb-4" (click)="actionPdf()">
                    <svg-icon src="assets/svg/downloads.svg"></svg-icon>
                    <span>Exportar</span>
                </a>
            </div>
        </div>
        <div class="page-card secondary ">
            <div class="row" style="row-gap: 20px">
                <p class="col-md-3 page-lbl secondary">Informações<br><span>da empresa</span></p>
                <div class="col">
                    <div class="row">
                        <div class="col-xs-4">
                            <div class="page-value flex-column">
                                <p class="p-security">Nome da empresa<br><span
                                        class="p-span">{{physicalSecurity.name}}</span></p>
                                <p class="p-security">Telefone<br>
                                    <span class="p-span">{{physicalSecurity.phone | mask:'(00) 00000-0000 || (00) 0000-0000'}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-4" style="row-gap: 20px">
                            <div class="page-value flex-column">
                                <p class="p-security">CNPJ<br>
                                    <span class="p-span">{{physicalSecurity.cnpj | mask:'00.000.000/0000-00'}}</span>
                                </p>
                                <p class="p-security">Quantidade de postos<br><span
                                        class="p-span">{{physicalSecurity.stationNumber}}</span></p>
                            </div>
                        </div>
                        <div class="col-xs-4" style="row-gap: 20px">
                            <div class="page-value flex-column">
                                <p class="p-security">Segmento<br><span
                                        class="p-span">{{physicalSecurity.segment}}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-card secondary">
            <div class="row align-items-start mt-4">
                <p class="col-lg-3 page-lbl secondary">Informações<br><span>funcionário</span></p>
                <div class="col">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">ID</th>
                                <th scope="col">Status</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let i of listMember">
                                <td>{{i.name}}</td>
                                <td>{{i.document | mask:'000.000.000-00'}}</td>
                                <td>
                                    {{i.status ? 'Ativo' : 'Inativo'}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
