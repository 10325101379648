import {Component, AfterViewInit, OnDestroy} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    Bloc,
    CheckListAccessFinishedDto,
    CheckListAccessFinishedFilter,
    CheckListAccessItem,
    CheckListItemStatusUpdateInput,
    GetBlocByUnityGQL,
    GetCheckListAccessFinishedGQL,
    GetCheckListAccessFinishedQuery,
    UpdateCheckListAccessItemStatusGQL, User
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {ExcelService} from "../../../service/excel.service";
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-access-control-detail',
    templateUrl: './access-control-detail.component.html',
    styleUrls: ['./access-control-detail.component.scss']
})
export class AccessControlDetailComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    user = new User();
    filter: CheckListAccessFinishedFilter = new CheckListAccessFinishedFilter();
    checkList: CheckListAccessFinishedDto = new CheckListAccessFinishedDto();
    checkListAccessQuery: QueryRef<GetCheckListAccessFinishedQuery>;
    selectedItem: CheckListAccessItem = new CheckListAccessItem();
    status = ['pending', 'working', 'late', 'done'];
    pushComment: string;
    blocs: Bloc[] = [];
    statusCheckList: string;

    exportExcel: boolean;
    actionBtn: boolean;

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public translate: TranslateService,
        private getCheckListAccessFinishedGQL: GetCheckListAccessFinishedGQL,
        private getBlocByUnityGQL: GetBlocByUnityGQL,
        private UpdateCheckListAccessItemStatusGQL: UpdateCheckListAccessItemStatusGQL,
        public userService: UserService,
        private excelService: ExcelService,
        ) {
        super(router, translate);
    }

    ngOnDestroy(): void {
        this.destroyModal('statusModal');
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.actionUserProfileBTN();
        this.activatedRoute.params.subscribe(data => {
            this.filter.id = data.id;
            this.filter.page = 1;
            this.getSearchCheckList();
        });
    }

    getSearchCheckList() {
        this.checkListAccessQuery = this.getCheckListAccessFinishedGQL.watch({data: this.filter});
        this.checkListAccessQuery.valueChanges.subscribe(({data}) => {
            this.checkList = data.checkListAccessFinished as CheckListAccessFinishedDto;
            if (this.isNullOrUndefined(this.blocs)) {
                this.getBlocByUnityGQL.watch({unityId: this.checkList.checklist.unity.id}).valueChanges.subscribe(({data}) => {
                    this.blocs = data.blocByUnity as Bloc[];
                }, error => super.onError(error));
            }
        }, error => super.onError(error));
    }

    onSearch() {
        this.checkListAccessQuery.refetch({data: this.filter});
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.onSearch();
    }

    onModalDetail(c: CheckListAccessItem) {
        this.selectedItem = Object.assign({}, c);
        this.pushComment = undefined;
        this.statusCheckList = this.selectedItem.status;
        this.showModal('statusModal');
        console.log(this.selectedItem);
    }

    onSave() {
        if (this.isNullOrUndefined(this.pushComment)) {
            return this.showMessage(this.translate.instant('atencao'), this.translate.instant('comentarioobrigatorio'));
        }
        let data: CheckListItemStatusUpdateInput = new CheckListItemStatusUpdateInput();
        data.itemId = this.selectedItem.id;
        data.status = this.statusCheckList;
        data.comment = this.pushComment;
        this.UpdateCheckListAccessItemStatusGQL.mutate({data: data}).subscribe(({data}) => {
            this.onSearch();
            this.closeModal('statusModal');
            super.showMessage(this.translate.instant('success'), this.translate.instant('checklistalterado'), 'success');
        }, error => super.onError(error));
    }

    actionExcel() {
        this.excelService.getExcelCheckListAccess(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }
    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.exportExcel = true;
                this.actionBtn = true;
                break;
            case 'local-manager':
                this.exportExcel = true;
                this.actionBtn = false;
                break;
            case 'user':
                this.exportExcel = false;
                this.actionBtn = false;
                break;
            case 'vigilant-leader':
                this.exportExcel = false;
                this.actionBtn = false;
                break;
            case 'vigilant-conductor':
                this.exportExcel = false;
                this.actionBtn = false;
                break;
            case 'vigilant':
                this.exportExcel = false;
                this.actionBtn = false;
                break;
            case 'lecturer':
                this.exportExcel = false;
                this.actionBtn = false;
                break;
            case 'corporate-operator':
                this.exportExcel = true;
                this.actionBtn = true;
                break;
            case 'local-operator':
                this.exportExcel = true;
                this.actionBtn = true;
                break;
        }
    }
}
