import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {HomeComponent} from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import {NotificationComponent} from './views/notification/notification.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {OccurrenceNewComponent} from './views/occurrence/occurrence-new/occurrence-new.component';
import {OccurrenceDetailComponent} from './views/occurrence/detail/occurrence-detail.component';
import {OccurrenceComponent} from './views/occurrence/occurrence.component';
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {OccurrenceListComponent} from './views/occurrence/occurrence-list/occurrence-list.component';
import {GraphQLModule} from './graphql.module';
import {CURRENCY_MASK_CONFIG, CurrencyMaskModule} from 'ng2-currency-mask';
import {PhysicalSecurityComponent} from './views/physical-security/physical-security.component';
import {PoliciesProceduresComponent} from './views/policies-procedures/policies-procedures.component';
import {SettingsComponent} from './views/settings/settings.component';
import {AccessControlComponent} from './views/access-control/access-control.component';
import {AlarmComponent} from './views/alarm/alarm.component';
import {CftvComponent} from './views/cftv/cftv.component';
import {AlarmControlHomeComponent} from './views/alarm/home/alarm-control-home.component';
import {CftvHomeComponent} from './views/cftv/home/cftv-home.component';
import {AccessControlHomeComponent} from './views/access-control/home/access-control-home.component';
import {PhysicalSecurityHomeComponent} from './views/physical-security/home/physical-security-home.component';
import {PoliciesProceduresHomeComponent} from './views/policies-procedures/home/policies-procedures-home.component';
import {TrucksComponent} from './views/trucks/trucks.component';
import {TrucksHomeComponent} from './views/trucks/home/trucks-home.component';
import {ServicesComponent} from './views/services/services.component';
import {ServicesHomeComponent} from './views/services/home/services-home.component';
import {CameraComponent} from './views/settings/camera/camera.component';
import {AccessComponent} from './views/settings/access/access.component';
import {SettingsAlarmComponent} from './views/settings/alarm/settings-alarm.component';
import {BlockComponent} from './views/settings/block/block.component';
import {UnityComponent} from './views/settings/unity/unity.component';
import {AccessControlDetailComponent} from './views/access-control/detail/access-control-detail.component';
import {PhysicalSecurityDetailComponent} from './views/physical-security/detail/physical-security-detail.component';
import {AlarmControlDetailComponent} from './views/alarm/detail/alarm-control-detail.component';
import {CftvDetailComponent} from './views/cftv/detail/cftv-detail.component';
import {UnityDetailComponent} from './views/settings/unity/unity-detail/unity-detail.component';
import {NgxMaskModule} from 'ngx-mask';
import {SearchFilterComponent} from './views/search-filter/search-filter.component';
import {AccessControlCreateComponent} from './views/access-control/create/access-control-create.component';
import {ModalCommentComponent} from './views/components/modal-comment/modal-comment.component';
import {CftvControlCreateComponent} from './views/cftv/create/cftv-control-create.component';
import {AlarmControlCreateComponent} from './views/alarm/create/alarm-control-create.component';
import {PhysicalSecurityCreateComponent} from './views/physical-security/create/physical-security-create.component';
import {TrucksCreateComponent} from './views/trucks/create/trucks-create.component';
import {TrucksDetailComponent} from './views/trucks/detail/trucks-detail.component';
import {ServicesDetailComponent} from './views/services/detail/services-detail.component';
import {ServicesCreateComponent} from './views/services/create/services-create.component';
import {ServicesSurveyComponent} from './views/services/survey/services-survey.component';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {GoogleChartsModule} from 'angular-google-charts';
import {GeneralConfigComponent} from './views/settings/general-config/general-config.component';


registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        LogoutComponent,
        HomeComponent,
        AutocompleteComponent,
        NotificationComponent,
        UserComponent,
        AuthenticateComponent,
        OccurrenceNewComponent,
        OccurrenceDetailComponent,
        OccurrenceComponent,
        OccurrenceListComponent,
        LoginComponent,
        DashboardComponent,
        PhysicalSecurityComponent,
        PoliciesProceduresComponent,
        SettingsComponent,
        AccessControlComponent,
        AlarmComponent,
        CftvComponent,
        AlarmControlHomeComponent,
        CftvHomeComponent,
        AccessControlHomeComponent,
        PhysicalSecurityHomeComponent,
        PoliciesProceduresHomeComponent,
        TrucksComponent,
        TrucksHomeComponent,
        ServicesComponent,
        ServicesHomeComponent,
        CameraComponent,
        AccessComponent,
        SettingsAlarmComponent,
        BlockComponent,
        UnityComponent,
        AccessControlCreateComponent,
        AlarmControlCreateComponent,
        CftvControlCreateComponent,
        AccessControlDetailComponent,
        PhysicalSecurityDetailComponent,
        AlarmControlDetailComponent,
        CftvDetailComponent,
        UnityDetailComponent,
        SearchFilterComponent,
        ModalCommentComponent,
        PhysicalSecurityCreateComponent,
        TrucksCreateComponent,
        TrucksDetailComponent,
        ServicesDetailComponent,
        ServicesCreateComponent,
        CropImageComponent,
        ServicesSurveyComponent,
        RecoveryComponent,
        GeneralConfigComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        GraphQLModule,
        NgxSpinnerModule,
        GoogleChartsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgApexchartsModule,
        ImageCropperModule,
        NgxPaginationModule,
        CurrencyMaskModule,
        NgMultiSelectDropDownModule,
        NgxMaskModule.forRoot()
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL'
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {   // BRL
                align: 'left',
                allowNegative: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                suffix: '',
                thousands: '.'
            }
        },
        TranslateService,
        AppDataService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
