<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon src="assets/svg/settings-blue.svg"></svg-icon>
            <h1 class="page-title">{{"config" | translate}} | <span>{{"settings-bloc.bloc" | translate}}</span></h1>
            <a (click)="create()" class="btn btn-secondary">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                {{"settings-bloc.new-bloc" | translate}}
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" [(ngModel)]="filter.keyword" [maxLength]="80"
                       required/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>{{'busca'|translate}}</span>
                </label>
            </div>
            <div class="input-material select">
                <select name="country" id="country" (change)="getUnitiesFilter()"
                        [(ngModel)]="filterCountryId"
                        class="form-control" required>
                    <option [ngValue]="null">Todos</option>
                    <option *ngFor="let c of countries" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="country">{{"settings.country" | translate}}</label>
            </div>
            <div class="input-material select">
                <select name="unity" id="unity"
                        [(ngModel)]="filterUnityId"
                        class="form-control" required>
                    <option [ngValue]="null">Todos</option>
                    <option *ngFor="let c of filterUnities" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="unity">{{'unidade'|translate}}</label>
            </div>
            <a (click)="onFilter()" class="btn btn-primary btn-lg text-white">{{'buscar'|translate}}</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table table_regular custom-table-width">
                <!--TABLE HEAD-->
                <thead>
                <tr>
                    <th scope="col">{{"settings-bloc.id" | translate}}</th>
                    <th scope="col">{{"settings.country" | translate}}</th>
                    <th scope="col">{{"settings.bloc" | translate}}</th>
                    <th scope="col">{{"settings.unity" | translate}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(result.results)">
                    <td colspan="5" class="text-center">
                        {{"settings.no-result" | translate}}
                    </td>
                </tr>
                <tr *ngFor="let r of result.results |
                paginate: {itemsPerPage: result.PageSize,
                currentPage: result.currentPage,
                totalItems: result.totalCount }">
                    <td>{{r.code}}</td>
                    <td>
                        <img *ngIf="!isNullOrUndefined(r.unity?.country)"
                             [src]="'assets/images/country/' + r.unity?.country?.flag + '.svg'"
                             alt="img"
                             width="30px"/>
                    </td>
                    <td>{{r.name}}</td>
                    <td class="w-70">{{r.unity.name}}</td>
                    <td class="text-center">
                        <div class="d-flex justify-content-around w-100">
                            <button (click)="actionEdit(r)">
                                <svg-icon src="assets/svg/edit.svg"></svg-icon>
                            </button>
                            <button (click)="actionDelete(r.id)">
                                <svg-icon src="assets/svg/close-red.svg"></svg-icon>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

<div class="modal fade" id="blocModal" tabindex="-1" role="dialog" aria-labelledby="blocModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{isNullOrUndefined(this.modelCreate.id) ? this.translate.instant('novobloco') : this.translate.instant('editarbloco')}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body mh-0 mb-0">

                <div class="row col-12 mb-0">
                    <!--NAME-->
                    <div class="col-12">
                        <div class="input-material">
                            <input id="blocName" class="form-control" type="text" required
                                   [(ngModel)]="modelCreate.name" [maxLength]="80"/>
                            <label for="blocName">
                                <span>*{{'nome'|translate}}</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material select">
                            <select name="blocUnity" id="blocCountry" (change)="getUnitiesForCreate()"
                                    [(ngModel)]="selectedCreateCountryId"
                                    class="form-control" required>
                                <option [value]="undefined">Selecione um País</option>
                                <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name}}</option>
                            </select>
                            <label for="blocCountry">País</label>
                        </div>
                    </div>
                    <!-- UNITY DROPDOWN -->
                    <div class="col-12">
                        <div class="input-material select">
                            <select name="blocUnity" id="blocUnity"
                                    [(ngModel)]="selectedCreateUnityId"
                                    class="form-control" required>
                                <option [value]="undefined">Selecione uma Unidade</option>
                                <option *ngFor="let c of createUnities" [ngValue]="c.id">{{c.name}}</option>
                            </select>
                            <label for="blocUnity">*{{'unidade'|translate}}</label>
                        </div>
                    </div>

                    <a href="javascript:void('modal')" (click)="actionSave()" class="col-12 btn btn-secondary mt-3">
                        {{'salvar'|translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
