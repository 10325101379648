import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-occurrence',
  templateUrl: './occurrence.component.html',
  styleUrls: ['./occurrence.component.scss']
})
export class OccurrenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
