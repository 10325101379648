<div class="page-header secondary">
    <div class="container justify-content-center">
        <a [routerLink]="['/occurrence']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <h2 class="page-header__title">BIO Nº <strong>{{model.code}}</strong></h2>
        <!--BOTÃO DE EXPORTAR COMENTADO ATÉ DEFINIR SEU TIPO / TAREFA EO-49-->
        <button class="btn btn-outline-primary order-3 ml-auto" *ngIf="profile" (click)="actionExcel()">
            <svg-icon class="mr-3 pdf_svg" src="assets/svg/export.svg"></svg-icon>
            <span>Exportar</span>
        </button>
        <div>&nbsp;</div>
    </div>
</div>
<section class="page-content">
    <div class="container">
        <article class="page-content__details dark-section pb-0">
            <div class="d-flex justify-content-between align-items-center">
                <h2 class="page-content__section secondary single-line">
                    <strong>1</strong>
                    <span>{{'dadosda'|translate}}<span class="text-uppercase is-highlight"> UNIDADE</span></span>
                </h2>
            </div>
            <div class="row">
                <div class="col-12 col-md-10 col-lg-11 offset-md-2 offset-lg-1">
                    <div class="light-section no-bg">
                        <div class="row">
                            <div class="col-12 col-lg-8">
                                <div class="row">
                                    <div class="col-6">
                                        <h4 class="title">{{'unidade'|translate}}</h4>
                                        <p class="text">{{model?.unity?.name}}</p>
                                    </div>
                                    <div class="col-6">
                                        <h4 class="title">{{'dataocorrencia'|translate}}</h4>
<!--                                        <p class="text">{{model.date | date: 'dd/MM/yyyy HH:mm' : 'utc'}}</p>-->
                                        <p class="text">{{dateHandler(model.date | date: 'dd/MM/yyyy  HH:mm')}}</p>
                                    </div>
                                    <div class="col-6">
                                        <h4 class="title">Área</h4>
                                        <p class="text">{{model.area}}</p>
                                    </div>
                                    <div class="col-6">
                                        <h4 class="title">Responsável</h4>
                                        <p class="text">{{model.areaResponsible}}</p>
                                    </div>
                                    <div class="col-6">
                                        <h4 class="title">Nº BO</h4>
                                        <p class="text">{{model.bulletinNumber}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="row">
                                    <div class="col-6 col-lg-12">
                                        <h4 class="title bold">Local</h4>
                                        <p class="text">{{model?.unity?.city}}</p>
                                    </div>
                                    <div class="col-6 col-lg-12 d-flex align-items-center pt-lg-2">
                                        <img width="30px"
                                             [src]="'assets/images/country/' + model.unity?.country?.flag + '.svg'"
                                             alt="Brasil">
                                        <p class="ml-2 mb-0 title">{{model?.unity?.country?.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>

        <article class="page-content__details dark-section">
            <div class="d-flex justify-content-between align-items-center">
                <h2 class="page-content__section secondary single-line">
                    <strong>2</strong>
                    <span class="is-highlight">{{'ocorrencia'|translate}}</span>
                </h2>
            </div>
            <div class="row mb-lg-5">
                <div class="col-12 col-md-2 col-lg-1">
                    <div class="dark-section__border">
                        <span>{{'vitima'|translate}}</span>
                    </div>
                </div>
                <div class="col-12 col-md-10 col-lg-11">
                    <div class="light-section">
                        <div class="row">
                            <div class="col-3 col-lg-2">
                                <img width="70px" src="assets/images/user.svg" alt="">
                            </div>
                            <div class="col-9 col-lg-6 d-flex align-items-center">
                                <h4 class="user-title">{{modelVictim.name}}</h4>
                            </div>
                            <div *ngIf="modelVictim.type === 'pessoal'"
                                 class="col-12 col-lg-4 d-flex align-items-center justify-content-around flex-lg-column justify-content-lg-center align-items-lg-start mt-2 mt-lg-0">
                                <span *ngIf="getAge(modelVictim.birth) !== 0" class="d-flex align-items-center mb-lg-2">
                                    <h4 class="title fixed-label mb-0 mt-1">Idade</h4>
                                    <p class="text mb-0">{{getAge(modelVictim.birth)}} anos</p>
                                </span>
                                <span class="d-flex align-items-center">
                                    <h4 class="title fixed-label mb-0 mt-1">Sexo</h4>
                                    <p class="text mb-0">{{getTranslate(modelVictim.gender)}}</p>
                                </span>
                            </div>
                        </div>
                        <hr>
                        <div *ngIf="modelVictim.type === 'pessoal'" class="row">
                            <div class="col-6 col-lg-4">
                                <h4 class="title">{{'rg'|translate}}</h4>
                                <p class="text">{{this.translate.currentLang==='pt'? (modelVictim.rg| mask:'00.000.000-0') : (modelVictim.rg)}}</p>
                            </div>
                            <div class="col-6 col-lg-2">
                                <h4 class="title">UF</h4>
                                <p class="text">{{modelVictim.uf}}</p>
                            </div>
                            <div class="col-6 col-lg-3">
                                <h4 class="title">Natural </h4>
                                <p class="text">{{modelVictim.nationality}}</p>
                            </div>
                            <div class="col-6 col-lg-3">
                                <h4 class="title">{{'cpf' | translate}}</h4>
                                <p class="text">{{this.translate.currentLang==='pt'? (modelVictim.document| mask:'000.000.000-00') : (modelVictim.document)}}</p>

                            </div>
                        </div>
                        <div class="row">
                            <div *ngIf="modelVictim.type === 'pessoal'" class="col-6 col-lg-4">
                                <h4 class="title">Cargo</h4>
                                <p class="text">{{modelVictim.jobRole}}</p>
                            </div>
                            <div *ngIf="modelVictim.type === 'pessoal'" class="col-6 col-lg-5">
                                <h4 class="title">Setor </h4>
                                <p class="text">{{modelVictim.sector}}</p>
                            </div>
                            <div *ngIf="modelVictim.type === 'legal'" class="col-6 col-lg-5">
                                <h4 class="title">CNPJ </h4>
                                <p class="text">{{modelVictim.document}}</p>
                            </div>
                            <div class="col-6 col-lg-3">
                                <h4 class="title">CEP</h4>
                                <p class="text">{{modelVictim.cep}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-9">
                                <h4 class="title">{{'Endereco'|translate}}</h4>
                                <p class="text">{{modelVictim.address}}, {{modelVictim.addressNumber}}</p>
                            </div>
                            <div class="col-12 col-lg-3">
                                <h4 class="title">Complemento</h4>
                                <p class="text">{{modelVictim.complement}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="light-section no-bg mt-3">
                        <div class="row">
                            <div class="col-6 col-lg-3">
                                <h4 class="title">Empresa</h4>
                                <p class="text">{{model.company}}</p>
                            </div>
                            <div class="col-6 col-lg-4">
                                <h4 class="title">Departamento</h4>
                                <p class="text">{{model.department}}</p>
                            </div>
                            <div class="col-6 col-lg-5">
                                <h4 class="title">Responsável</h4>
                                <p class="text">{{model.responsible}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-md-7 col-lg-5">
                                <h4 class="title">Local</h4>
                                <p class="text">{{model.place}}</p>
                            </div>
                            <div class="col-6 col-md-2 col-lg-2">
                                <h4 class="title">Hora</h4>
                                <p class="text">{{model.hour}}</p>
                            </div>
                            <div class="col-6 col-md-3 col-lg-5">
                                <h4 class="title">Data</h4>
                                <p class="text">{{model.date | date:'d \'de\' MMMM \'de\' y' : 'UTC'}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-lg-3">
                                <h4 class="title">Classificação</h4>
                                <p class="text">{{getTranslate(model.classification)}}</p>
                            </div>
                            <div class="col-6 col-lg-4">
                                <h4 class="title">Sinistro</h4>
                                <p class="text">{{getTranslate(model.sinister)}} </p>
                            </div>
                            <div class="col-6 col-lg-2">
                                <h4 class="title">Recorrente</h4>
                                <p class="text">{{model.recurrent ? 'Sim' : 'Não'}}</p>
                            </div>
                            <div class="col-6 col-lg-3">
                                <h4 class="title">Afastamento</h4>
                                <p class="text">{{model.abscent ? 'Sim' : 'Não'}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <h4 class="title">{{'vitimas'|translate}}</h4>
                                <p class="text mb-lg-0">{{getTranslate(model.victims)}}</p>
                            </div>
                            <div class="col-12 col-lg-6">
                                <h4 class="title">{{'tipoocorrencia'|translate}}</h4>
                                <p class="text mb-lg-0">{{getTranslate(model.type)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-lg-5 mt-lg-3">
                <div class="col-12 col-md-2 col-lg-1">
                    <div class="dark-section__border ">
                        <span>{{'autoria'|translate}}</span>
                    </div>
                </div>
                <div class="col-12 col-md-10 col-lg-11">
                    <div *ngIf="model.authorship === 'known'" class="light-section">
                        <div *ngFor="let t of authors,let i = index">
                            <div class="row">
                                <div class="col-3 col-lg-2">
                                    <img width="70px" src="assets/images/user.svg" alt="">
                                </div>
                                <div class="col-9 col-lg-6 d-flex align-items-center">
                                    <h4 class="user-title">{{t.name}}</h4>
                                </div>
                                <div class="col-12 col-lg-4 d-flex align-items-center justify-content-around flex-lg-column justify-content-lg-center align-items-lg-start mt-2 mt-lg-0">
                                    <span class="d-flex align-items-center mb-lg-2">
                                        <h4 class="title fixed-label mb-0 mt-1">Idade</h4>
                                        <p class="text mb-0">{{getAge(t.birth)}} anos</p>
                                    </span>
                                    <span class="d-flex align-items-center">
                                        <h4 class="title fixed-label mb-0 mt-1">Sexo</h4>
                                        <p class="text mb-0">{{getTranslate(t.gender)}}</p>
                                    </span>
                                </div>
                            </div>
                        <hr>
                        <div class="row">
                            <div class="col-6 col-lg-4">
                                <h4 class="title">{{'rg'|translate}}</h4>
                                <p class="text">{{this.translate.currentLang==='pt'? (t.rg| mask:'00.000.000-0') : (t.rg)}}</p>

                            </div>
                            <div class="col-6 col-lg-2">
                                <h4 class="title">UF</h4>
                                <p class="text">{{t.uf}}</p>
                            </div>
                            <div class="col-6 col-lg-3">
                                <h4 class="title">Natural </h4>
                                <p class="text">{{t.nationality}}</p>
                            </div>
                            <div class="col-6 col-lg-3">
                                <h4 class="title">{{'cpf' | translate}}</h4>
                                <p class="text">{{this.translate.currentLang==='pt'? (t.document| mask:'000.000.000-00') : (t.document)}}</p>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-lg-4">
                                <h4 class="title">Mae</h4>
                                <p class="text">{{t.mother}}</p>
                            </div>
                            <div class="col-6 col-lg-5">
                                <h4 class="title">Pai</h4>
                                <p class="text">{{t.father}}</p>
                            </div>
                            <div class="col-6 col-lg-3">
                                <h4 class="title">CEP</h4>
                                <p class="text">{{t.cep}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-9">
                                <h4 class="title">{{'Endereco'|translate}}</h4>
                                <p class="text">{{t.address}}, {{t.addressNumber}}</p>
                            </div>
                            <div class="col-12 col-lg-3">
                                <h4 class="title">Complemento</h4>
                                <p class="text">{{t.complement}}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="light-section no-bg mt-3">
                        <div class="row">
                            <div class="col-6 col-lg-4">
                                <h4 class="title">Colaborador Envolvidos</h4>
                                <p class="text">{{getTranslate(model.employeesInvolved)}}</p>
                            </div>
                            <div class="col-6 col-lg-4">
                                <h4 class="title">Violência</h4>
                                <p class="text">{{getTranslate(model.violence)}} </p>
                            </div>
                            <div class="col-12 col-lg-4">
                                <h4 class="title">Autor</h4>
                                <p class="text">{{getTranslate(model.author)}} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-lg-3">
                <div class="col-12 col-md-2 col-lg-1">
                    <div class="dark-section__border ">
                        <span>{{'resumodaocorrencia'|translate}}</span>
                    </div>
                </div>
                <div class="col-12 col-md-10 col-lg-11">
                    <div class="col-12 col-md-10 col-lg-11">
                        <h4 class="title">Resumo da Ocorrência</h4>
                        <p class="text">{{model.occurrenceDetail}} </p>
                    </div>
                    <div class="light-section">
                        <div class="row">
                            <div class="col-12 col-lg-4">
                                <div class="uploaded no-border">
                                    <h5>Fotos</h5>
                                    <ul class="images">
                                        <li *ngFor="let picture of filteredFiles(model.files, 'picture')">
                                            <a [href]="picture.url">
                                                <img [src]="picture.url" [alt]="picture.originalName">
                                            </a>
                                            <p>{{picture.comment}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="uploaded no-border">
                                    <h5>Músicas</h5>
                                    <ul class="pl-2">
                                        <li *ngFor="let sound of filteredFiles(model.files, 'sound')">
                                            <a [href]="sound.url">
                                                <span>{{sound.originalName}}</span>
                                                <svg-icon class="remove" src="assets/svg/link.svg"></svg-icon>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="uploaded no-border">
                                    <h5>Videos</h5>
                                    <ul class="pl-2">
                                        <li *ngFor="let video of filteredFiles(model.files, 'video')">
                                            <a [href]="video.url">
                                                <span>{{video.originalName}}</span>
                                                <svg-icon class="remove" src="assets/svg/link.svg"></svg-icon>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>

        <article class="page-content__details dark-section">
            <div class="d-flex justify-content-between align-items-center">
                <h2 class="page-content__section secondary single-line">
                    <strong>3</strong>
                    <span class="is-highlight">{{'analiseocorrencia'|translate}}</span>
                </h2>
            </div>
            <div class="row mb-5">
                <div class="col-12 col-md-2 col-lg-1">
                    <div class="dark-section__border ">
                        <span>Perdas</span>
                    </div>
                </div>
                <div class="col-12 col-md-10 col-lg-11">
                    <div class="light-section">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <h3 class="mt-5 mb-0 input-head text-left">Perdas
                                    <strong>{{'diretas'|translate}}</strong></h3>

                                <div class="row w-full row-lost">
                                    <div class="col-lg-5">
                                        <h4 class="title">Tipo:</h4>
                                    </div>
                                    <div class="col-lg-7">
                                        <p class="text price mb-0">
                                            <span>{{model.directLossType | translate}}</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row w-full row-lost">
                                    <div class="col-lg-5">
                                        <h4 class="title">Valor em R$:</h4>
                                    </div>
                                    <div class="col-lg-7">
                                        <p class="text price mb-0">
                                            <span>{{model.directLossValue | currency: 'BRL'}}</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row mt-2 mt-lg-4" *ngIf="!isNullOrUndefined(model.directLossObjects)">
                                    <div class="col-12">
                                        <h4 class="title">Objeto</h4>
                                        <p class="text">{{model.directLossObjects}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <h3 class="mt-5 mb-0 input-head text-left">Perdas
                                    <strong>{{'indiretas'|translate}}</strong></h3>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <h4 class="title">{{'interrupcaoatividade'|translate}}</h4>
                                        <p class="text">{{model.indirectLossInterruption ? 'Sim' : 'Não'}}</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <h4 class="title">{{'tempoparada'|translate}}</h4>
                                        <p class="text">{{model.indirectLossTime}} horas</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <h4 class="title">{{'atividade'|translate}}</h4>
                                        <p class="text">{{model.indirectLossActivity}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <h4 class="title">Custo da Atividade</h4>
                                        <p class="text">{{model.indirectLossCost | currency: 'BRL'}}</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <h4 class="title">{{'removal'|translate}}</h4>
                                        <p class="text">{{model.indirectLossRemoval | currency: 'BRL'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="light-section no-bg mt-lg-2">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <h3 class="mt-5 mb-0 input-head text-left"><strong>{{'recuperada'|translate}}</strong>
                                </h3>
                                <div class="row w-full">
                                    <div class="col-12">
                                        <h4 class="title fixed-label mb-0 mt-1">Status</h4>
                                        <p class="text price mb-0">
                                            <span>{{getTranslate(model.recoveryStatus)}}</span>
                                        </p>
                                    </div>

                                    <div class="col-12">
                                        <h4 class="title fixed-label mb-0 mt-1">Valor em R$:</h4>
                                        <p class="text price mb-0">
                                            <span>{{model.retrievedValue | currency: 'BRL'}}</span>
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <h4 class="title fixed-label mb-0 mt-1"></h4>
                                        <p class="text price mb-0">
                                            <span>{{getTranslate(model.recoveryStatusDescription)}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 pt-3 pt-lg-0">
                                <h3 class="mt-5 mb-0 input-head text-left">Estimativa de <strong>Perdas</strong></h3>
                                <div class="row w-full">
                                    <div class="col-12 d-flex flex-column align-items-center justify-content-around justify-content-lg-center align-items-lg-start col-highlight">
                                        <div class="d-flex align-items-center justify-content-between w-full mb-lg-2">
                                            <h4 class="title fixed-label mb-0 mt-1">{{'diretas'|translate}}</h4>
                                            <p class="text price mb-0">
                                                <span>{{model.directLossValue | currency: 'BRL'}}</span>
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between w-full mb-lg-2">
                                            <h4 class="title fixed-label mb-0 mt-1">{{'indiretas'|translate}}</h4>
                                            <p class="text price mb-0">
                                                <span>{{((((model.indirectLossCost * ((((this.model.indirectLossTime)?.slice(0, 2) * 60) +(((this.model.indirectLossTime)?.slice(3,5)*1)))/60)) + model.indirectLossRemoval))) | currency: 'BRL'}}</span>
                                            </p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between w-full mb-lg-2 mt-2 mt-lg-lost">
                                            <h4 class="title fixed-label mb-0 mt-0">Perda Total</h4>
                                            <p class="text price mb-0">
                                                <span>{{((model.directLossValue + ((model.indirectLossCost * ((((this.model.indirectLossTime)?.slice(0, 2) * 60) +(((this.model.indirectLossTime)?.slice(3,5)*1)))/60)) + model.indirectLossRemoval)) - model.retrievedValue) | currency: 'BRL'}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12 col-md-2 col-lg-1">
                    <div class="dark-section__border ">
                        <span>{{'facilitadoresocorrencia'|translate}}</span>
                    </div>
                </div>
                <div class="col-12 col-md-10 col-lg-11">
                    <div class="light-section no-bg mt-lg-2">
                        <div class="row">
                            <div class="col-12">
                                <h4 class="title">{{'favorecemocorrencia'|translate}}</h4>
                                <p class="text">{{getTranslate(model.favor)}}</p>
                            </div>
                            <div class="col-12" *ngIf="!isNullOrUndefined(model.favorDetail)">
                                <h4 class="title">{{'detalharcondicoes'|translate}}</h4>
                                <p class="text highlighted-text mb-0">{{model.favorDetail}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-2 col-lg-1">
                    <div class="dark-section__border ">
                        <span>{{'providencias'|translate}}</span>
                    </div>
                </div>
                <div class="col-12 col-md-10 col-lg-11">
                    <div class="light-section no-bg mt-lg-2">
                        <div class="row">
                            <div class="col-12 col-lg-7">
                                <div class="row">
                                    <div class="col-12">
                                        <h4 class="title">{{'quemfoicomunicado'|translate}}</h4>
                                        <p class="text" *ngFor="let t of model.responsiblesCommunicated,let i = index">
                                            <span *ngIf="i>0">, </span>{{getTranslate(t.type)}}
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <h4 class="title">{{'resumomedidasmitigatorias'|translate}}</h4>
                                        <p class="text highlighted-text">{{model.mitigatingMeasuresDescription}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 offset-lg-1">
                                <h3 class="mt-5 mb-0 input-head text-left"><strong
                                        class="d-lg-block">Responsável</strong> pelas providências</h3>
                                <div class="row">
                                    <div class="col-12">
                                        <h4 class="title">{{'nome'|translate}}</h4>
                                        <p class="text">{{model.measuresResponsibleName}}</p>
                                    </div>
                                    <div class="col-12">
                                        <h4 class="title">Cargo</h4>
                                        <p class="text">{{model.measuresResponsibleRole}}</p>
                                    </div>
                                    <div class="col-12">
                                        <h4 class="title">Prazo para execução das providências</h4>
                                        <p class="text">{{model.mitigatingDeadLine | date: 'd \'de\' MMMM \'de\' y'  : 'UTC'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>

<!--        <article class="page-content__details dark-section mb-5">-->
<!--            <div class="row">-->
<!--                <div class="col-12">-->
<!--                    <div class="light-section no-bg p-0">-->
<!--                        <div class="row">-->
<!--                            <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center">-->
<!--                                <h3 class="mt-5 mb-0 input-head text-right">Assinatura <strong class="d-lg-block">Digital</strong>-->
<!--                                </h3>-->
<!--                            </div>-->
<!--                            <div class="col-12 col-lg-7 offset-lg-1">-->
<!--                                <div class="signated">-->
<!--                                    <img [src]="signature.url" alt="">-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </article>-->

    </div>
</section>
