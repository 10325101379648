import {Component, AfterViewInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    CreatePhysicalSecurityGQL,
    CreatePhysicalSecurityInput,
    CreatePhysicalSecurityMemberInput,
    PhysicalSecurity,
    PhysicalSecurityGQL,
    PhysicalSecurityMember, PhysicalSecurityQuery, RemovePhysicalSecurityMemberGQL,
    UpdatePhysicalSecurityGQL,
    UpdatePhysicalSecurityInput, UpdatephysicalSecurityMemberInput
} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {ExcelService} from "../../../service/excel.service";

declare var $: any;

@Component({
    selector: 'app-physical-security-create',
    templateUrl: './physical-security-create.component.html',
    styleUrls: ['./physical-security-create.component.scss']
})
export class PhysicalSecurityCreateComponent extends BaseComponent implements AfterViewInit {
    physicalSecurity: PhysicalSecurity = new PhysicalSecurity();
    listMember: PhysicalSecurityMember[] = [];
    member: PhysicalSecurityMember = new PhysicalSecurityMember();
    physicalSecurityQuery: QueryRef<PhysicalSecurityQuery>;
    parmId: string;

    file: File = null;
    listFiles = [];

    pathImageObj = {
        primary: {name: '', path: ''},
        secondary: {name: '', path: ''}
    };
    constructor(public router: Router,
                public translate: TranslateService,
                public activatedRoute: ActivatedRoute,
                private createPhysicalSecurityGQL: CreatePhysicalSecurityGQL,
                private updatePhysicalSecurityGQL: UpdatePhysicalSecurityGQL,
                private physicalSecurityGQL: PhysicalSecurityGQL,
                private removePhysicalSecurityMemberGQL: RemovePhysicalSecurityMemberGQL,
                private excelService: ExcelService
    ) {
        super(router, translate);
    }

    ngAfterViewInit(): void {
        this.activatedRoute.params.subscribe(data => {
            if (!this.isNullOrUndefined(data.id)) {
                this.parmId = data.id;
                this.physicalSecurityQuery = this.physicalSecurityGQL.watch({id: data.id});
                this.physicalSecurityQuery.valueChanges.subscribe(({data}) => {
                    this.physicalSecurity = data.physicalSecurity as PhysicalSecurity;
                    this.listMember = this.physicalSecurity.members;
                }, error => super.onError(error));
            }
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.physicalSecurity.name, text: `Nome da empresa<br>`},
            {value: this.physicalSecurity.segment, text: `Segmento<br>`},
            {value: this.physicalSecurity.phone, text: `Telefone<br>`},
            {value: this.physicalSecurity.cnpj, text: `CNPJ<br>`},
            {value: this.physicalSecurity.stationNumber, text: `Qtd de postos<br>`},

        ];

        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }
        if (this.isNullOrUndefined(this.physicalSecurity.id)) {
            this.createPhysicalSecurity();
        } else {
            this.updatePhysicalSecurity();
        }

    }

    pushMemberList() {
        if (this.isNullOrUndefined(this.member.name) || this.isNullOrUndefined(this.member.document)) {
            return this.showMessage(this.translate.instant('baseComponent.warningAttention'), 'Preencha o formulário corretamente para adicionar um funcionário', 'warning');

        }
        this.member.status = true;
        this.listMember.push(this.member);
        this.member = new PhysicalSecurityMember();
        this.closeModal('modalEmployee');
    }

    removeMember(memberId: string, index: number) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('Desejaexcluiressefuncionario'), () => {
            if (this.isNullOrUndefined(memberId)) {
                this.listMember.splice(index, 1);
            } else {
                this.removePhysicalSecurityMemberGQL.mutate({id: memberId}).subscribe(({data}) => {
                    this.physicalSecurityQuery.refetch({id: this.parmId});
                    this.showMessage(this.translate.instant('success'), data.removePhysicalSecurityMember.message, 'success');
                }, error => super.onError(error));
            }
        });

    }

    createPhysicalSecurity() {
        let listMemberInput: CreatePhysicalSecurityMemberInput[] = [];
        this.listMember.forEach((e) => {
            const createInput: CreatePhysicalSecurityMemberInput = {
                name: e.name,
                document: e.document,
                status: e.status
            }
            listMemberInput.push(createInput);
        })
        const data: CreatePhysicalSecurityInput = {
            companyStatus: this.isNullOrUndefined(this.physicalSecurity.companyStatus, false) ? false : this.physicalSecurity.companyStatus,
            name: this.physicalSecurity.name,
            cnpj: this.physicalSecurity.cnpj,
            segment: this.physicalSecurity.segment,
            phone: this.physicalSecurity.phone,
            stationNumber: this.physicalSecurity.stationNumber,
            member: listMemberInput,
        };
        this.createPhysicalSecurityGQL.mutate({data: data}).subscribe(({data}) => {
            super.showMessage(this.translate.instant('success'), this.translate.instant('Empresacadastradacomsucesso'), 'success');
            this.router.navigate(['/physical-security']);
        }, error => super.onError(error));
    }

    updatePhysicalSecurity() {
        let listMemberInput: UpdatephysicalSecurityMemberInput[] = [];
        this.listMember.forEach((e) => {
            const createInput: UpdatephysicalSecurityMemberInput = {
                id: e.id,
                name: e.name,
                document: e.document,
                status: e.status
            }
            listMemberInput.push(createInput);
        });
        const data: UpdatePhysicalSecurityInput = {
            id: this.physicalSecurity.id,
            companyStatus: this.isNullOrUndefined(this.physicalSecurity.companyStatus, false) ? false : this.physicalSecurity.companyStatus,
            name: this.physicalSecurity.name,
            cnpj: this.physicalSecurity.cnpj,
            segment: this.physicalSecurity.segment,
            phone: this.physicalSecurity.phone,
            stationNumber: this.physicalSecurity.stationNumber,
            membersData: listMemberInput,
        };
        console.log(data);
        this.updatePhysicalSecurityGQL.mutate({data: data}).subscribe(({data}) => {
            super.showMessage(this.translate.instant('success'), this.translate.instant('Empresaatualizadacomsucesso'), 'success');
            this.router.navigate(['/physical-security']);
        }, error => super.onError(error));
    }

    onKey(value: string) {
        const fieldValue = value;
        let numShow = 0;
        $('.js-filter-sidebar').find('.js-filter-sidebar-item').each(function (e, i) {
            console.log($(this).text().toLowerCase());
            if ($(this).text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0) {
                $(this).removeClass('d-none');
                numShow++;
            } else {
                $(this).addClass('d-none');
            }
        });
    }

    newHelper() {
        this.member = new PhysicalSecurityMember();
        this.showModal('modalEmployee');
    }

    actionImport(event, type, element) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 60000000) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                    this.translate.instant('baseComponent.warningMsgUploadFileMaxSize'), 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            // this.pathImage = file.target.result;
            this.pathImageObj[element].path = file.target.result;
            this.pathImageObj[element].name = files[0].name;
            this.listFiles.push({file: files[0], path: file.target.result, type});
        };
        reader.readAsDataURL(event.target.files[0]);
        this.file =  files[0];

        this.excelService.upload(this.file, `/physical-security/eco/excel`).subscribe({
            next: data2 => {
                this.listMember = data2 as PhysicalSecurityMember[];
            }, error: err => super.onError(err)
        });
    }
}
