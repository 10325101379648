import {Component, OnDestroy, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../base/base.component';
import {
    Bloc,
    CheckListAccessFinishedDto,
    CheckListAccessFinishedFilter,
    CheckListAccessItem, CheckListAlarmFinishedDto, CheckListAlarmFinishedFilter, CheckListAlarmItemStatusUpdateInput,
    CheckListItemStatusUpdateInput,
    GetBlocByUnityGQL,
    GetCheckListAccessFinishedQuery,
    GetCheckListAlarmFinishedGQL,
    GetCheckListAlarmFinishedQuery,
    UpdateCheckListAlarmItemStatusGQL
} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {ExcelService} from "../../../service/excel.service";

@Component({
    selector: 'app-detail',
    templateUrl: './alarm-control-detail.component.html',
    styleUrls: ['./alarm-control-detail.component.scss']
})
export class AlarmControlDetailComponent extends BaseComponent implements OnDestroy, AfterViewInit {
    filter: CheckListAlarmFinishedFilter = new CheckListAlarmFinishedFilter();
    checkList: CheckListAlarmFinishedDto = new CheckListAlarmFinishedDto();
    selectedItem: CheckListAccessItem = new CheckListAccessItem();
    status = ['pending', 'working', 'late', 'done'];
    pushComment: string;
    blocs: Bloc[] = [];
    statusCheckList: string;
    checkListAlarmQuery: QueryRef<GetCheckListAlarmFinishedQuery>;

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public translate: TranslateService,
        private getCheckListAlarmFinishedGQL: GetCheckListAlarmFinishedGQL,
        private getBlocByUnityGQL: GetBlocByUnityGQL,
        private updateCheckListAlarmItemStatusGQL: UpdateCheckListAlarmItemStatusGQL,
        private excelService: ExcelService,
    ) {
        super(router, translate);
    }

    ngOnDestroy(): void {
        this.destroyModal('statusModal');
    }

    ngAfterViewInit(): void {
        this.activatedRoute.params.subscribe(data => {
            this.filter.id = data.id;
            this.filter.page = 1;
            this.getSearchCheckList()
        })
    }

    getSearchCheckList() {
        this.checkListAlarmQuery = this.getCheckListAlarmFinishedGQL.watch({data: this.filter});
        this.checkListAlarmQuery.valueChanges.subscribe(({data}) => {
            this.checkList = data.checkListAlarmFinished as CheckListAlarmFinishedDto;
            if (this.isNullOrUndefined(this.blocs)) {
                this.getBlocByUnityGQL.watch({unityId: this.checkList.checklist.unity.id}).valueChanges.subscribe(({data}) => {
                    this.blocs = data.blocByUnity as Bloc[];
                }, error => super.onError(error));
            }

        }, error => super.onError(error));
    }

    onSearch() {
        this.checkListAlarmQuery.refetch({data: this.filter});
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.onSearch();
    }

    onModalDetail(c: CheckListAccessItem) {
        this.selectedItem = Object.assign({}, c);
        this.pushComment = undefined;
        this.statusCheckList = this.selectedItem.status;
        this.showModal('statusModal');
        console.log(this.selectedItem);
    }

    onSave() {
        if (this.isNullOrUndefined(this.pushComment)) {
            return this.showMessage(this.translate.instant('atencao'), this.translate.instant('comentarioobrigatorio'));
        }
        let data: CheckListAlarmItemStatusUpdateInput = new CheckListAlarmItemStatusUpdateInput();
        data.itemId = this.selectedItem.id;
        data.status = this.statusCheckList;
        data.comment = this.pushComment;
        this.updateCheckListAlarmItemStatusGQL.mutate({data: data}).subscribe(({data}) => {
            this.onSearch();
            this.closeModal('statusModal');
            super.showMessage(this.translate.instant('success'), this.translate.instant('checklistalterado'), 'success');
        }, error => super.onError(error));
    }

    actionExcel() {
        this.excelService.getExcelCheckListAlarms(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }
}
