import {Component, AfterViewInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {
    GetUnitiesGQL, GetUnitiesQuery,
    RemoveServiceRequestGQL,
    RemoveTruckGQL,
    SearchServiceRequestGQL, SearchServiceRequestInput,
    SearchServiceRequestQuery, ServicePage,
    ServiceRequest, ServiceRequestInspect, Unity, User
} from '../../../../generated/graphql';
import {TranslateService} from '@ngx-translate/core';
import {QueryRef} from 'apollo-angular';
import moment from 'moment';
import {ExcelService} from '../../../service/excel.service';
import {UserService} from '../../../service/user.service';

@Component({
    selector: 'app-services-home',
    templateUrl: './services-home.component.html',
    styleUrls: ['./services-home.component.scss']
})
export class ServicesHomeComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    user: User = new User();
    model: ServiceRequest = new ServiceRequest();
    modelInspect: ServiceRequestInspect = new ServiceRequestInspect();
    searchServiceRequestQuery: QueryRef<SearchServiceRequestQuery>;
    listService: ServicePage = new ServicePage();
    filter: SearchServiceRequestInput = new SearchServiceRequestInput();
    page = 1;
    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesQuery>;

    labelUnity: boolean;
    actionNew: boolean;
    viewProvider: boolean;
    inspection: boolean;
    check: boolean;
    exportExcel: boolean;

    constructor(public router: Router,
                private searchServiceRequestGLQ: SearchServiceRequestGQL,
                private removeServiceRequestGQL: RemoveServiceRequestGQL,
                private excelService: ExcelService,
                public userService: UserService,
                private getUnitiesGQL: GetUnitiesGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.actionUserProfileBTN();
        if (this.actionUserProfile() === false) {
            this.filter.unity = this.user.unity.id;
        }
        this.filter.start = moment().subtract(6, 'days').utcOffset(0, true).format();
        this.filter.end = moment().utcOffset(0, true).format();
        this.setDatePicker('date-picker', (start, end, label) => {
            this.filter.start = start.utcOffset(0, true).format();
            this.filter.end = end.utcOffset(0, true).format();
        }, this.filter.start);
        this.searchServiceRequestQuery = this.searchServiceRequestGLQ.watch({data: this.filter});
        this.searchServiceRequestQuery.valueChanges.subscribe(({data}) => {
            this.listService = data.searchServiceRequest as ServicePage;
        }, error1 => super.onError(error1));
        this.unityQuery = this.getUnitiesGQL.watch();
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.unities as Array<Unity>;
        });
    }

    ngOnDestroy(): void {
        super.destroyModal('modalServices');
        super.destroyModal('modalServicesInspect');
    }

    onSearch() {
        this.searchServiceRequestQuery.refetch({data: this.filter});
    }

    actionShowRequest(l: ServiceRequest) {
        this.model = Object.assign({}, l);
        this.showModal('modalServices');
    }

    actionShowRequestInspect(x: ServiceRequestInspect) {
        this.closeModal('modalServices');
        setTimeout(() => {
            this.router.navigate(['/services/inspection/' + this.model.id + '/' + x.id]);
        }, 500);
        // this.modelInspect = Object.assign({}, x);
        // this.showModal('modalServicesInspect');
    }

    actionRemoveRequest(l: ServiceRequest) {
        this.confirmMessage('Atenção', 'Tem certeza que deseja apagar o agendamento?', () => {
            this.removeServiceRequestGQL.mutate({data: l.id}).subscribe(
                (data) => {
                    this.searchServiceRequestQuery.refetch({data: this.filter});
                });
        });
    }

    handlerRequestIsStart(l: ServiceRequest) {
        return moment().utc(true).isBefore(l.entranceDate);
    }

    actionExcel() {
        this.excelService.getExcelServiceRequest(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    validateUser(item: ServiceRequest) {
        // if (this.user.profile === 'vigilant' && item.status === 'check') {
        //     return;
        // }
        this.router.navigate([(item.status !== 'finished' ? 'services/survey/' : 'services/detail/') + item.id]);
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
            case 'transport':
                return false;
        }
    }


    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelUnity = true;
                this.actionNew = true;
                this.viewProvider = true;
                this.inspection = true;
                this.check = true;
                this.exportExcel = true;
                break;
            case 'local-manager':
                this.labelUnity = false;
                this.actionNew = true;
                this.viewProvider = true;
                this.inspection = false;
                this.check = false;
                this.exportExcel = true;
                break;
            case 'user':
                this.labelUnity = false;
                this.actionNew = true;
                this.viewProvider = false;
                this.inspection = false;
                this.check = false;
                this.exportExcel = false;
                break;
            case 'vigilant-leader':
                this.labelUnity = false;
                this.actionNew = false;
                this.viewProvider = true;
                this.inspection = true;
                this.check = true;
                this.exportExcel = true;
                break;
            case 'vigilant-conductor':
                this.labelUnity = false;
                this.actionNew = false;
                this.viewProvider = true;
                this.inspection = true;
                this.check = true;
                this.exportExcel = false;
                break;
            case 'vigilant':
                this.labelUnity = false;
                this.actionNew = false;
                this.viewProvider = true;
                this.inspection = true;
                this.check = true;
                this.exportExcel = false;
                break;
            case 'lecturer':
                this.labelUnity = false;
                this.actionNew = false;
                this.viewProvider = false;
                this.inspection = false;
                this.check = false;
                this.exportExcel = false;
                break;
            case 'corporate-operator':
                this.labelUnity = false;
                this.actionNew = false;
                this.viewProvider = false;
                this.inspection = false;
                this.check = false;
                this.exportExcel = false;
                break;
            case 'local-operator':
                this.labelUnity = false;
                this.actionNew = false;
                this.viewProvider = false;
                this.inspection = false;
                this.check = false;
                this.exportExcel = false;
                break;
            case 'transport':
                this.labelUnity = false;
                this.actionNew = true;
                this.viewProvider = false;
                this.inspection = false;
                this.check = false;
                this.exportExcel = false;
                break;
        }
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.onSearch();
    }
}
