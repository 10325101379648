import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../base/base.component';
import {QueryRef} from 'apollo-angular';
import moment from 'moment';
import {
    CheckListAlarmBlocDto,
    CheckListAlarmComment,
    CheckListAlarmItem,
    CheckListAlarmUnfinishedDto, CheckListAlarmUpdateInput, CheckListAlarmUpdateInputItem,
    GetCheckListAlarmUnfinishedGQL,
    GetCheckListAlarmUnfinishedQuery,
    UpdateCheckListAlarmGQL
} from '../../../../generated/graphql';

@Component({
    selector: 'app-alarm-check-list',
    templateUrl: './alarm-control-create.component.html',
    styleUrls: ['./alarm-control-create.component.scss']
})
export class AlarmControlCreateComponent extends BaseComponent {

    checklistResult: CheckListAlarmUnfinishedDto = new CheckListAlarmUnfinishedDto();
    checklistQuery: QueryRef<GetCheckListAlarmUnfinishedQuery>;

    selectedBloc: CheckListAlarmBlocDto;
    selectedItem: CheckListAlarmItem = new CheckListAlarmItem();
    commentModel: CheckListAlarmComment = new CheckListAlarmComment();
    checkListId: string;

    constructor(public router: Router,
                public translate: TranslateService,
                public getCheckListAlarmUnfinishedGQL: GetCheckListAlarmUnfinishedGQL,
                public updateCheckListAlarmUnfinishedGQL: UpdateCheckListAlarmGQL,
                public activatedRoute: ActivatedRoute) {
        super(router, translate);
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.checkListId = url.id;
                this.checklistQuery = this.getCheckListAlarmUnfinishedGQL.watch({id: url.id});
                this.checklistQuery.valueChanges.subscribe({
                    next: ({data}) => {
                        this.checklistResult = data.checkListAlarmUnfinished as CheckListAlarmUnfinishedDto;
                        if (this.checklistResult.status === 'done') {
                            this.router.navigate([`alarm/detail/${this.checkListId}`]);
                        }
                    }
                });
            }
        });
    }

    actionSelectBloc(b: CheckListAlarmBlocDto) {
        if (b.total == b.done) {
            return;
        }
        this.selectedBloc = new CheckListAlarmBlocDto();
        const x = JSON.stringify(b);
        this.selectedBloc = JSON.parse(x) as CheckListAlarmBlocDto;
    }

    actionClearSelectedBloc() {
        this.selectedBloc = null;
    }

    actionOpenCommentModal(b: CheckListAlarmItem) {
        this.commentModel = new CheckListAlarmComment();
        this.selectedItem = Object.assign({}, b);
        if (!this.isNullOrUndefined(this.selectedItem)) {
            if (!this.isNullOrUndefined(this.selectedItem.comments)) {
                this.commentModel = this.selectedItem.comments[0];
            }
        }
        super.showModal('commentModal');
        if (this.isNullOrUndefined(this.selectedItem.dateLimit)) {
            this.selectedItem.dateLimit = moment().add(1, "day").utcOffset(0, true).format();
        }
        this.setDate('dateLimit', (start, end) => {
            this.selectedItem.dateLimit = end.utcOffset(0, true).format();
            this.selectedItem.dateLimit = end.utcOffset(0, true).format();
        }, this.selectedItem.dateLimit, true, null, 'DD/MM/YYYY - HH:mm');
    }

    actionAddComment(event) {
        this.commentModel = event;
        if (super.isNullOrUndefined(this.commentModel.comment)) {
            this.showMessage(this.translate.instant('atencao'), this.translate.instant('Preenchaoucliquesair'));
            return;
        }

        let listItem = this.selectedBloc.items.find(it => it.id === this.selectedItem.id);

        listItem.comments = [this.commentModel];
        listItem.dateLimit = this.selectedItem.dateLimit;

        super.closeModal('commentModal');
        this.selectedItem = new CheckListAlarmItem();
        this.commentModel = new CheckListAlarmComment();

    }

    actionSave() {
        let model = new CheckListAlarmUpdateInput();
        model.blocId = this.selectedBloc.id;
        model.checkListId = this.checkListId;

        const items: CheckListAlarmUpdateInputItem[] = [];

        for (const it of this.selectedBloc.items.filter(x => !this.isNullOrUndefined(x.condition))) {
            const m = new CheckListAlarmUpdateInputItem();
            m.dateLimit = it.dateLimit;
            m.condition = it.condition;
            m.comments = it.comments;
            m.id = it.id;

            items.push(m);
        }

        if (super.isNullOrUndefined(items)) {
            this.showMessage(this.translate.instant('atencao'), this.translate.instant('Preenchaumitemdalista'));
            return;
        }

        model.items = items;
        this.updateCheckListAlarmUnfinishedGQL.mutate({data: model}).subscribe({
            next: (dt) => {
                this.actionClearSelectedBloc();
                this.checklistQuery.refetch({id: this.checkListId});
                super.showMessage(this.translate.instant('success'), this.translate.instant('Checklistenviadocomsucesso'), 'success');
            }
        });
    }

}
