import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {
    Bloc,
    CreateTruckCheckListObject,
    CreateTruckUnityInput,
    EnumType, FindCpfGQL, FindCpfQuery,
    GetBlocByUnityGQL,
    GetBlocByUnityQuery,
    GetEnumByTypeAndCultureGQL,
    GetEnumByTypeAndCultureQuery, GetEnumByUnityGQL,
    GetEnumByUnityQuery,
    GetUnitiesGQL,
    GetUnitiesQuery,
    RemoveTruckChecklistPhotoGQL,
    Truck,
    TruckChecklist,
    TruckChecklistEnumGQL,
    TruckGQL,
    TruckHelper,
    TruckQuery,
    TruckReceipt,
    Unity,
    UpdateTruckCheckListInput,
    UpdateTruckGQL,
    UpdateTruckHelperInput,
    UpdateTruckInput,
    UpdateTruckReceiptInput,
    User,
} from "../../../../generated/graphql";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import moment from "moment";
import {QueryRef} from "apollo-angular";
import {ExcelService} from "../../../service/excel.service";
import {CreateTruckGQL} from "../../../../generated/graphql";
import {UserService} from "../../../service/user.service";

declare var $;
declare var window;
declare var requestAnimFrame;

@Component({
    selector: 'app-trucks-create',
    templateUrl: './trucks-create.component.html',
    styleUrls: ['./trucks-create.component.scss']
})
export class TrucksCreateComponent extends BaseComponent implements OnInit, AfterViewInit {

    user: User = new User();

    entranceMotive = 'shipment';
    checklistType = 'vehicle';
    checkOk = 'ok'
    flagSignature = false;
    imageSignature = undefined;
    // imageSignatureFile: File;

    truck: Truck = new Truck();
    helper: TruckHelper = new TruckHelper();
    helpers: TruckHelper[] = [];
    receipt: TruckReceipt = new TruckReceipt();
    receipts: TruckReceipt[] = [];
    unity: Unity[] = [];
    checklistsVehicle: TruckChecklist[] = [];
    checklistsTrailer: TruckChecklist[] = [];

    newMessage: string;
    typeSelected: string;
    indexSelected: number;

    fileSeal: File = null;
    thumbSeal: any = null;

    fileLicensePlate: File = null;
    thumbLicensePlate: any = null;

    fileAutoPlate: File = null;
    thumbAutoPlate: any = null;

    fileTrailerLicensePlate: File = null;
    thumbTrailerLicensePlate: any = null;

    checkListFile: File = null;
    truckQuery: QueryRef<TruckQuery>;

    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesQuery>;

    // cpfTruck: Array<Truck>;
    cpfQuery: QueryRef<FindCpfQuery>;

    deliveries: Array<EnumType>
    filterDeliveriesByUnityQuery: QueryRef<GetEnumByUnityQuery>;

    profile: boolean = false;
    hasTrailer: boolean = false;
    unityID: string;

    labelUnity: boolean = false;
    canvas: any;
    ctx: any;

    cpfTruck: { driverName?: string | null; company?: string | null };


    isTruck: boolean = false;

    constructor(public router: Router,
                public translate: TranslateService,
                private truckChecklistEnumGQL: TruckChecklistEnumGQL,
                private removeTruckChecklistPhotoGQL: RemoveTruckChecklistPhotoGQL,
                private truckGQL: TruckGQL,
                private updateTruckGQL: UpdateTruckGQL,
                private excelService: ExcelService,
                public userService: UserService,
                private getUnitiesGQL: GetUnitiesGQL,
                private getCpfGQL: FindCpfGQL,
                private getDeliveryByUnityGQL: GetEnumByUnityGQL,
                public activatedRoute: ActivatedRoute) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.profile = this.handlerProfile();
                this.actionUserProfileBTN();
                if (this.actionUserProfile() === false) {
                    this.unityID = this.user.unity.id;
                    this.getUnity();
                    this.getDeliveriesFilter();
                }
            }, error: err => super.onError(err)
        });
    }

    ngAfterViewInit(): void {
        this.getUnity();
        this.activatedRoute.params.subscribe(data => {
            this.truckGQL.watch({id: data.id}).valueChanges.subscribe(({data}) => {
                this.truck = data.truck as Truck;
                data.truck.checklist.forEach((e) => {
                    if (e.name === 'vehicle') {
                        this.checklistsVehicle.push(e);
                    } else {
                        this.checklistsTrailer.push(e);
                    }
                });
            }, error => super.onError(error));
            // $(() => {
            //     this.canvas = document.getElementById('sig-canvas');
            //     this.ctx = this.canvas.getContext('2d');
            //     this.handlerPaint();
            //     $(window).resize(() => {
            //         let W = this.canvas.offsetWidth, H = this.canvas.offsetHeight;
            //         let temp = this.ctx.getImageData(0, 0, W, H)
            //         this.ctx.canvas.width = parseInt(document.getElementById('sketch').offsetWidth.toString(), 0);
            //         this.ctx.canvas.height = parseInt(document.getElementById('sketch').offsetHeight.toString(), 0);
            //         this.ctx.putImageData(temp, 0, 0)
            //     })
            // });
            this.setDate('date-picker',
                (start, end, label) => {
                    this.truck.date = end.utcOffset(0, true).format();
                }, null, true, null, 'DD/MM/YYYY - HH:mm');
        });

    }

    getUnity() {
        this.unityQuery = this.getUnitiesGQL.watch();
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.unities as Array<Unity>;
        });
    }

    getDeliveriesFilter() {
        this.truck.place = null;
        this.deliveries = [];
        if (this.isNullOrUndefined(this.unityID)) {
            return;
        }
        this.filterDeliveriesByUnityQuery = this.getDeliveryByUnityGQL.watch({unityId: this.unityID});
        this.filterDeliveriesByUnityQuery.valueChanges.subscribe(({data}) => {
            this.deliveries = data.enumByUnity as Array<EnumType>;
        });
    }

    pushHelpersList() {
        if (this.isNullOrUndefined(this.helper.name) || this.isNullOrUndefined(this.helper.rg)) {
            return this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('form'), 'warning');
        }
        this.helpers.push(this.helper);
        this.helper = new TruckHelper();
    }

    pushReceiptList() {
        if (this.isNullOrUndefined(this.receipt.receipt)) {
            return this.showMessage(this.translate.instant('baseComponent.warningAttention'), 'Preencha o formulário corretamente para adicionar um nota fiscal', 'warning');
        }
        this.receipts.push(this.receipt);
        this.receipt = new TruckReceipt();
    }

    actionSignature() {
        let a = 0;
        const b = $('html, body');
        b.toggleClass('no-scroll');
        this.flagSignature = !this.flagSignature;
        if (!this.flagSignature) {
            a = $('.signature').offset().top;
        }
        b.stop().animate(10, { scrollTop: a });
        this.toggleFullScreen();
    }

    // handlerPaint() {
    //     $(() => {
    //         const st = this;
    //         this.canvas.width = parseInt(document.getElementById('sketch').offsetWidth.toString(), 0);
    //         this.canvas.height = parseInt(document.getElementById('sketch').offsetHeight.toString(), 0);
    //         window.requestAnimFrame = (function (callback) {
    //             return window.requestAnimationFrame ||
    //                 window.webkitRequestAnimationFrame ||
    //                 window.mozRequestAnimationFrame ||
    //                 window.oRequestAnimationFrame ||
    //                 window.msRequestAnimaitonFrame ||
    //                 function (callback) {
    //                     window.setTimeout(callback, st.canvas.width / st.canvas.height);
    //                 };
    //         })();
    //
    //         this.ctx.strokeStyle = '#222222';
    //         this.ctx.lineWith = 2;
    //
    //         // Set up the UI
    //         const sigText: any = document.getElementById('sig-dataUrl');
    //         const sigImage: any = document.getElementById('sig-image');
    //         const clearBtn: any = document.getElementById('sig-clearBtn');
    //         const submitBtn: any = document.getElementById('sig-submitBtn');
    //         clearBtn.addEventListener('click', function (e) {
    //             clearCanvas();
    //             // sigText.innerHTML = 'Data URL for your signature will go here!';
    //             // sigImage.setAttribute('src', '');
    //         }, false);
    //         submitBtn.addEventListener('click', function (e) {
    //             const dataUrl = st.canvas.toDataURL('image/png');
    //             const blobBin = atob(dataUrl.split(',')[1]);
    //             const array = [];
    //             for (let i = 0; i < blobBin.length; i++) {
    //                 array.push(blobBin.charCodeAt(i));
    //             }
    //             st.imageSignatureFile = new File([new Uint8Array(array)], 'signature.png', {
    //                 type: 'image/png',
    //                 lastModified: new Date().getTime()
    //             });
    //             st.imageSignature = dataUrl;
    //             st.actionSignature();
    //         }, false);
    //         // Set up mouse events for drawing
    //         let drawing = false;
    //         let mousePos = {x: 0, y: 0};
    //         let lastPos = mousePos;
    //         this.canvas.addEventListener('mousedown', function (e) {
    //             // st.flagSignature = true; // FLAG ASSINATURA PREENCHIDA
    //             drawing = true;
    //             lastPos = getMousePos(st.canvas, e);
    //         }, false);
    //         this.canvas.addEventListener('mouseup', function (e) {
    //             drawing = false;
    //         }, false);
    //         this.canvas.addEventListener('mousemove', function (e) {
    //             mousePos = getMousePos(st.canvas, e);
    //         }, false);
    //         // Set up touch events for mobile, etc
    //         this.canvas.addEventListener('touchstart', function (e) {
    //             mousePos = getTouchPos(st.canvas, e);
    //             const touch: any = e.touches[0];
    //             const mouseEvent: any = new MouseEvent('mousedown', {
    //                 clientX: touch.clientX,
    //                 clientY: touch.clientY
    //             });
    //             st.canvas.dispatchEvent(mouseEvent);
    //         }, false);
    //         this.canvas.addEventListener('touchend', function (e) {
    //             const mouseEvent: any = new MouseEvent('mouseup', {});
    //             st.canvas.dispatchEvent(mouseEvent);
    //         }, false);
    //         this.canvas.addEventListener('touchmove', function (e) {
    //             const touch: any = e.touches[0];
    //             const mouseEvent: any = new MouseEvent('mousemove', {
    //                 clientX: touch.clientX,
    //                 clientY: touch.clientY
    //             });
    //             st.canvas.dispatchEvent(mouseEvent);
    //         }, false);
    //         // Prevent scrolling when touching the canvas
    //         document.body.addEventListener('touchstart', function (e) {
    //             if (e.target === st.canvas) {
    //                 e.preventDefault();
    //             }
    //         }, false);
    //         document.body.addEventListener('touchend', function (e) {
    //             if (e.target === st.canvas) {
    //                 e.preventDefault();
    //             }
    //         }, false);
    //         document.body.addEventListener('touchmove', function (e) {
    //             if (e.target === st.canvas) {
    //                 e.preventDefault();
    //             }
    //         }, false);
    //
    //         // Get the position of the mouse relative to the canvas
    //         function getMousePos(canvasDom, mouseEvent) {
    //             const rect = canvasDom.getBoundingClientRect();
    //             return {
    //                 x: mouseEvent.clientX - rect.left,
    //                 y: mouseEvent.clientY - rect.top
    //             };
    //         }
    //
    //         // Get the position of a touch relative to the canvas
    //         function getTouchPos(canvasDom, touchEvent) {
    //             const rect = canvasDom.getBoundingClientRect();
    //             return {
    //                 x: touchEvent.touches[0].clientX - rect.left,
    //                 y: touchEvent.touches[0].clientY - rect.top
    //             };
    //         }
    //
    //         // Draw to the canvas
    //         function renderCanvas() {
    //             if (drawing) {
    //                 st.ctx.moveTo(lastPos.x, lastPos.y);
    //                 st.ctx.lineTo(mousePos.x, mousePos.y);
    //                 st.ctx.stroke();
    //                 lastPos = mousePos;
    //             }
    //         }
    //
    //         function clearCanvas() {
    //             st.canvas.width = st.canvas.width;
    //         }
    //
    //         // Allow for animation
    //         (function drawLoop() {
    //             requestAnimFrame(drawLoop);
    //             renderCanvas();
    //         })();
    //     });
    // }

    removeHelpers(memberId: string, index: number) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse ajudante?', () => {
            if (this.isNullOrUndefined(memberId)) {
                this.helpers.splice(index, 1);
            }
        });
    }

    removeReceipt(memberId: string, index: number) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir essa nota?', () => {
            if (this.isNullOrUndefined(memberId)) {
                this.receipts.splice(index, 1);
            }
        });
    }

    modalComment(index: number, type: string) {
        this.indexSelected = index;
        this.typeSelected = type;
        if (this.typeSelected == 'vehicle') {
            this.newMessage = this.checklistsVehicle[this.indexSelected].comment;
        } else {
            this.newMessage = this.checklistsTrailer[this.indexSelected].comment;
        }
        this.showModal('commentTruckCreate');
    }

    saveComment() {
        if (this.typeSelected == 'vehicle') {
            this.checklistsVehicle[this.indexSelected].comment = this.newMessage;
        } else {
            this.checklistsTrailer[this.indexSelected].comment = this.newMessage;
        }
        this.closeModal('commentTruckCreate');
        this.indexSelected = undefined;
        this.typeSelected = undefined;
    }


    setCroppedSeal(o: any) {
        this.thumbSeal = o.thumb;
        this.fileSeal = this.handleUpload(o.file);
    }

    setCroppedAuto(o: any) {
        this.thumbAutoPlate = o.thumb;
        this.fileAutoPlate = this.handleUpload(o.file);
    }

    setCroppedLicensePlate(o: any) {
        this.thumbLicensePlate = o.thumb;
        this.fileLicensePlate = this.handleUpload(o.file);
    }

    setCroppedTrailerLicensePlate(o: any) {
        this.thumbTrailerLicensePlate = o.thumb;
        this.fileTrailerLicensePlate = this.handleUpload(o.file);
    }


    modalUploadCheckList(index: number, type: string) {
        this.indexSelected = index;
        this.typeSelected = type;
        this.showModal('uploadCheckList');
    }


    removeFile(index: number, check: TruckChecklist) {
        if (this.isNullOrUndefined(check.photo)) {
            return;
        }
        console.log(check.name);
        if (check.name == 'vehicle') {
            this.checklistsVehicle[index].photo = '';
        } else {
            this.checklistsTrailer[index].photo = '';
        }
        this.removeTruckChecklistPhotoGQL.mutate({id: check.id}).subscribe(({data}) => {
        }, err => super.onError(err));
    }

    setCroppedCheckList(o: any) {
        let checkId;
        if (this.typeSelected == 'vehicle') {
            checkId = this.checklistsVehicle[this.indexSelected].id;
            this.checklistsVehicle[this.indexSelected].photo = o.thumb;
        } else {
            checkId = this.checklistsTrailer[this.indexSelected].id;
            this.checklistsTrailer[this.indexSelected].photo = o.thumb;
        }
        this.checkListFile = this.handleUpload(o.file);
        if (this.isNullOrUndefined(checkId)) {
            return this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('truck.checklist-not-found'), 'warning');
        }
        this.excelService.upload(this.checkListFile, `/upload/truck/${checkId}/truck-checklist`).subscribe({
            next: data2 => {
            }, error: err => super.onError(err)
        });
        this.indexSelected = undefined;
        this.typeSelected = undefined;
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.truck.driverName, text: `Nome do Motorista<br>`},
            {value: this.truck.driverCpf, text: `ID<br>`},
            {value: this.truck.company, text: `Empresa<br>`},
            {value: this.truck.licensePlate, text: `Placa do Cavalo<br>`},
            // {value: this.truck.trailerLicensePlate, text: `Placa do Reboque<br>`},
        ];
        if (this.actionUserProfile()) {
            arrValidateFields.push({value: this.unityID, text: `Unidade<br>`});
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }

        if (this.entranceMotive === 'delivery') {
            if (this.isNullOrUndefined(this.receipts)) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), `Adicione ao menos uma nota fiscal`);
                return;
            }
        }
        if (!this.isNullOrUndefined(this.truck.id)) {
            this.updateTruck();
        }
    }


    updateTruck() {
        let validateChecklist = 0;
        let inputCheckList: UpdateTruckCheckListInput[] = [];
        this.checklistsVehicle.forEach((e) => {
            if (this.isNullOrUndefined(e.statusTruck)) {
                validateChecklist += 1;
            }
            inputCheckList.push({
                id: e.id,
                comment: this.isNullOrUndefined(e.comment) ? '' : e.comment,
                statusTruck: e.statusTruck,
            })
        });
        if(this.hasTrailer) {
            this.checklistsTrailer.forEach((e) => {
                if (this.isNullOrUndefined(e.statusTruck)) {
                    validateChecklist += 1;
                }
                inputCheckList.push({
                    id: e.id,
                    comment: this.isNullOrUndefined(e.comment) ? '' : e.comment,
                    statusTruck: e.statusTruck,
                });
            });
        }

        let inputHelpers: UpdateTruckHelperInput[] = [];
        this.helpers.forEach((e) => {
            inputHelpers.push({name: e.name, rg: e.rg,});
        });
        let inputReceipts: UpdateTruckReceiptInput[] = [];
        this.receipts.forEach((e) => {
            inputReceipts.push({receipt: e.receipt});
        });
        // let inputUnity = new CreateTruckUnityInput();
        // this.unity.forEach((e) => {
        //     inputReceipts.push({id: e.id});
        // });
        const data: UpdateTruckInput = {
            unity: {id: this.unityID},
            id: this.truck.id,
            date: this.truck.date,
            driverName: this.truck.driverName,
            driverCpf: this.truck.driverCpf,
            company: this.truck.company,
            licensePlate: this.truck.licensePlate,
            trailerLicensePlate: this.truck.trailerLicensePlate,
            sealNumber: this.truck.sealNumber,
            place: this.truck.place,
            seal: this.isNullOrUndefined(this.truck.seal) ? false : this.truck.seal,
            sealCorrect: this.isNullOrUndefined(this.truck.sealCorrect) ? false : this.truck.sealCorrect,
            reason: this.entranceMotive,
            isAuto: this.isTruck,
            helpers: inputHelpers,
            receipts: this.entranceMotive == 'shipment' ? [] : inputReceipts,
        }
        if (this.isTruck === true){
            data.checklist= inputCheckList;
        }


        if (validateChecklist > 0 && this.isTruck === true) {
            this.showMessage(this.translate.instant('atencao'), this.translate.instant('preenchaoschecklistcorretamente'));
            return;
        }
        // if (this.isNullOrUndefined(this.imageSignatureFile)) {
        //     this.showMessage(this.translate.instant('atencao'), this.translate.instant('preenchaassinaturadigitalcorretamente'));
        //     return;
        // }
        this.updateTruckGQL.mutate({data: data}).subscribe(({data}) => {
            // this.excelService.upload(this.imageSignatureFile, `/upload/truck/${data.updateTruck.id}/signature`).subscribe({
            //     next: data2 => {
            //     }, error: err => super.onError(err)
            // });
            this.uploadFileTruck(data.updateTruck as Truck);
            super.showMessage(this.translate.instant('success'), this.translate.instant('caminhaocadastradocomsucesso'), 'success');
            this.router.navigate(['/truck']);
        }, error => super.onError(error));
    }


    uploadFileTruck(data: Truck) {
        if (!this.isNullOrUndefined(this.fileSeal)) {
            this.excelService.upload(this.fileSeal, `/upload/truck/${data.id}/truck-seal`).subscribe({
                next: data2 => {
                }, error: err => super.onError(err)
            });
        }
        if (!this.isNullOrUndefined(this.fileAutoPlate)) {
            this.excelService.upload(this.fileAutoPlate, `/upload/truck/${data.id}/auto`).subscribe({
                next: data2 => {
                }, error: err => super.onError(err)
            });
        }
        if (!this.isNullOrUndefined(this.fileLicensePlate)) {
            this.excelService.upload(this.fileLicensePlate, `/upload/truck/${data.id}/license-plate`).subscribe({
                next: data2 => {
                }, error: err => super.onError(err)
            });
        }
        if (!this.isNullOrUndefined(this.fileTrailerLicensePlate)) {
            this.excelService.upload(this.fileTrailerLicensePlate, `/upload/truck/${data.id}/trailer-license-plate`).subscribe({
                next: data2 => {
                }, error: err => super.onError(err)
            });
        }
    }

    handlerProfile() {
        return this.user.profile === 'vigilant' || this.user.profile === 'lecturer';
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
        }
    }

    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelUnity = true;
                break;
            case 'local-manager':
                this.labelUnity = false;
                break;
            case 'user':
                this.labelUnity = false;
                break;
            case 'vigilant-leader':
                this.labelUnity = false;
                break;
            case 'vigilant-conductor':
                this.labelUnity = false;
                break;
            case 'vigilant':
                this.labelUnity = false;
                break;
            case 'lecturer':
                this.labelUnity = false;
                break;
            case 'corporate-operator':
                this.labelUnity = false;
                break;
            case 'local-operator':
                this.labelUnity = false;
                break;
        }
    }

    searchCPF() {
        this.truck.company = null
        this.truck.driverName = null
        this.truck.licensePlate = null
        this.cpfQuery = this.getCpfGQL.watch({cpf: this.isNullOrUndefined(this.truck.driverCpf) ? '' : this.truck.driverCpf});
        this.cpfQuery.valueChanges.subscribe(({data}) => {
            this.truck.company = data.findCPF.company;
            this.truck.driverName = data.findCPF.driverName;
            this.truck.licensePlate = data.findCPF.licensePlate;
        });
    }
}
