<!-- Modal CROP -->
<div class="modal fade modal-crop" [id]="cropId" tabindex="-1" role="dialog" aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex align-items-center">
                    <svg-icon class="svg-icon-primary " src="assets/svg/addimage.svg"></svg-icon>
                    <h3 class="modal-title ml-3">
                        <strong class="text-primary">Enviar Imagem</strong>
                    </h3>
                </div>

                <a class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body" style="min-height: initial">
                <div [ngClass]="{'d-none':isNullOrUndefined(imageChangedEvent)}">
                    <div class="d-flex align-items-center mb-3" style="gap: 17px;">
                        <a href="javascript:void(0)" (click)="onReset()">
                            <svg-icon src="assets/svg/addimage.svg"></svg-icon>
                        </a>
                        <a href="javascript:void(0)" (click)="onImageRotate()">
                            <svg-icon src="assets/svg/rotate.svg"></svg-icon>
                        </a>
                        <a href="javascript:void(0)" (click)="onImageSave()">
                            <svg-icon src="assets/svg/check2.svg"></svg-icon>
                        </a>
                    </div>
                    <image-cropper
                            [format]="'png'"
                            [imageQuality]="40"
                            [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true"
                            [aspectRatio]="cropAspectRatio"
                            (imageCropped)="imageCropped($event)"
                            (imageLoaded)="imageLoaded()"
                            (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()"
                            [canvasRotation]="cropCanvasRotation"
                            [resizeToWidth]="cropResizeToWidth"
                            [resizeToHeight]="cropResizeToHeight"
                    ></image-cropper>
                </div>

                <div class="description__photo" [ngClass]="{'d-none':!isNullOrUndefined(imageChangedEvent)}">
                    <div class="file-field text-center">
                        <label class="mb-0">
                            <img src="assets/images/prevent-upload.png" alt="Upload"
                                 class="img-fluid is-cursor"/>
                            <input id="cropped" type="file" class="description__photo--file d-none"
                                   name="profile_photo" onclick="this.value = null"
                                   (change)="fileChangeEvent($event)" #newItem/>
                        </label>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
