import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-physical-security',
    template: '<router-outlet></router-outlet>',
})
export class PhysicalSecurityComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
