<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon src="assets/svg/truck.svg"></svg-icon>
            <h1 class="page-title">{{'praanchetaeletronica'|translate}} | <span>{{'caminhoes'|translate}}</span></h1>
            <a class="btn btn-gray-fill" (click)="actionExcel()" *ngIf="exportExcel">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                {{"exportarexcel" | translate}}
            </a>
            <a (click)="onCreate()" class="btn btn-secondary" *ngIf="actionNew">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                {{'novocaminhao'|translate}}
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'busca'|translate}}</span>
                </label>
            </div>
            <div class="input-material">
                <input id="date" class="form-control date-picker" type="text" required/>
                <label for="date">
                    <span>{{"period" | translate}}</span>
                </label>
            </div>
            <div class="input-material select" *ngIf="labelUnity">
                <select name="unity" id="unity"
                        [(ngModel)]="filter.unity"
                        class="form-control" required>
                    <option [ngValue]="undefined">{{'occurrence.selectunit'|translate}}</option>
                    <option *ngFor="let c of unities" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="unity">{{'unidade'|translate}}</label>
            </div>
            <a class="btn btn-primary btn-lg text-white offset-lg-2"
               (click)="onSearch()">{{'settings.dosearch'|translate}}</a>
        </div>

        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col">{{"truck.plate" | translate}}</th>
                    <th scope="col">{{"truck.ent" | translate}}</th>
                    <th scope="col">{{"truck.cd" | translate}}</th>
                    <th class="text-center" scope="col">{{"truck.sai" | translate}}</th>
<!--                    <th class="text-center" scope="col">{{"truck.portao" | translate}}</th>-->
                    <th class="text-center" scope="col">{{"truck.enterprise" | translate}}</th>
                    <th class="text-center" scope="col">{{"truck.driver" | translate}}</th>
                    <th class="text-center" scope="col">{{"truck.date" | translate}}</th>
                    <th class="text-center" scope="col">{{"settings.status" | translate}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(trucks)">
                    <td colspan="8">
                        <p class="text-center my-5">{{"settings.no-result" | translate}}</p>
                    </td>
                </tr>
                <tr *ngFor="let list of trucks.results |
                paginate: {itemsPerPage: trucks.PageSize,
                currentPage: trucks.currentPage,
                totalItems: trucks.totalCount }">
                    <td>{{list.licensePlate}}</td>
                    <td>{{list.createdBy?.name}}</td>
                    <td>{{list.inspection?.createdBy?.name}}</td>
                    <td class="text-center">{{list.release?.createdBy?.name}}</td>
                    <td class="text-center">{{list.company}}</td>
                    <td class="text-center">{{list.driverName}}</td>
                    <td class="text-center">{{list.date |date:'dd/MM/yy - HH:mm':'UTC+0'}}</td>
                    <td>

                        <a class="status_active bg-warning text-primary is-cursor" (click)="modalInspect(list)"
                           *ngIf="list.status === 'inspect'">{{getTranslate(list.status + 'Truck')}}</a>

                        <a class="status_active bg-info is-cursor" (click)="modalRelease(list)"
                           *ngIf="list.status === 'release'">{{getTranslate(list.status)}}</a>

                        <a class="status_active bg-success"
                           *ngIf="list.status === 'finished'">{{getTranslate(list.status)}}</a>

                    </td>
                    <td class="text-center is-cursor">
                        <svg-icon src="assets/svg/eye.svg" [routerLink]="['detail/'+list.id]"></svg-icon>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

<div class="modal fade" id="inspectTruck" tabindex="-1" role="dialog" aria-labelledby="inspectTruck"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">Inspecionar Veículo</h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body modal-body--small">
                <!--NAME-->
                <div class="card_ret row">
                    <div class="col-12 col-md-4">
                        <span><strong>Horário</strong> do processo</span>
                    </div>

                    <div class="col-12 col-md-8">
                        <div class="input-material">
                            <input id="proccessDate" class="form-control date-truck" type="text" required/>
                            <label for="proccessDate">
                                Data
                            </label>
                        </div>
                    </div>

                </div>
                <div class="card_ret row">
                    <div class="col-12 col-md-4">
                        <span><strong>Placa</strong> do reboque</span>
                    </div>

                    <div class="col-12 col-md-8">
                        <div class="input-material">
                            <input id="trailerLicensePlate" class="form-control" type="text" required maxlength="7"
                                   [(ngModel)]="
                           inspectTruck.trailerLicensePlate"/>
                            <label for="trailerLicensePlate">
                                Placa
                            </label>
                        </div>
                    </div>

                </div>
                <div class="card_ret row">
                    <div class="col-12 col-md-4">
                        <span>Notas <strong>Fiscais</strong></span>
                    </div>

                    <div class="col-12 col-md-8">
                        <div class="row align-items-center">
                            <div class="col-12 col-md-6">
                                <div class="input-material">
                                    <input id="roteNumber" [(ngModel)]="receipt.receipt" class="form-control"
                                           type="text"
                                           required/>
                                    <label for="roteNumber">
                                        Número da Nota
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <button (click)="pushReceiptList()" class="btn btn-warning btn-block">
                                    <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                                    <span class="pl-2">{{'adicionar'|translate}}</span>
                                </button>
                            </div>
                            <div class="col-12" *ngIf="!isNullOrUndefined(inspectTruck.receipt)">
                                <div class="table-responsive">
                                    <table class="table table_regular">
                                        <thead>
                                        <tr>
                                            <th scope="col">Número da Nota Fiscal</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let r of inspectTruck.receipt;let i = index">
                                            <td style="padding: 0.7em 0.05em !important;">{{r.receipt}}</td>
                                            <td style="padding: 0.7em 0.05em !important;">
                                                <svg-icon (click)="removeReceipt(i)"
                                                          src="assets/svg/close-circle.svg"></svg-icon>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card_ret row">
                    <div class="col-12 col-md-4">
                        <span>Numero <strong>Lacre</strong></span>
                    </div>
                    <div class="col-12 col-md-8 row">
                        <div class="col-12">
                            <div class="input-material">
                                <input id="lacreNumber" [(ngModel)]="inspectTruck.sealNumber" class="form-control"
                                       type="text" required/>
                                <label for="lacreNumber">
                                    Número do Lacre
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="uploaded is-cursor mb-0" (click)="showModal('uploadInspectSealPhoto')">
                        <h5>Foto do lacre</h5>
                        <ul>
                            <li *ngIf="isNullOrUndefined(thumbSeal)">
                                Nenhuma imagem anexada
                            </li>
                            <li *ngIf="!isNullOrUndefined(thumbSeal)">
                                <img src="{{thumbSeal}}" alt="seal">
                            </li>
                        </ul>
                    </div>
                </div>

                <p class="text-primary">{{'comentário' | translate}}</p>
                <div class="input-material w-100">
                    <textarea name="truckCommentFinal" [(ngModel)]="inspectTruck.comment" id="InspectCommentFinal"
                              type="text"
                              class="form-control W-100"></textarea>
                    <label for="InspectCommentFinal">Escreva aqui</label>
                </div>

                <div class="mx-auto" style="max-width: 573px;width: 100%">
                    <button (click)="actionInspect()" class="btn btn-block btn-warning w-100">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="releaseTruck" tabindex="-1" role="dialog" aria-labelledby="releaseTruck"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    Liberar Veículo
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body modal-body--small">
                <!--NAME-->
                <div class="card_ret row">
                    <div class="col-12 col-md-4">
                        <span><strong>Horário</strong> do processo</span>
                    </div>

                    <div class="col-12 col-md-8 row">
                        <div class="col-12">
                            <div class="input-material">
                                <input id="releaseTruckDate" [(ngModel)]="releaseTruck.createdAt"Liberar Veículo
                                       class="form-control" type="text" disabled required/>
                                <label class="disabled" for="releaseTruckDate">Data</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card_ret row" *ngIf="releaseTruck?.receipt?.length !== 0">
                    <div class="col-12 col-md-4">
                        <span>Notas <strong>Fiscais</strong></span>
                    </div>

                    <div class="col-12 col-md-8 ">
                        <ng-container *ngFor="let c of releaseTruck.receipt,let i = index">
                            <div class="truck_scroll_card_comment">
                                <div class="top top-inspection">
                                    <div class="d-flex">
                                        <b>{{i + 1}} -</b>
                                        <p class="card_ret-truck-p">{{c.receipt.receipt}}</p>
                                    </div>
                                    <div class="d-flex flex-wrap justify-content-center" style="gap: 10px">
                                        <div class="check-btn" (click)="c.status = true"
                                             [ngClass]="{'btn-success': c.status === true}">
                                            <svg-icon src="assets/svg/check.svg"></svg-icon>
                                        </div>
                                        <div (click)="c.status = false" class="check-btn"
                                             [ngClass]="{'btn-danger': c.status === false}">
                                            <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                                        </div>
                                        <div *ngIf="!isNullOrUndefined(c.status)"
                                             class="check-btn">
                                            <svg-icon (click)="modalComment(i)" src="assets/svg/comment.svg"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="!isNullOrUndefined(c.comment)" class="row_comment">{{c.comment}}</p>
                            </div>
                        </ng-container>
                    </div>

                </div>
                <div class="card_ret row" *ngIf="sealNumberSelected !== undefined">
                    <div class="col-12 col-md-4">
                        <span>Numero <strong>Lacre</strong></span>
                    </div>

                    <div class="col-12 col-md-8">
                        <div class="truck_scroll_card_comment">
                            <div class="top top-inspection">
                                <div class="d-flex">
                                    <p class="card_ret-truck-p">{{sealNumberSelected}}</p>
                                </div>
                                <div class="d-flex flex-wrap justify-content-center" style="gap: 10px">
                                    <div class="check-btn" (click)="releaseTruck.sealStatus = true"
                                         [ngClass]="{'btn-success': releaseTruck.sealStatus === true}">
                                        <svg-icon src="assets/svg/check.svg"></svg-icon>
                                    </div>
                                    <div (click)="releaseTruck.sealStatus = false" class="check-btn"
                                         [ngClass]="{'btn-danger': releaseTruck.sealStatus === false}">
                                        <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                                    </div>
                                    <div *ngIf="!isNullOrUndefined(releaseTruck.sealStatus)"
                                         class="check-btn">
                                        <svg-icon (click)="modalSealComment()" src="assets/svg/comment.svg"></svg-icon>
                                    </div>
                                </div>
                            </div>
                            <p *ngIf="!isNullOrUndefined(releaseTruck.sealComment)"
                               class="row_comment">{{releaseTruck.sealComment}}</p>
                        </div>
                    </div>
                    <div class="uploaded is-cursor mb-0" (click)="showModal('uploadRelaseSealPhoto')">
                        <h5>Foto do lacre</h5>
                        <ul>
                            <li *ngIf="isNullOrUndefined(thumbLicensePlate)">
                                Nenhuma imagem anexada
                            </li>
                            <li *ngIf="!isNullOrUndefined(thumbLicensePlate)">
                                <img src="{{thumbLicensePlate}}" alt="seal">
                            </li>
                        </ul>
                    </div>

                </div>

                <p class="text-primary">{{'comentário' | translate}}</p>
                <div class="input-material w-100">
                    <textarea name="truckCommentFinal" [(ngModel)]="releaseTruck.comment" id="truckCommentFinal"
                              type="text"
                              class="form-control W-100"></textarea>
                    <label for="truckCommentFinal">Escreva aqui</label>
                </div>

                <div class="mx-auto" style="max-width: 573px;width: 100%">
                    <button (click)="actionRelease()" class="btn btn-block btn-warning w-100">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="commentTruck" tabindex="-1" role="dialog"
     aria-labelledby="commentTruck"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    Inspeção {{'comentário' | translate}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body justify-content-between modal-body--small">
                <div class="row px-4">
                    <div class="col-12">
                        <p>
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in but the majority have suffered alteration in but the majority
                            have suffered alteration in
                        </p>
                        <div class="input-material">
                            <textarea id="newMessage" rows="5" [(ngModel)]="newMessage" class="form-control" type="text"
                                      required></textarea>
                            <label for="newMessage">
                                <span>Escreva aqui</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row col-12 justify-content-center mt-3">
                    <div class="col-md-6">
                        <button (click)="saveComment()" class="btn btn-block btn-warning w-100">Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="commentTruckSeal" tabindex="-1" role="dialog"
     aria-labelledby="commentTruckSeal"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    Inspeção {{'comentário' | translate}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body justify-content-between modal-body--small">
                <div class="row px-4">
                    <div class="col-12">
                        <p>
                            There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in but the majority have suffered alteration in but the majority
                            have suffered alteration in
                        </p>
                        <div class="input-material">
                            <textarea id="newMessageSeal" rows="5" [(ngModel)]="newMessage" class="form-control"
                                      type="text"
                                      required></textarea>
                            <label for="newMessageSeal">
                                <span>Escreva aqui</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row col-12 justify-content-center mt-3">
                    <div class="col-md-6">
                        <button (click)="saveSealComment()" class="btn btn-block btn-warning w-100">Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-crop-image [cropId]="'uploadInspectSealPhoto'"
                [cropAspectRatio]="2"
                (croppedImageResult)="setCroppedSeal($event)">
</app-crop-image>

<app-crop-image [cropId]="'uploadRelaseSealPhoto'"
                [cropAspectRatio]="2"
                (croppedImageResult)="setCroppedLicensePlate($event)">
</app-crop-image>
