import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {HomeComponent} from './views/home/home.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {OccurrenceNewComponent} from './views/occurrence/occurrence-new/occurrence-new.component';
import {OccurrenceDetailComponent} from './views/occurrence/detail/occurrence-detail.component';
import {OccurrenceComponent} from './views/occurrence/occurrence.component';
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {OccurrenceListComponent} from './views/occurrence/occurrence-list/occurrence-list.component';
import {PhysicalSecurityComponent} from './views/physical-security/physical-security.component';
import {PoliciesProceduresComponent} from './views/policies-procedures/policies-procedures.component';
import {SettingsComponent} from './views/settings/settings.component';
import {AccessControlComponent} from './views/access-control/access-control.component';
import {AlarmComponent} from './views/alarm/alarm.component';
import {CftvComponent} from './views/cftv/cftv.component';
import {AlarmControlHomeComponent} from './views/alarm/home/alarm-control-home.component';
import {CftvHomeComponent} from './views/cftv/home/cftv-home.component';
import {AccessControlHomeComponent} from './views/access-control/home/access-control-home.component';
import {PhysicalSecurityHomeComponent} from './views/physical-security/home/physical-security-home.component';
import {PoliciesProceduresHomeComponent} from './views/policies-procedures/home/policies-procedures-home.component';
import {TrucksHomeComponent} from './views/trucks/home/trucks-home.component';
import {TrucksComponent} from './views/trucks/trucks.component';
import {ServicesComponent} from './views/services/services.component';
import {ServicesHomeComponent} from './views/services/home/services-home.component';
import {CameraComponent} from './views/settings/camera/camera.component';
import {AccessComponent} from './views/settings/access/access.component';
import {SettingsAlarmComponent} from './views/settings/alarm/settings-alarm.component';
import {BlockComponent} from './views/settings/block/block.component';
import {UnityComponent} from './views/settings/unity/unity.component';
import {AccessControlDetailComponent} from './views/access-control/detail/access-control-detail.component';
import {PhysicalSecurityDetailComponent} from './views/physical-security/detail/physical-security-detail.component';
import {AlarmControlDetailComponent} from './views/alarm/detail/alarm-control-detail.component';
import {CftvDetailComponent} from './views/cftv/detail/cftv-detail.component';
import {UserResolver} from './resolver/user.resolve';
import {AccessControlCreateComponent} from './views/access-control/create/access-control-create.component';
import {CftvControlCreateComponent} from './views/cftv/create/cftv-control-create.component';
import {AlarmControlCreateComponent} from './views/alarm/create/alarm-control-create.component';
import {PhysicalSecurityCreateComponent} from './views/physical-security/create/physical-security-create.component';
import {TrucksCreateComponent} from './views/trucks/create/trucks-create.component';
import {ServicesDetailComponent} from './views/services/detail/services-detail.component';
import {TrucksDetailComponent} from './views/trucks/detail/trucks-detail.component';
import {ServicesCreateComponent} from './views/services/create/services-create.component';
import {ServicesSurveyComponent} from './views/services/survey/services-survey.component';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {GeneralConfigComponent} from './views/settings/general-config/general-config.component';

const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'authenticate', component: AuthenticateComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {
        path: 'recovery/:code', component: RecoveryComponent
    },
    {
        path: '', component: MainComponent,
        children: [
            // {path: 'register', component: RegisterComponent},
            // {path: 'register/:id', component: RegisterComponent},
            // {path: 'register-detail', component: RegisterDetailComponent},
            // {path: 'my-list', component: MyListComponent},
            {path: 'dashboard', component: DashboardComponent},
            {path: 'user', component: UserComponent},
            // {path: 'ocorrencias', component: OccurrenceComponent},
            {
                path: 'occurrence', component: OccurrenceComponent,
                children: [
                    {path: '', component: OccurrenceListComponent},
                    {path: 'new', component: OccurrenceNewComponent},
                    {path: 'new/:id', component: OccurrenceNewComponent},
                    {path: 'detail/:id', component: OccurrenceDetailComponent},
                ]
            },
            {
                path: 'access-control', component: AccessControlComponent, children: [
                    {path: '', component: AccessControlHomeComponent},
                    {path: 'create/:id', component: AccessControlCreateComponent},
                    {path: 'detail/:id', component: AccessControlDetailComponent},
                ]
            },
            {
                path: 'alarm', component: AlarmComponent, children: [
                    {path: '', component: AlarmControlHomeComponent},
                    {path: 'create/:id', component: AlarmControlCreateComponent},
                    {path: 'detail/:id', component: AlarmControlDetailComponent},
                ]
            },
            {
                path: 'cftv', component: CftvComponent, children: [
                    {path: '', component: CftvHomeComponent},
                    {path: 'create/:id', component: CftvControlCreateComponent},
                    {path: 'detail/:id', component: CftvDetailComponent},
                ]
            },
            {
                path: 'physical-security', component: PhysicalSecurityComponent, children: [
                    {path: '', component: PhysicalSecurityHomeComponent},
                    {path: 'detail/:id', component: PhysicalSecurityDetailComponent},
                    {path: 'create/:id', component: PhysicalSecurityCreateComponent},
                    {path: 'create', component: PhysicalSecurityCreateComponent},
                ]
            },
            {
                path: 'policies-procedures', component: PoliciesProceduresComponent, children: [
                    {path: '', component: PoliciesProceduresHomeComponent},
                ]
            },
            {
                path: 'truck', component: TrucksComponent, children: [
                    {path: '', component: TrucksHomeComponent},
                    {path: 'create', component: TrucksCreateComponent},
                    {path: 'create/:id', component: TrucksCreateComponent},
                    {path: 'detail/:id', component: TrucksDetailComponent},
                ]
            },
            {
                path: 'services', component: ServicesComponent, children: [
                    {path: '', component: ServicesHomeComponent},
                    {path: 'detail/:id', component: ServicesDetailComponent},
                    {path: 'create/:id', component: ServicesCreateComponent},
                    {path: 'survey/:id', component: ServicesSurveyComponent},
                    {path: 'inspection/:id/:sub', component: ServicesSurveyComponent},
                ]
            },
            {
                path: 'settings', component: SettingsComponent, children: [
                    {path: 'camera', component: CameraComponent},
                    {path: 'access', component: AccessComponent},
                    {path: 'alarm', component: SettingsAlarmComponent},
                    {path: 'block', component: BlockComponent},
                    {path: 'unity', component: UnityComponent},
                    {path: 'general', component: GeneralConfigComponent},
                ]
            },
            {path: 'occurrence-list', component: HomeComponent},
            {path: '', component: HomeComponent},
        ],
        resolve: {
            user: UserResolver
        }
        // canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
