import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    Bloc,
    CheckListCftvFinishedDto,
    CheckListCftvFinishedFilter,
    CheckListCftvItem,
    CheckListCftvItemStatusUpdateInput,
    GetBlocByUnityGQL,
    GetCheckListCftvFinishedGQL,
    GetCheckListCftvFinishedQuery,
    UpdateCheckListCftvItemStatusGQL
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {ExcelService} from "../../../service/excel.service";

@Component({
    selector: 'app-detail',
    templateUrl: './cftv-detail.component.html',
    styleUrls: ['./cftv-detail.component.scss']
})
export class CftvDetailComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    filter: CheckListCftvFinishedFilter = new CheckListCftvFinishedFilter();
    checkList: CheckListCftvFinishedDto = new CheckListCftvFinishedDto();
    checkListCftvQuery: QueryRef<GetCheckListCftvFinishedQuery>;
    selectedItem: CheckListCftvItem = new CheckListCftvItem();
    status = ['pending', 'working', 'late', 'done'];
    pushComment: string;
    blocs: Bloc[] = [];
    statusCheckList: string;

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public translate: TranslateService,
        private getCheckListCftvFinishedGQL: GetCheckListCftvFinishedGQL,
        private getBlocByUnityGQL: GetBlocByUnityGQL,
        private UpdateCheckListCftvItemStatusGQL: UpdateCheckListCftvItemStatusGQL,
        private excelService: ExcelService) {
        super(router, translate);
    }

    ngOnDestroy(): void {
        this.destroyModal('statusModal');
    }

    ngAfterViewInit(): void {
        this.activatedRoute.params.subscribe(data => {
            this.filter.id = data.id;
            this.filter.page = 1;
            this.getSearchCheckList()
        })
    }

    getSearchCheckList() {
        this.checkListCftvQuery = this.getCheckListCftvFinishedGQL.watch({data: this.filter});
        this.checkListCftvQuery.valueChanges.subscribe(({data}) => {
            this.checkList = data.checkListCftvFinished as CheckListCftvFinishedDto;
            if (this.isNullOrUndefined(this.blocs)) {
                this.getBlocByUnityGQL.watch({unityId: this.checkList.checklist.unity.id}).valueChanges.subscribe(({data}) => {
                    this.blocs = data.blocByUnity as Bloc[];
                }, error => super.onError(error));
            }
        }, error => super.onError(error));
    }

    onSearch() {
        this.checkListCftvQuery.refetch({data: this.filter});
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.onSearch();
    }

    onModalDetail(c: CheckListCftvItem) {
        this.selectedItem = Object.assign({}, c);
        this.pushComment = undefined;
        this.statusCheckList = this.selectedItem.status;
        this.showModal('statusModal');
        console.log(this.selectedItem);
    }

    onSave() {
        if (this.isNullOrUndefined(this.pushComment)) {
            return this.showMessage(this.translate.instant('atencao'), this.translate.instant('comentarioobrigatorio'));
        }
        let data: CheckListCftvItemStatusUpdateInput = new CheckListCftvItemStatusUpdateInput();
        data.itemId = this.selectedItem.id;
        data.status = this.statusCheckList;
        data.comment = this.pushComment;
        this.UpdateCheckListCftvItemStatusGQL.mutate({data: data}).subscribe(({data}) => {
            this.onSearch();
            this.closeModal('statusModal');
            super.showMessage(this.translate.instant('success'), this.translate.instant('checklistalterado'), 'success');
        }, error => super.onError(error));
    }

    actionExcel() {
        this.excelService.getExcelCheckListCftv(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }
}
