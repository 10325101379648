<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon src="assets/svg/user-blue.svg"></svg-icon>
            <h1 class="page-title"> {{'usuarios'|translate}}</h1>
            <div class="d-flex flex-wrap" style="gap: 15px">
                <button class="btn btn-gray-fill flex-grow-1" (click)="actionExcel()" *ngIf="exportExcel">
                    <svg-icon src="assets/svg/downloads.svg"></svg-icon>
                    {{'exportarexcel'|translate}}
                </button>
                <a (click)="create()" class="btn btn-secondary flex-grow-1" *ngIf="newUser">
                    <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                    {{"user.new-user" | translate}}
                </a>
            </div>

        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" autocomplete="off" type="text" required
                       [(ngModel)]="filter.keyword"
                       [maxLength]="80"/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>{{'busca'|translate}}</span>
                </label>
            </div>
            <div class="input-material select">
                <select id="role" class="form-control" required [(ngModel)]="filter.role">
                    <option disabled value hidden selected></option>
                    <option value="admin">{{'profiles.admin'|translate}}</option>
                    <option value="user">{{'profiles.user'|translate}}</option>
                    <option value="lecturer">{{'profiles.lecturer'|translate}}</option>
                    <option value="vigilant">{{'profiles.vigilant'|translate}}</option>
                    <option value="vigilant-leader">{{'profiles.vigilant-leader'|translate}}</option>
                    <option value="vigilant-conductor">{{'profiles.vigilant-conductor'|translate}}</option>
                    <option value="local-manager">{{'profiles.local-manager'|translate}}</option>
                    <option value="local-operator">{{'profiles.local-operator'|translate}}</option>
                    <option value="corporate-operator">{{'profiles.corporate-operator'|translate}}</option>
                    <option value="transport">{{'profiles.transport'|translate}}</option>
                </select>
                <label for="role">{{"user.profile" | translate}}</label>
            </div>
            <div class="input-material select" *ngIf="labelCountry">
                <select name="country" id="country" (change)="getUnitiesFilter()"
                        [(ngModel)]="filterCountryId"
                        class="form-control" required>
                    <option [ngValue]="null">Todos</option>
                    <option *ngFor="let c of filterCountries" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="country">{{"settings.country" | translate}}</label>
            </div>
            <div class="input-material select" *ngIf="labelUnity">
                <select name="unity" id="unity" [(ngModel)]="filterUnityId"
                        class="form-control" required>
                    <option [ngValue]="null">Selecione um país</option>
                    <option *ngFor="let c of filterUnities" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="unity">{{"settings.unity" | translate}}</label>
            </div>
            <a href="javascript:void(0)" (click)="onFilter()"
               class="btn btn-primary btn-lg text-white flex-grow-1"
               type="button">
                <span>{{'settings.dosearch'|translate}}</span>
            </a>
        </div>

        <div class="table-responsive">
            <table class="table table_regular custom-table-width">
                <thead>
                <tr>
                    <th scope="col">{{"name" | translate}}</th>
                    <th scope="col">{{"settings.country" | translate}}</th>
                    <th scope="col">{{"unidade" | translate}}</th>
                    <th scope="col">{{"user.role" | translate}}</th>
                    <th scope="col">{{"email" | translate}}</th>
                    <th class="text-center" scope="col">{{"settings.status" | translate}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(users)">
                    <td colspan="7" class="text-center">
                        {{"settings.no-result" | translate}}
                    </td>
                </tr>
                <tr *ngFor="let x of users | paginate: {
                     itemsPerPage: modelSearchResult.PageSize,
                     currentPage: modelSearchResult.currentPage,
                     totalItems: modelSearchResult.totalCount}">
                    <td>{{x.name}}</td>
                    <td>
                        <img *ngIf="!isNullOrUndefined(x.unity?.country)"
                             [src]="'assets/images/country/' + x.unity.country?.flag + '.svg'"
                             alt="img"
                             width="30px"/>
                    </td>
                    <td>{{x.unity.name}}</td>
                    <td>{{x.office}}</td>
                    <td>{{x.email}}</td>
                    <td class="text-center">{{x.status ? ('active' | translate) : ('inactive' | translate)}}</td>
                    <td class="text-center">
                        <div class="table_action" *ngIf="actionUpdate">
                            <svg-icon src="assets/svg/edit.svg" (click)="actionEdit(x)"></svg-icon>
                            <svg-icon src="assets/svg/close-red.svg"
                                      (click)="actionDelete(x.id)">
                            </svg-icon>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center mt-3">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

<div class="modal fade" id="userModal" tabindex="-1" role="dialog" aria-labelledby="userModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{isNullOrUndefined(this.model.id) ? this.translate.instant('novouser') : this.translate.instant('editaruser')}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body mh-0 mb-0">
                <div class="col-12 mb-0">
                    <div class="row col-12 mb-0">
                        <div class="col-12 d-flex justify-content-center">
                            <img class="mb-3" id="svg_login_profile" src="assets/images/photologin.png"
                                 (click)="showModal('modalCropLogin')"/>
                            <img class="img_login" *ngIf="croppedImage && !!isNullOrUndefined" src="{{croppedImage}}"
                                 alt="">
                        </div>
                        <!--NAME-->
                        <div class="col-12">
                            <div class="input-material">
                                <input id="userName" class="form-control" type="text" name="name"
                                       [(ngModel)]="model.name" [maxLength]="80"/>
                                <label for="blocTipo">
                                    <span>*{{'name'|translate}}</span>
                                </label>
                            </div>
                        </div>
                        <!-- COUNTRY DROPDOWN -->
                        <div class="col-6">
                            <div class="input-material  select" *ngIf="labelCountry">
                                <select name="countryId" id="countryId" (change)="getUnities()" [disabled]="user.profile === 'local-manager'"
                                        [(ngModel)]="selectedCountryId"
                                        class="form-control" required>
                                    <option [value]="undefined">País</option>
                                    <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name}}</option>
                                </select>
                                <label for="countryId" [ngClass]="{'disabled': user.profile === 'local-manager'}">*{{'pais'|translate}}</label>
                            </div>
                        </div>
                        <!--UNITY DROPDOWN-->
                        <div class="col-6">
                            <div class="input-material select" *ngIf="labelUnity">
                                <select name="blocUnity" id="blocUnity" [(ngModel)]="selectedUnityId"
                                        class="form-control" required>
                                    <option [value]="undefined">Unidade</option>
                                    <option *ngFor="let u of unities" [ngValue]="u.id">{{u.name}}</option>
                                </select>
                                <label for="blocUnity">*{{'unidade'|translate}}</label>
                            </div>
                        </div>
                        <!-- PROFILE -->
                        <div class="col-6">
                            <div class="input-material select">
                                <select name="profile" id="profile" [(ngModel)]="model.profile"
                                        class="form-control" required>
                                    <option [value]="undefined"> Perfil</option>
                                    <option value="admin">{{'profiles.admin'|translate}}</option>
                                    <option value="user">{{'profiles.user'|translate}}</option>
                                    <option value="lecturer">{{'profiles.lecturer'|translate}}</option>
                                    <option value="vigilant">{{'profiles.vigilant'|translate}}</option>
                                    <option value="vigilant-leader">{{'profiles.vigilant-leader'|translate}}</option>
                                    <option value="vigilant-conductor">{{'profiles.vigilant-conductor'|translate}}</option>
                                    <option value="local-manager">{{'profiles.local-manager'|translate}}</option>
                                    <option value="local-operator">{{'profiles.local-operator'|translate}}</option>
                                    <option value="corporate-operator">{{'profiles.corporate-operator'|translate}}</option>
                                    <option value="transport">{{'profiles.transport'|translate}}</option>
                                </select>
                                <label for="profile">{{'profile'|translate}}</label>
                            </div>
                        </div>
                        <!--OFICCE-->
                        <div class="col-6">
                            <div class="input-material">
                                <input id="userOffice" class="form-control" type="text" required
                                       [(ngModel)]="model.office" [maxLength]="80"/>
                                <label for="userOffice">
                                    <span>*{{'office'|translate}}</span>
                                </label>
                            </div>
                        </div>
                        <!-- FONE -->
                        <div class="col-6">
                            <div class="input-material">
                                <input id="userFone" class="form-control" type="text" required
                                       [(ngModel)]="model.phone" [maxLength]="80"
                                       mask="(00) 0 0000-0000||(00) 0000-0000"/>
                                <label for="userFone">
                                    <span>*{{'phone'|translate}}</span>
                                </label>
                            </div>
                        </div>
                        <!-- EMAIL -->
                        <div class="col-6">
                            <div class="input-material">
                                <input style="text-transform: lowercase" id="blocTipo" class="form-control" type="text" required
                                       [(ngModel)]="model.email" [maxLength]="80"/>
                                <label for="blocTipo">
                                    <span>*{{'email'|translate}}</span>
                                </label>
                            </div>
                        </div>
                        <!-- STATUS -->
                        <div class="col-6">
                            <div class="input-material switcher c-default">
                                <label class="auto c-default" for="status">Status</label>
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" [(ngModel)]="model.status" name="status"
                                           class="custom-control-input" id="status">
                                    <label class="custom-control-label" for="status"></label>
                                </div>
                            </div>
                        </div>
                        <!-- CPF -->
                        <div class="col-6">
                            <div class="input-material">
                                <input [(ngModel)]="model.identifier" name="documentAuthor"
                                       id="blocCpf"
                                       [mask]="this.translate.currentLang==='pt'? '000.000.000-00' : ''"
                                       (blur)="onClearAuthor($event, 'document')"
                                       class="form-control" type="text" required/>
                                <label for="blocCpf">
                                    <span>*{{'cpf' | translate}}</span>
                                </label>
                            </div>
                        </div>
                        <!-- USER MICROSOFT -->
                        <div *ngIf="isNullOrUndefined(model.id)" class="col-6">
                            <div class="input-material switcher c-default">
                                <label class="auto c-default" for="status">É usuário Microsoft?</label>
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" [(ngModel)]="userMicrosof" name="status"
                                           class="custom-control-input" id="statusMicrosoft">
                                    <label class="custom-control-label" for="statusMicrosoft"></label>
                                </div>
                            </div>
                        </div>
                        <!-- PASSWORD -->
                        <div *ngIf="isNullOrUndefined(model.id)" class="col-6">
                            <div class="input-material" *ngIf="userMicrosof === false">
                                <input id="userPassword" class="form-control" type="password"
                                       [(ngModel)]="selectPassword" [maxLength]="80"/>
                                <label for="userPassword">
                                    <span>{{'password'|translate}}</span>
                                </label>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <a href="javascript:void('modal')" (click)="actionSave()" class="col-12 btn btn-secondary mt-3">
                        {{'salvar'|translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<app-crop-image [cropId]="'modalCropLogin'"
                [cropAspectRatio]="4/4"
                [cropResizeToWidth]="576"
                [cropResizeToHeight]="576"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>
