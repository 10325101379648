import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    GetOccurrenceActivityGQL,
    GetOccurrenceActivityQuery,
    GetOccurrenceAvoidedRecoveredGQL,
    GetOccurrenceAvoidedRecoveredQuery,
    GetOccurrenceDashboardGQL,
    GetOccurrenceDashboardQuery,
    GetOccurrenceLossDashboardGQL,
    GetOccurrenceLossDashboardQuery,
    OccurrenceActivityDashboard,
    OccurrenceAvoidedRecoveredDashboard,
    OccurrenceLossDashboard,
    OccurrencesDashboard,
    SearchStatisticInput,
    User
} from '../../../generated/graphql';
import {UserService} from '../../service/user.service';
import {Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ExcelService} from '../../service/excel.service';
import {BaseComponent} from '../../base/base.component';
import {QueryRef} from 'apollo-angular';
import {Subscription} from 'rxjs';

declare var $;

// import {EventBusService} from '../../service/event-bus.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    user = new User();

    filter: SearchStatisticInput = new SearchStatisticInput();

    modelActivity: OccurrenceActivityDashboard[] = [];
    modelOccurrenceLoss: OccurrenceLossDashboard[] = [];
    modelOccurrenceAvoided: OccurrenceAvoidedRecoveredDashboard[] = [];
    modelOccurrenceType: OccurrencesDashboard[] = [];

    occurrenceTypeQuery: QueryRef<GetOccurrenceDashboardQuery>;
    activityDashboardQuery: QueryRef<GetOccurrenceActivityQuery>;
    occurrenceLossQuery: QueryRef<GetOccurrenceLossDashboardQuery>;
    occurrenceAvoidedQuery: QueryRef<GetOccurrenceAvoidedRecoveredQuery>;
    labelUnity: boolean;

    // Chart Datas
    myData1 = [['', 0]];

    myData2 = [['', 0, 0, 0]];

    myData3 = [['', 0]];

    myData4: any[] = [['', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];

    counts = [];

    // Chart Options

    options = {
        vAxis: {
            minValue: 0
        },
        isStacked: true,
        colors: ['#fae100']
    };

    options2 = {
        colors: ['#032E58', '#fae100', '#004B93']
    };

    options3 = {
        colors: ['#032E58', '#fae100', '#004B93']
    };

    options4 = {
        colors: ['#53565A', '#032E58', '#fae100', '#004B93']
    };

    // Chart Columns

    informName = [
        'Data',
        'Relatorios'
    ];

    informNameES = [
        'Data',
        'Informes'
    ];

    graph2Names = [
        'Data',
        'Total',
        'Diretas',
        'Indiretas'
    ];

    graph2NamesES = [
        'Data',
        'Total',
        'Directas',
        'Indirectas'
    ];

    graph4Names = [
        'Data',
        'Furto',
        'Roubo',
        'Fraude',
        'Desalojamento',
        'Danos',
        'Vandalismo',
        'Sequestro',
        'Agressão',
        'Tráfico de Drogas',
        'Consumo de Drogas',
        'Desinteligência',
        'Crime Sexual',
        'Crime Ambiental',
        'Arma',
        'Arma Branca',
        'Outros',
    ];

    graph4NamesES = [
        'Data',
        'Hurto',
        'Robo',
        'Fraude',
        'Desalojamiento',
        'Daños',
        'Vandalismo',
        'Secuestro',
        'Agresión',
        'Tráfico de Drogas',
        'Consumo de Drogas',
        'Desinteligencia',
        'Crime Sexual',
        'Crime Ambiental',
        'Arma',
        'Arma Branca',
        'Outros',
    ];

    columnNames = [];

    columnNames2 = [];

    columnNames4 = [];

    filterUnityId: string;

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService,
                public getOccurrenceDashboardGQL: GetOccurrenceDashboardGQL,
                public getOccurrenceActivityGQL: GetOccurrenceActivityGQL,
                public getOccurrenceLossDashboardGQL: GetOccurrenceLossDashboardGQL,
                public getOccurrenceAvoidedRecoveredGQL: GetOccurrenceAvoidedRecoveredGQL,
                public excelService: ExcelService) {
        super(router, translate);
        this.subscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            $(() => {
                this.checkSubtitle();
                this.createArrChartAvoided();
            });
        });
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.actionUserProfileBTN();
        this.initGraphs();
        this.checkSubtitle();
        if (this.actionUserProfile() === false) {
            this.filterUnityId = this.user.unity.id;
        }
        // this.eventbus.on(Events.eventLanguage, (data) => this.initGraphs());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
        }
    }

    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelUnity = true;
                break;
            case 'local-manager':
                this.labelUnity = false;
                break;
            case 'user':
                this.labelUnity = false;
                break;
            case 'vigilant-leader':
                this.labelUnity = false;
                break;
            case 'vigilant-conductor':
                this.labelUnity = false;
                break;
            case 'vigilant':
                this.labelUnity = false;
                break;
            case 'lecturer':
                this.labelUnity = false;
                break;
            case 'corporate-operator':
                this.labelUnity = false;
                break;
            case 'local-operator':
                this.labelUnity = false;
                break;
        }
    }

    initGraphOccurrenceLoss() {
        this.occurrenceLossQuery = this.getOccurrenceLossDashboardGQL.watch({
            data: this.filter = {
                start: '01/02/2022',
                end: '30/10/2022'
            }
        });
        this.occurrenceLossQuery.valueChanges.subscribe({
                next: ({data}) => {
                    this.modelOccurrenceLoss = data.occurrenceLossDashboard as OccurrenceLossDashboard[];
                    this.createArrChartLoss();
                    console.log(this.myData2);
                }
            }
        );
    }

    createArrChartLoss() {
        if (this.modelOccurrenceLoss.length !== 0) {
            this.myData2 = [];
            this.modelOccurrenceLoss.forEach(e => {
                this.myData2.push([e.month, e.total, e.direct, e.indirect]);
            });
        }
    }

    initGraphActivity() {
        this.activityDashboardQuery = this.getOccurrenceActivityGQL.watch({
            data: this.filter = {
                start: '01/02/2022',
                end: '30/10/2022'
            }
        });
        this.activityDashboardQuery.valueChanges.subscribe({
                next: ({data}) => {
                    this.modelActivity = data.occurrenceActivityDashboard as OccurrenceActivityDashboard[];
                    this.createArrChartActivity();
                }
            }
        );
    }

    createArrChartActivity() {
        if (this.modelActivity.length !== 0) {
            this.myData1 = [];
            this.modelActivity.forEach(e => {
                this.myData1.push([e.month, e.time]);
            });
        }
    }

    initGraphOccurrenceAvoided() {
        this.occurrenceAvoidedQuery = this.getOccurrenceAvoidedRecoveredGQL.watch({
            data: this.filter = {
                start: '01/02/2022',
                end: '30/10/2022'
            }
        });
        this.occurrenceAvoidedQuery.valueChanges.subscribe({
                next: ({data}) => {
                    this.modelOccurrenceAvoided = data.occurrenceAvoidedRecoveredDashboard as OccurrenceAvoidedRecoveredDashboard[];
                    this.createArrChartAvoided();
                }
            }
        );
    }

    createArrChartAvoided() {
        if (this.modelOccurrenceAvoided.length !== 0) {
            this.myData3 = [];
            this.modelOccurrenceAvoided.forEach(e => {
                this.myData3.push([this.avoidedType(e.status), e.count]);
            });
        }
    }

    initGraphOccurrenceType() {
        this.occurrenceTypeQuery = this.getOccurrenceDashboardGQL.watch({
            data: this.filter = {
                start: '01/02/2022',
                end: '30/10/2022'
            }
        });
        this.occurrenceTypeQuery.valueChanges.subscribe({
                next: ({data}) => {
                    this.modelOccurrenceType = data.occurrenceDashboard as OccurrencesDashboard[];
                    this.createArrChartType();
                }
            }
        );
    }

    createArrChartType() {
        if (this.modelOccurrenceType.length !== 0) {
            this.myData4 = [];
            this.modelOccurrenceType.forEach(e => {
                this.counts = [];
                this.counts.push(e.date);
                e.detail.forEach(d => {
                    this.counts.push(parseInt(d.count, 0));
                });
                this.myData4.push(this.counts);
            });
            console.log(this.myData4);
        }
    }

    initGraphs() {
        this.initGraphActivity();
        this.initGraphOccurrenceAvoided();
        this.initGraphOccurrenceLoss();
        this.initGraphOccurrenceType();
    }

    checkSubtitle() {
        if (this.translate.currentLang === 'pt') {
            this.columnNames = this.informName;
            this.columnNames2 = this.graph2Names;
            this.columnNames4 = this.graph4Names;
        } else {
            this.columnNames = this.informNameES;
            this.columnNames2 = this.graph2NamesES;
            this.columnNames4 = this.graph4NamesES;
        }
    }

    avoidedType(type) {
        switch (type) {
            case 'avoided':
                return 'Evitadas';
            case 'retrieved':
                return 'Recuperadas';
            case 'lost':
                if (this.translate.currentLang === 'pt') {
                    return 'Perdidas';
                } else {
                    return 'Pérdidas';
                }
        }
    }

}
