<div class="page">
    <!-- HEADER FUNCTIONS -->
    <div class="page-padding">
        <!--HEADER TITLE-->
        <div class="page-header-custom">
            <svg-icon src="assets/svg/settings-blue.svg"></svg-icon>
            <h1 class="page-title">{{"config" | translate}} | <span>{{"settings-general.general" | translate}}</span></h1>
            <!--FUNCTIONS-->
            <!--ADD-->
            <a href="javascript:void('modal')"
               (click)="create()"
               class="btn btn-secondary"
               type="button">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-1">{{'settings-general.new-config'|translate}}</span>
            </a>
        </div>
        <!-- KEYWORD -->
        <div class="page-filter">
            <!-- SEARCH -->
            <div class="input-material">
                <input id="search" maxlength="100" [maxLength]="80" class="form-control"
                       [(ngModel)]="filter.keyword"
                       type="text"
                       required/>
                <label for="search">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    {{'busca'|translate}}
                </label>
            </div>
            <!-- Country -->
            <div class="input-material select">
                <select name="country" id="country" (change)="getUnitiesFilter()"
                        [(ngModel)]="filterCountryId"
                        class="form-control" required>
                    <option [ngValue]="null">Todos</option>
                    <option *ngFor="let c of countries" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="country">{{"settings.country" | translate}}</label>
            </div>
            <!-- Unity -->
            <div class="input-material select">
                <select name="unity" id="unity"
                        [(ngModel)]="filterUnityId"
                        class="form-control" required>
                    <option [ngValue]="null">Todos</option>
                    <option *ngFor="let c of filterUnities" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="unity">{{'unidade'|translate}}</label>
            </div>
            <!--Type-->
            <div class="input-material select">
                <select id="type" class="form-control" name="type" required [(ngModel)]="filterTypeId">
                    <option selected [ngValue]="null">{{this.translate.currentLang==='pt' ? 'Selecione' : 'Seleccione'}}</option>
                    <option value="facilitadoresocorrencia">{{'facilitadoresocorrencia' | translate}}</option>
                    <option value="localdeentrega">{{'localdeentrega' | translate}}</option>
<!--                    <option value="vitimas">{{'vitimas' | translate}}</option>-->
<!--                    <option value="tipoocorrencia">{{'tipoocorrencia' | translate}}</option>-->
<!--                    <option value="author">{{'author' | translate}}</option>-->
                </select>
                <label for="type" class="mb-0 mr-3">{{'tipo'|translate}}</label>
            </div>
            <!-- Culture -->
            <div class="input-material select">
                <select id="idioma" class="form-control" name="idioma" required [(ngModel)]="filterCultureId">
                    <option selected [ngValue]="null">{{this.translate.currentLang==='pt' ? 'Selecione' : 'Seleccione'}}</option>
                    <option value="pt-BR">PT-BR</option>
                    <option value="es-ES">ES-ES</option>
                </select>
                <label for="idioma" class="mb-0 mr-3">{{'idioma'|translate}}</label>
            </div>
            <!-- FILTER -->
            <a href="javascript:void(0)" (click)="onFilter()"
               class="btn btn-primary btn-lg text-white flex-grow-1"
               type="button">
                <span>{{'buscar'|translate}}</span>
            </a>
        </div>
        <!--GRID-->
        <div class="table-responsive">
            <table class="table table_regular" style="width: 99%">
                <!--TABLE HEAD-->
                <thead>
                <tr>
                    <th scope="col">{{"settings.country" | translate}}</th>
                    <th scope="col">{{"settings.unity" | translate}}</th>
                    <th scope="col">{{"tipo" | translate}}</th>
                    <th scope="col">{{"nome" | translate}}</th>
                    <th scope="col">{{"idioma" | translate}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(result.results)">
                    <td colspan="5" class="text-center">
                        {{"settings.no-result" | translate}}
                    </td>
                </tr>
                <tr *ngFor="let r of result.results |
                paginate: {itemsPerPage: result.PageSize,
                currentPage: result.currentPage,
                totalItems: result.totalCount }">
                    <td>
                        <img *ngIf="!isNullOrUndefined(r.unity?.country)"
                             [src]="'../../assets/images/country/' + r.unity?.country?.flag + '.svg'"
                             alt="img"
                             width="30px"/>
                    </td>
                    <td>{{r.unity?.name ? r.unity?.name : "-"}}</td>
                    <td>{{r.type | translate}}</td>
                    <td>{{r.value}}</td>
                    <td>{{r.culture.toUpperCase()}}</td>
                    <td>
                        <div class="table_action">
                            <button (click)="actionEdit(r)">
                                <svg-icon src="assets/svg/edit.svg"></svg-icon>
                            </button>
                            <button (click)="actionDelete(r.id)">
                                <svg-icon src="assets/svg/close-red.svg"></svg-icon>
                            </button>
                        </div>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

<div class="modal fade" id="configModal" tabindex="-1" role="dialog" aria-labelledby="configModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary" *ngIf="this.translate.currentLang==='pt'">
                    {{isNullOrUndefined(this.modelCreate.id) ? 'Nova Configuração' : 'Editar Configuração'}}
                </h5>
                <h5 class="modal-title text-primary" *ngIf="this.translate.currentLang==='es'">
                    {{isNullOrUndefined(this.modelCreate.id) ? 'Nueva configuración' : 'Editar configuración'}}
                </h5>
                <button type="button" class="close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <!--TYPE DROPDOWN-->
                    <div class="col-12">
                        <div class="input-material select">
                            <select id="typeConfig" class="form-control" [(ngModel)]="modelCreate.type">
<!--                                <option [value]="undefined">{{'minhalista.input3.subtitle' | translate}} </option>-->
                                <option value="facilitadoresocorrencia">{{'facilitadoresocorrencia' | translate}}</option>
                                <option value="localdeentrega">{{'localdeentrega' | translate}}</option>
<!--                                <option value="vitimas">{{'vitimas' | translate}}</option>-->
<!--                                <option value="tipoocorrencia">{{'tipoocorrencia' | translate}}</option>-->
<!--                                <option value="author">{{'author' | translate}}</option>-->
                            </select>
                            <label for="typeConfig" class="mb-0 mr-3">{{'tipo'|translate}}*</label>
                        </div>
                    </div>
                    <!--VALUE-->
                    <div class="col-12">
                        <div class="input-material">
                            <input id="valueConfig" class="form-control" type="text"
                                   required [maxLength]="80" [(ngModel)]="modelCreate.value"/>
                            <label for="valueConfig">{{'nome'|translate}}*</label>
                        </div>
                    </div>
                        <!--CULTURE DROPDOWN-->
                    <div class="col-12">
                        <div class="input-material select">
                            <select id="cultureConfig" class="form-control" [(ngModel)]="modelCreate.culture">
<!--                                <option [value]="undefined">{{'minhalista.input3.subtitle' | translate}} </option>-->
                                <option value="pt-BR">PT-BR</option>
                                <option value="es-ES">ES-ES</option>
                            </select>
                            <label for="cultureConfig" class="mb-0 mr-3">{{'idioma'|translate}}*</label>
                        </div>
                    </div>
                    <!-- COUNTRY DROPDOWN -->
                    <div class="col-12" *ngIf="modelCreate.type == 'localdeentrega'">
                        <div class="input-material select">
                            <select name="countryConfig" id="countryConfig" (change)="getUnitiesForCreate()"
                                    [(ngModel)]="selectedCreateCountryId"
                                    class="form-control" required>
<!--                                <option [value]="undefined">Selecione um País</option>-->
                                <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name}}</option>
                            </select>
                            <label for="countryConfig">País*</label>
                        </div>
                    </div>
                    <!-- UNITY DROPDOWN -->
                    <div class="col-12" *ngIf="modelCreate.type == 'localdeentrega'">
                        <div class="input-material select">
                            <select name="unityConfig" id="unityConfig"
                                    [(ngModel)]="selectedCreateUnityId"
                                    class="form-control" required>
<!--                                <option [value]="undefined">Selecione uma Unidade</option>-->
                                <option *ngFor="let c of createUnities" [ngValue]="c.id">{{c.name}}</option>
                            </select>
                            <label for="unityConfig">Unidade*</label>
                        </div>
                    </div>
                    <button class="btn btn-block btn-warning mt-3"
                            (click)="actionSave()">{{'salvar'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
