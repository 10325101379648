import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    CreateTruckCheckListObject,
    PhysicalSecurity,
    PhysicalSecurityGQL,
    PhysicalSecurityQuery, ServiceRequest, Truck, TruckChecklist,
    TruckGQL,
    TruckQuery, User
} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {environment} from "../../../../environments/environment";
import {UserService} from "../../../service/user.service";

declare var $;
declare var window;
declare var requestAnimFrame;

@Component({
    selector: 'app-trucks-detail',
    templateUrl: './trucks-detail.component.html',
    styleUrls: ['./trucks-detail.component.scss']
})
export class TrucksDetailComponent extends BaseComponent implements OnInit, AfterViewInit {
    user = new User();
    parmId: string;
    truckQuery: QueryRef<TruckQuery>;

    entranceMotive = 'gathering';
    checklistType = 'horse';
    tabType = 'entry';
    checkOk = 'ok';
    flagSignature = true;
    imageSignature = undefined;
    imageSignatureFile: File;

    truck: Truck = new Truck();
    checklist: TruckChecklist[] = [];
    checklistsVehicle: TruckChecklist[] = [];
    checklistsTrailer: TruckChecklist[] = [];

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute,
                private truckGQL: TruckGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.activatedRoute.params.subscribe(data => {
            if (!this.isNullOrUndefined(data.id)) {
                this.parmId = data.id;
                this.truckQuery = this.truckGQL.watch({id: data.id});
                this.truckQuery.valueChanges.subscribe(({data}) => {
                    this.truck = data.truck as Truck;
                    this.truck.checklist.forEach((e) => {
                        if (e.name == 'vehicle') {
                            this.checklistsVehicle.push(e);
                        } else {
                            this.checklistsTrailer.push(e);
                        }
                    });
                }, error => super.onError(error));
            }
        });
    }

    actionPdf() {
        const token = localStorage.getItem('token') as string;
        const id = this.parmId as string;
        const url = `${environment.base_url}/trucks/pdf?id=${id}&token=${token}`;
        window.open(encodeURI(url));
    }
    actionUserProfile() {
        switch (this.user.profile) {
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'local-manager':
                return true;
            case 'corporate-operator':
                return true;
            case 'user':
                return true;
            case 'admin':
                return true;
        }
    }
}
