<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon src="assets/svg/settings-blue.svg"></svg-icon>
            <h1 class="page-title">{{"config" | translate}} | <span>{{"settings-access.access" | translate}}</span></h1>
            <a (click)="create()" class="btn btn-secondary">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                {{"settings-access.new-access" | translate}}
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" required [(ngModel)]="filter.keyword"
                       [maxLength]="80"/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>{{"settings.search" | translate}}</span>
                </label>
            </div>
            <div class="input-material select">
                <select name="country" id="country" (change)="getUnitiesFilter()"
                        [(ngModel)]="filterCountryId"
                        class="form-control" required>
                    <option [ngValue]="null">Todos</option>
                    <option *ngFor="let c of countries" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="country">{{"settings.country" | translate}}</label>
            </div>
            <div class="input-material select">
                <select name="unity" id="unity" (change)="getBlocsFilter()"
                        [(ngModel)]="filterUnityId"
                        class="form-control" required>
                    <option [ngValue]="null">Todos</option>
                    <option *ngFor="let c of filterUnities" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="unity">{{"settings.unity" | translate}}</label>
            </div>
            <div class="input-material select">
                <select name="bloc" id="bloc"
                        [(ngModel)]="filterBlocId"
                        class="form-control" required>
                    <option [ngValue]="null">Todos</option>
                    <option *ngFor="let b of filterBlocs" value="{{b.id}}">{{b.name}}</option>
                </select>
                <label for="bloc">{{"settings.bloc" | translate}}</label>
            </div>
            <!-- FILTER -->
            <a href="javascript:void(0)" (click)="onFilter()"
               class="btn btn-primary btn-lg text-white flex-grow-1"
               type="button">
                <span>{{'buscar'|translate}}</span>
            </a>
        </div>

        <div class="table-responsive">
            <table class="table table_regular custom-table-width">
                <thead>
                <tr>
                    <th scope="col">{{"settings.code" | translate}}</th>
                    <th scope="col">{{"settings.country" | translate}}</th>
                    <th scope="col">{{"settings.local" | translate}}</th>
                    <th scope="col">{{"settings.bloc" | translate}}</th>
                    <th scope="col">{{"settings.type" | translate}}</th>
                    <th scope="col">{{"settings.unity" | translate}}</th>
                    <th scope="col">{{"settings.status" | translate}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(accesses.results)">
                    <td colspan="8" class="text-center">
                        {{"settings.no-result" | translate}}
                    </td>
                </tr>
                <tr *ngFor="let a of accesses.results|
                paginate: {itemsPerPage: accesses.PageSize,
                currentPage: accesses.currentPage,
                totalItems: accesses.totalCount }">
                    <td>{{a.code}}</td>
                    <td>
                        <img *ngIf="!isNullOrUndefined(a.bloc?.unity.country)"
                             [src]="'assets/images/country/' + a.bloc?.unity.country?.flag + '.svg'"
                             alt="img"
                             width="30px"/>
                    </td>
                    <td>{{a.place}}</td>
                    <td>{{a.bloc.name}}</td>
                    <td>{{a.type}}</td>
                    <td>{{a.bloc.unity.name}}</td>
                    <td>{{a.status ? 'Ativo' : 'Inativo' }}</td>
                    <td class="text-center">
                        <div class="table_action">
                            <a (click)="actionEdit(a)">
                                <svg-icon src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <a (click)="actionDelete(a.id)">
                                <svg-icon src="assets/svg/close-red.svg"></svg-icon>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
<div class="modal fade" id="accessModal" tabindex="-1" role="dialog" aria-labelledby="accessModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{isNullOrUndefined(this.modelCreate.id) ? 'Novo Acesso' : 'Editar Acesso'}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body mh-0 mb-0">
                <div class="col-12  mb-0">
                    <div class="row col-12 mb-0">
                        <!--LOCAL-->
                        <div class="col-6">
                            <div class="input-material">
                                <input id="blocplace" class="form-control" type="text" required
                                       [(ngModel)]="modelCreate.place" [maxLength]="80"/>
                                <label for="blocplace">
                                    <span>*{{'local'|translate}}</span>
                                </label>
                            </div>
                        </div>
                        <!-- COUNTRY DROPDOWN -->
                        <div class="col-6">
                            <div class="input-material select">
                                <select name="blocUnity" id="blocCountry" (change)="getUnitiesForCreate()"
                                        [(ngModel)]="selectedCreateCountryId"
                                        class="form-control" required>
                                    <option [value]="undefined">Selecione um País</option>
                                    <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name}}</option>
                                </select>
                                <label for="blocCountry">*País</label>
                            </div>
                        </div>
                        <!--UNITY DROPDOWN-->
                        <div class="col-6">
                            <div class="input-material select">
                                <select name="blocUnity" id="blocUnity" (change)="getBlocsForCreate()"
                                        [(ngModel)]="selectedCreateUnityId"
                                        class="form-control" required>
                                    <option [value]="undefined">Selecione uma unidade</option>
                                    <option *ngFor="let u of createUnities" [ngValue]="u.id">{{u.name}}</option>
                                </select>
                                <label for="blocUnity">*Unidade</label>
                            </div>
                        </div>
                        <!-- BLOC -->
                        <div class="col-6">
                            <div class="input-material select">
                                <select name="blocUnity" id="blocBloc"
                                        [(ngModel)]="selectedCreateBlocId"
                                        class="form-control" required>
                                    <option [value]="undefined">Selecione um bloco</option>
                                    <option *ngFor="let b of createBlocs" [ngValue]="b.id">{{b.name}}</option>
                                </select>
                                <label for="blocBloc">*Bloco</label>
                            </div>
                        </div>
                        <!-- TYPE -->
                        <div class="col-6">
                            <div class="input-material select">
                                <select name="blocUnity" id="bloctype"
                                        [(ngModel)]="modelCreate.type"
                                        class="form-control" required>
                                    <option [ngValue]="undefined">Selecione um tipo</option>
                                    <option value="biometry">Biometria</option>
                                    <option value="facialBiometrics">Reconhecimento Facial</option>
                                    <option value="badge">Distintivo</option>
                                </select>
                                <label for="bloctype">*Tipo</label>
                            </div>
                        </div>
                        <!-- STATUS -->
                        <div class="col-6">
                            <div class="input-material switcher c-default">
                                <label class="auto c-default" for="status">Status</label>
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" [(ngModel)]="modelCreate.status" name="status"
                                           class="custom-control-input" id="status">
                                    <label class="custom-control-label" for="status"></label>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>


                    <a href="javascript:void('modal')" (click)="actionSave()" class="col-12 btn btn-secondary mt-3">
                        {{'salvar'|translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
