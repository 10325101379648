<div class="page-header full">
    <div class="container no-wrap justify-content-start pt-3 pb-4 pt-lg-4 pl-0">
        <svg-icon style="fill: #0d2e5d !important;" src="assets/svg/dashboard.svg"></svg-icon>
        <h2 class="page-header__title large">
            <strong>Dashboard</strong>
        </h2>
    </div>
</div>

<div class="dashboard">
    <div class="container">
        <!--<section class="dashboard_top row">
            <div class="col-sm-6 col-lg-3" *ngIf="labelUnity">
                <div class="input-material select mb-lg-0">
                    <select class="form-control" required>
                        <option disabled value hidden selected></option>
                        <option>1</option>
                        <option>2</option>
                    </select>
                    <label>Todas as Unidades</label>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="input-material mb-0">
                    <input class="form-control" type="text" required />
                    <label>
                        <span>Data da Ocorrência</span>
                        <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                    </label>
                </div>
            </div>
        </section>-->

        <section class="row">
            <article class="col-12 col-lg-6">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <p class="card-primary-header-title">{{"dash.impact" | translate}}
                                <strong>{{"dash.activity" | translate}}</strong></p>
                            <hr>
                        </div>
                        <div class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <google-chart
                                        [type]="'ColumnChart'"
                                        [data]="myData1"
                                        [columns]="columnNames"
                                        [options]="options"
                                        style="width: 100%; height: 300px;">
                                </google-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article class="col-12 col-lg-6">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <p class="card-primary-header-title">{{"dash.loss" | translate}}<strong>{{"dash.occurrence-function" | translate}}</strong></p>
                            <hr>
                        </div>
                        <div class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <google-chart
                                        [type]="'ColumnChart'"
                                        [data]="myData2"
                                        [columns]="columnNames2"
                                        [options]="options2"
                                        style="width: 100%; height: 300px">
                                </google-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article class="col-12 col-lg-6">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <p class="card-primary-header-title">{{"dash.losses" | translate}} <strong>{{"dash.avoided" | translate}}</strong></p>
                            <hr>
                        </div>
                        <div class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <google-chart
                                        [type]="'PieChart'"
                                        [data]="myData3"
                                        [columns]="columnNames"
                                        [options]="options3"
                                        style="width: 100%; height: 300px">
                                </google-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article class="col-12 col-lg-6">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <p class="card-primary-header-title">{{"dash.types" | translate}}<strong>{{"dash.occurrences" | translate}}</strong>
                            </p>
                            <hr>
                        </div>
                        <div class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <google-chart
                                        [type]="'LineChart'"
                                        [data]="myData4"
                                        [columns]="columnNames4"
                                        [options]="options4"
                                        style="width: 100%; height: 300px">
                                </google-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    </div>
</div>




