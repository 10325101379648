<div class="page">
    <!-- HEADER FUNCTIONS -->
    <div class="page-padding">
        <!--HEADER TITLE-->
        <div class="page-header-custom">
            <svg-icon src="assets/svg/settings-blue.svg"></svg-icon>
            <h1 class="page-title">{{"config" | translate}} | <span>{{"settings-unity.unity" | translate}}</span></h1>
            <!--FUNCTIONS-->
            <!--ADD-->
            <a href="javascript:void('modal')"
               (click)="create()"
               class="btn btn-secondary"
               type="button">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                <span class="ml-1">{{'settings-unity.new-unity'|translate}}</span>
            </a>
        </div>
        <!-- KEYWORD -->
        <div class="page-filter">
            <!-- SEARCH -->
            <div class="input-material">
                <input id="search" maxlength="100" [(ngModel)]="filter.keyword" [maxLength]="80" class="form-control"
                       type="text"
                       required/>
                <label for="search">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    {{'busca'|translate}}
                </label>
            </div>
            <!-- COUNTRY -->
            <div class="input-material select">
                <select id="country" [(ngModel)]="filter.country" class="form-control" name="type" required>
                    <option selected
                            [ngValue]="null">{{this.translate.currentLang==='pt' ? 'Selecione' : 'Seleccione'}}</option>
                    <option selected *ngFor="let i of countries" [value]="i.id">{{i.name}}</option>
                </select>
                <label for="country" class="mb-0 mr-3">{{'settings.country'|translate}}</label>
            </div>
            <!-- FILTER -->
            <a href="javascript:void(0)" (click)="onFilter()"
               class="btn btn-primary btn-lg text-white flex-grow-1"
               type="button">
                <span>{{'buscar'|translate}}</span>
            </a>
        </div>
        <!--GRID-->
        <div class="table-responsive">
            <table class="table table_regular" style="width: 99%">
                <!--TABLE HEAD-->
                <thead>
                <tr>
                    <th scope="col">{{"settings.code" | translate}}</th>
                    <th class="text-center" scope="col">{{"settings.country" | translate}}</th>
                    <th scope="col">{{"settings.unity" | translate}}</th>
                    <th scope="col">{{"settings-unity.city" | translate}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(unities)">
                    <td colspan="5" class="text-center">
                        {{"settings.no-result" | translate}}
                    </td>
                </tr>
                <tr *ngFor="let u of unities.results |
                paginate: {itemsPerPage: unities.PageSize,
                currentPage: unities.currentPage,
                totalItems: unities.totalCount }">
                    <td>{{u.code}}</td>
                    <td class="d-flex flex-column align-items-center">
                        <img *ngIf="!isNullOrUndefined(u.country)"
                             [src]="'assets/images/country/' + u.country?.flag + '.svg'"
                             alt="img"
                             width="30px"/>
                        {{u.country.name}}
                    </td>
                    <td>{{u.name}}</td>
                    <td>{{u.city}}</td>
                    <td>
                        <div class="table_action">
                            <button (click)="actionEdit(u)">
                                <svg-icon src="assets/svg/edit.svg"></svg-icon>
                            </button>
                            <button (click)="actionDelete(u.id)">
                                <svg-icon src="assets/svg/close-red.svg"></svg-icon>
                            </button>
                        </div>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

<div class="modal fade" id="unityModal" tabindex="-1" role="dialog" aria-labelledby="unityModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{isNullOrUndefined(this.model.id) ? this.translate.instant('novaunidade') : this.translate.instant('editarunidade')}}
                </h5>
                <button type="button" class="close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <!--NAME-->
                    <div class="col-12">

                        <div class="input-material">
                            <input id="nameUnity" class="form-control" type="text"
                                   required [(ngModel)]="model.name" [maxLength]="80"/>
                            <label for="nameUnity"> * {{'nome'|translate}}</label>
                        </div>
                    </div>
                    <!-- COUNTRY DROPDOWN -->
                    <div class="col-12">
                        <div class="input-material select">
                            <select id="countryUnity" class="form-control" name="type" [(ngModel)]="selectedCountryID">
                                <option selected
                                        [ngValue]="null">{{'minhalista.input3.subtitle' | translate}} </option>

                                <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
                            </select>
                            <label for="countryUnity" class="mb-0 mr-3"> *{{'pais'|translate}}</label>
                        </div>
                    </div>
                    <!-- CITY -->
                    <div class="col-12">
                        <div class="input-material">
                            <input id="cityUnity" class="form-control" type="text"
                                   required [(ngModel)]="model.city" [maxLength]="80"/>
                            <label for="cityUnity"> *{{'cidade'|translate}}</label>
                        </div>
                    </div>
                    <button class="btn btn-block btn-warning mt-3"
                            (click)="actionSave()">{{'salvar'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
