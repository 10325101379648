import {Component, OnInit} from '@angular/core';
import {
    Country,
    GetCountriesGQL,
    GetCountriesQuery,
    GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery,
    OccurrencePage, OccurrenceUser, RemoveOccurrenceGQL,
    SearchOccurrenceInput,
    SearchOccurrencesGQL,
    SearchOccurrencesQuery,
    Unity, User
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ExcelService} from '../../../service/excel.service';
import {UserService} from '../../../service/user.service';

declare var moment;
declare var $;

@Component({
    selector: 'app-home',
    templateUrl: './occurrence-list.component.html',
    styleUrls: ['./occurrence-list.component.scss']
})
export class OccurrenceListComponent extends BaseComponent implements OnInit {

    user: User = new User();
    page = 1;
    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesByCountryQuery>;

    filter: SearchOccurrenceInput = new SearchOccurrenceInput();
    searchOccurrenceQuery: QueryRef<SearchOccurrencesQuery>;
    result: OccurrencePage;
    selectedCountryId: string;

    labelCountry: boolean;
    labelUnity: boolean;
    exportExcel: boolean;
    actionNew: boolean;
    actionUpdate: boolean;

    constructor(private getCountriesGQL: GetCountriesGQL,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                private searchOccurrenceGQL: SearchOccurrencesGQL,
                private removeOccurrence: RemoveOccurrenceGQL,
                public router: Router,
                public excelService: ExcelService,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        if (this.actionUserProfile() === false) {
            this.filter.unityId = this.user.unity.id;
        }
        this.actionUserProfileBTN();
        this.getCountries();
        // this.onClear(false);
        this.searchOccurrenceQuery = this.searchOccurrenceGQL.watch({page: this.page, input: this.filter});
        this.searchOccurrenceQuery.valueChanges.subscribe(({data}) => {
            this.result = data.searchOccurrence as OccurrencePage;
        });

        this.unityQuery = this.getUnitiesByCountryGQL.watch({countryId: this.isNullOrUndefined(this.selectedCountryId) ? '' : this.selectedCountryId});
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.filter.unityId = null;
            this.unities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    getUnities() {
        this.unityQuery.refetch({countryId: this.isNullOrUndefined(this.selectedCountryId) ? '' : this.selectedCountryId});
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    onFilter() {
        if (!this.isNullOrUndefined(this.selectedCountryId)) {
            this.filter.countryId = this.selectedCountryId;
        }
        if (this.isNullOrUndefined(this.selectedCountryId)) {
            this.filter.countryId = undefined;
        }
        this.searchOccurrenceQuery.refetch({page: this.filter.page, input: this.filter});
    }

    onClear(canGetList: boolean) {
        this.filter = {
            start: moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format(),
            end: moment().endOf('day').utcOffset(0, true).format()
        };
        this.selectedCountryId = undefined;
        this.setDatePicker('js-daterangepicker', (start, end, label) => {
            this.filter.start = start.utcOffset(0, true).format();
            this.filter.end = end.utcOffset(0, true).format();
        });
        this.filter = new SearchOccurrenceInput();
        if (canGetList) {
            this.onFilter();
        }
    }

    actionRemove(idInput: string) {
        super.confirmMessage((this.translate.instant('atencao')), (this.translate.instant('removerocorrencia')), () => {
            this.removeOccurrence.mutate({id: idInput}).subscribe(() => {
                this.searchOccurrenceQuery.refetch({page: this.page, input: this.filter});
            });
        });
    }

    getVictim(users: Array<OccurrenceUser>): Array<OccurrenceUser> {
        return users.filter(a => a.type === 'pessoal' || a.type === 'legal');
    }

    actionExcel() {
        this.excelService.getExcelOccurrences(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
        }
    }

    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelCountry = true;
                this.labelUnity = true;
                this.exportExcel = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'local-manager':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'user':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant-leader':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'vigilant-conductor':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'vigilant':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'lecturer':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'corporate-operator':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'local-operator':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
        }
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.onFilter();
    }
}
