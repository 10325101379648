import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unity-detail',
  templateUrl: './unity-detail.component.html',
  styleUrls: ['./unity-detail.component.scss']
})
export class UnityDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
