<div class="truck page">
    <div class="page-padding">
        <div class="page-header-custom-secondary is-cursor">
            <svg-icon routerLink="/truck" src="assets/svg/back.svg"></svg-icon>
            <h2 class="page-title"><strong>{{'caminhoes'|translate}}</strong></h2>
            <button class="btn btn-outline-primary order-3 ml-auto" (click)="actionPdf()" *ngIf="actionUserProfile()">
                <svg-icon class="mr-3 pdf_svg svg-white" src="assets/svg/export.svg"></svg-icon>
                <span>Exportar</span>
            </button>
        </div>
        <div class="tab_selector" *ngIf="!isNullOrUndefined(truck.inspection)||!isNullOrUndefined(truck.release)">
            <div class="tab_selector_content">
                <span class="tab_selector_content_title" (click)="tabType ='entry'"
                      [ngClass]="{'active':tabType=='entry'}">{{'entry'|translate}}
                </span>
                <span class="tab_selector_content_title" (click)="tabType ='loading'"
                      *ngIf="!isNullOrUndefined(truck.inspection)"
                      [ngClass]="{'active':tabType=='loading'}">{{'carregamento'|translate}}</span>
                <span class="tab_selector_content_title" (click)="tabType ='exit'"
                      *ngIf="!isNullOrUndefined(truck.release)"
                      [ngClass]="{'active':tabType=='exit'}">{{'saida'|translate}}</span>
            </div>
        </div>


        <div class="div" *ngIf="tabType=='entry'">
            <div class="container">

                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="card_ret_small col-12  flex-column">
                            <span>
                                <strong>
                                    {{'dataehorario'|translate}}
                                </strong>
                                {{'doprocesso'|translate}}
                            </span>
                            <p class="text-light">
                                {{'date'|translate}}
                            </p>
                            <p class="ml-2 ">
                                {{truck.date|date:'dd/MM/yyyy'}}
                            </p>
                            <p class="text-light mt-3">
                                {{'horadechegada'|translate}}
                            </p>
                            <p class="ml-2">
                                {{truck.date|date:'HH:mm':'UTC+0'}}
                            </p>
                        </div>
                    </div>

                    <div class="col-12 col-md-9">
                        <div class="card_ret_small col-12">
                            <div class="col-12 col-md-4">
                                 <span>
                                    <strong>
                                      {{'informacoes'|translate}}
                                     </strong>
                                     {{'domotorista'|translate}}
                                 </span>
                                <p class="text-light">
                                    {{'empresa'|translate}}
                                </p>
                                <p class="ml-2">
                                    {{truck.company}}
                                </p>
                                <p class="text-light mt-3">
                                    {{'ndolacre'|translate}}
                                </p>
                                <p class="ml-2">
                                    {{truck.sealNumber}}
                                </p>
                            </div>
                            <div class="col-12 col-md-4">
                                <p class="text-light">
                                    {{'nomemotorista'|translate}}
                                </p>
                                <p class="ml-2 ">
                                    {{truck.driverName}}
                                </p>
                                <p class="text-light mt-3">
                                    {{'placacavalo'|translate}}
                                </p>
                                <p class="ml-2">
                                    {{truck.licensePlate}}
                                </p>
                                <p *ngIf="truck.seal" class="mt-2">
                                    {{'seloseguranca'|translate}}
                                    <svg-icon src="assets/svg/accept.svg"></svg-icon>
                                </p>

                            </div>
                            <div class="col-12 col-md-4">
                                <p class="text-light">
                                    {{'cpf' | translate}}
                                </p>
                                <p class="ml-2 ">
                                    {{this.translate.currentLang === 'pt' ? (truck.driverCpf| mask:'000.000.000-00') : (truck.driverCpf)}}
                                </p>
                                <p class="text-light mt-3">
                                    {{'placareboque'|translate}}
                                </p>
                                <p class="ml-2">
                                    {{truck.trailerLicensePlate}}
                                </p>
                                <p *ngIf="truck.sealCorrect" class="mt-2">
                                    {{'seloafixado'|translate}}
                                    <svg-icon src="assets/svg/accept.svg"></svg-icon>
                                </p>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="card_ret_small">
                    <div class="row mt-3 w-100">
                        <div class="col-md-4">
                            <div class="uploaded mb-0 h-100">
                                <h5>{{"truck.fotolacre" | translate}}</h5>
                                <ul>
                                    <li *ngIf="isNullOrUndefined(truck.sealPhoto)">
                                        {{"truck.nophoto" | translate}}
                                    </li>
                                    <li *ngIf="!isNullOrUndefined(truck.sealPhoto)">
                                        <img src="{{truck.sealPhoto}}" alt="seal">
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4" *ngIf="truck.isAuto">
                            <div class="uploaded mb-0 h-100">
                                <h5>{{"truck.fotocavalo" | translate}}</h5>
                                <ul>
                                    <li *ngIf="isNullOrUndefined(truck.licensePlatePhoto)">
                                        {{"truck.nophoto" | translate}}
                                    </li>
                                    <li *ngIf="!isNullOrUndefined(truck.licensePlatePhoto)">
                                        <img src="{{truck.licensePlatePhoto}}" alt="licensePlate">
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4" *ngIf="truck.isAuto">
                            <div class="uploaded mb-0 h-100">
                                <h5>{{"truck.fotoreboque" | translate}}</h5>
                                <ul>
                                    <li *ngIf="isNullOrUndefined(truck.trailerLicensePlatePhoto)">
                                        {{"truck.nophoto" | translate}}
                                    </li>
                                    <li class="ng-star-inserted"
                                        *ngIf="!isNullOrUndefined(truck.trailerLicensePlatePhoto)">
                                        <img src="{{truck.trailerLicensePlatePhoto}}" alt="seal">
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4" *ngIf="!truck.isAuto">
                            <div class="uploaded mb-0 h-100">
                                <h5>{{"truck.fotoauto" | translate}}</h5>
                                <ul>
                                    <li *ngIf="isNullOrUndefined(truck.autoPhoto)">
                                        {{"truck.fotoauto" | translate}}
                                    </li>
                                    <li class="ng-star-inserted"
                                        *ngIf="!isNullOrUndefined(truck.autoPhoto)">
                                        <img src="{{truck.autoPhoto}}" alt="seal">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="!isNullOrUndefined(truck.helpers)">
                    <div class="col-12">
                        <div class="card_ret_small col-12">
                            <div class="col-12 col-md-4">
                                 <span>
                                    <strong>
                                      {{'informacoes'|translate}}
                                     </strong>
                                     {{'ajudante'|translate}}
                                 </span>

                            </div>
                            <div class="col-12 col-md">
                                <div class="table-responsive">
                                    <table class="table table_regular">
                                        <thead>
                                        <tr>
                                            <th scope="col">{{'nomeajudante'|translate}}</th>
                                            <th scope="col">Nº {{'rg'|translate}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let h of truck.helpers;let i = index">
                                            <td>{{h.name}}</td>
                                            <td>{{h.rg}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="card_ret_small col-12  flex-column">
                            <span>
                                <strong>
                                    {{'motivo'|translate}}
                                </strong>
                                {{'daentrada'|translate}}
                            </span>
                            <p class="text-light">
                                Tipo
                            </p>
                            <p class="ml-2 ">
                                {{truck.reason == 'delivery' ? this.translate.instant('entrega') : this.translate.instant('coleta')}}
                            </p>
                            <p class="text-light mt-3">
                                {{'localde'|translate}} {{truck.reason == 'delivery' ? this.translate.instant('entrega') : this.translate.instant('coleta')}}
                            </p>
                            <p class="ml-2">
                                {{truck.place}}
                            </p>
                        </div>
                    </div>

                    <div class="col-12 col-md-9">
                        <div class="card_ret_small flex-column col-12 row">
                                <span>
                                    {{'responsavel'|translate}}
                                </span>
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <p class="text-light">
                                        {{'nome'|translate}}
                                    </p>
                                    <p class="ml-2 ">
                                        {{truck.createdBy?.name}}
                                    </p>
                                </div>
                                <div class="col-12 col-md-4">
                                    <p class="text-light">
                                        {{'cpf'|translate}}
                                    </p>
                                    <p class="ml-2 ">
                                        {{this.translate.currentLang === 'pt' ? (truck.createdBy?.identifier| mask:'000.000.000-00') : (truck.createdBy?.identifier)}}
                                    </p>
                                </div>
                                <div class="col-12 col-md-4">
                                    <p class="text-light">
                                        Área
                                    </p>
                                    <p class="ml-2">
                                        {{truck.createdBy?.office}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="truck.isAuto">
                    <div class="col-12">
                        <div class="card_ret col-12 row">
                            <div class="col-12 col-md-3">
                                <span>
                                    <strong>
                                        Checklist
                                    </strong>
                                    {{'inspecao'|translate}}
                                </span>
                            </div>
                            <div class="row col-12 col-md-9 mb-2 justify-content-end">
                                <div class="col-12 col-md-4 mb-2">
                                    <button class="btn btn-select " (click)="checklistType ='horse'"
                                            [ngClass]="{'active':checklistType=='horse'}">
                                        <span class="pl-2">{{'cavalo'|translate}}</span>
                                    </button>
                                </div>
                                <div class="col-12 col-md-4 mb-2">
                                    <button class="btn btn-select " (click)="checklistType ='trailer'"
                                            [ngClass]="{'active':checklistType=='trailer'}">
                                        <span class="pl-2">{{'reboque'|translate}}</span>
                                    </button>
                                </div>
                            </div>

                            <div class="w-100 d-flex flex-wrap align-items-center justify-content-between"
                                 *ngIf="checklistType=='horse'">
                                <div class="col-12 col-md-6 mb-2">
                                    <img class="truck_image" src="../../../../assets/images/truck.png" alt="">
                                </div>
                                <div class="col-12 col-md-6 mb-2">
                                    <div class="truck_scroll">
                                        <div class="truck_scroll_card_comment"
                                             *ngFor="let c of checklistsVehicle,let i = index">
                                            <div class="top">
                                                <div class="d-flex">
                                                    <b>{{i + 1}}</b>
                                                    <p>{{(c.type).toLowerCase().replace(" ", "")  | translate}}</p>
                                                </div>
                                                <div class="d-flex flex-wrap justify-content-center" style="gap: 10px">
                                                    <div *ngIf="c.statusTruck  === 'accept'" class="check-btn"
                                                         [ngClass]="{'btn-success': c.statusTruck === 'accept'}">
                                                        <svg-icon src="assets/svg/check.svg"></svg-icon>
                                                    </div>
                                                    <div *ngIf="c.statusTruck  === 'error'" class="check-btn"
                                                         [ngClass]="{'btn-danger': c.statusTruck ===  'error'}">
                                                        <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                                                    </div>
                                                    <div *ngIf="c.statusTruck === 'notApply'" class="check-btn"
                                                         [ngClass]="{'btn-warning': c.statusTruck ===  'notApply'}">
                                                        <svg-icon src="assets/svg/icon_N_A.svg"></svg-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!isNullOrUndefined(c.photo === false)&&c.statusTruck ===  'error'||!isNullOrUndefined(c.comment)"
                                                 class="row_comment d-flex" style="gap: 15px">
                                                <img style="height: 78px;margin-top: 8px;"
                                                     *ngIf="c.statusTruck === false&&!isNullOrUndefined(c.photo)"
                                                     src="{{c.photo}}" alt="img">
                                                <p *ngIf="!isNullOrUndefined(c.comment)">{{c.comment}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 d-flex flex-wrap  justify-content-between"
                                 *ngIf="checklistType=='trailer'">
                                <div class="col-12 col-md-6 mb-2">
                                    <img class="truck_image" src="assets/images/trailer.png" alt="">
                                </div>
                                <div class="col-12 col-md-6 mb-2">
                                    <div class="truck_scroll">
                                        <div class="truck_scroll_card_comment"
                                             *ngFor="let c of checklistsTrailer,let i = index">
                                            <div class="top">
                                                <div class="d-flex">
                                                    <b>{{i + 1}}</b>
                                                    <p>{{(c.type).toLowerCase().replace(" ", "") | translate}}</p>
                                                </div>
                                                <div class="d-flex flex-wrap justify-content-center" style="gap: 10px">
                                                    <div *ngIf="c.statusTruck === 'accept'" class="check-btn"
                                                         [ngClass]="{'btn-success': c.statusTruck ===  'accept'}">
                                                        <svg-icon src="assets/svg/check.svg"></svg-icon>
                                                    </div>
                                                    <div *ngIf="c.statusTruck === 'error'" class="check-btn"
                                                         [ngClass]="{'btn-danger': c.statusTruck ===  'error'}">
                                                        <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                                                    </div>
                                                    <div *ngIf="c.statusTruck === 'notApply'" class="check-btn"
                                                         [ngClass]="{'btn-warning': c.statusTruck ===  'notApply'}">
                                                        <svg-icon src="assets/svg/icon_N_A.svg"></svg-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!isNullOrUndefined(c.photo === false)&&c.statusTruck ===  'error'||!isNullOrUndefined(c.comment)"
                                                 class="row_comment d-flex" style="gap: 15px">
                                                <img style="height: 78px;margin-top: 8px;"
                                                     *ngIf="c.statusTruck ===  'error'&&!isNullOrUndefined(c.photo)"
                                                     src="{{c.photo}}" alt="img">
                                                <p *ngIf="!isNullOrUndefined(c.comment)">{{c.comment}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!--                <div class="row">-->
                <!--                    <div class="col-12">-->
                <!--                        <div class="card_ret col-12 row">-->
                <!--                            <div class="col-12 col-md-5">-->
                <!--                                <span class="mb-3">-->
                <!--                                    {{'assinatura'|translate}}-->
                <!--                                    <strong>-->
                <!--                                        {{'digital'|translate}}-->
                <!--                                    </strong>-->
                <!--                                </span>-->
                <!--                                <div class="col-12 col-md-4">-->
                <!--                                    <p>-->
                <!--                                        {{'nomemotorista'|translate}}-->
                <!--                                    </p>-->
                <!--                                    <p class="ml-2 ">-->
                <!--                                        {{truck.driverName}}-->
                <!--                                    </p>-->
                <!--                                </div>-->
                <!--&lt;!&ndash;                                <div class="input-material" style="max-width: 300px" disabled="true">&ndash;&gt;-->
                <!--&lt;!&ndash;                                    <p class="form-control">&ndash;&gt;-->
                <!--&lt;!&ndash;                                        {{'empresa'|translate}}&ndash;&gt;-->
                <!--&lt;!&ndash;                                    </p>&ndash;&gt;-->
                <!--&lt;!&ndash;                                    <p >&ndash;&gt;-->
                <!--&lt;!&ndash;                                        {{truck.company}}&ndash;&gt;-->
                <!--&lt;!&ndash;                                    </p>&ndash;&gt;-->
                <!--&lt;!&ndash;                                    <input id="date" [(ngModel)]="truck.company"  class="form-control" type="text"&ndash;&gt;-->
                <!--&lt;!&ndash;                                           required />&ndash;&gt;-->
                <!--&lt;!&ndash;                                    <label for="date">{{'empresa'|translate}}</label>  &ndash;&gt;-->
                <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
                <!--                                <p class="text-primary">em <b>{{truck.date | date:'dd/MM/yyyy'}}</b></p>-->
                <!--                            </div>-->

                <!--&lt;!&ndash;                            <img class="col-12 col-md-7"&ndash;&gt;-->
                <!--&lt;!&ndash;                                 style="height: 135px;object-fit: contain;background-color: white"&ndash;&gt;-->
                <!--&lt;!&ndash;                                 [src]="truck.signature" alt="signature">&ndash;&gt;-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->

            </div>
        </div>

        <!--Carregamento-->
        <ng-container *ngIf="!isNullOrUndefined(truck.inspection)">
            <div *ngIf="tabType=='loading'">
                <div class="container">

                    <div class="row">
                        <div class="col-12 col-md-3">
                            <div class="card_ret_small col-12 row flex-column">
                            <span>
                                <strong>
                                    {{'dataehorario'|translate}}
                                </strong>
                                {{'doprocesso'|translate}}
                            </span>
                                <p class="text-light">
                                    {{'date'|translate}}
                                </p>
                                <p class="ml-2">
                                    {{truck.inspection?.date|date:'dd/MM/yyyy'}}
                                </p>
                                <p class="text-light mt-3">
                                    {{'horadechegada'|translate}}
                                </p>
                                <p class="ml-2">
                                    {{truck.inspection?.date|date:'HH:mm':'UTC+0'}}
                                </p>


                            </div>
                        </div>

                        <div class="col-12 col-md-9">
                            <div class="card_ret_small flex-column col-12 row">
                            <span>
                                {{'responsavel'|translate}}
                            </span>
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <p class="text-light">
                                            {{'nome'|translate}}
                                        </p>
                                        <p class="ml-2 ">
                                            {{truck.inspection?.createdBy.name}}
                                        </p>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <p class="text-light">
                                            {{'cpf'|translate}}
                                        </p>
                                        <p class="ml-2 ">
                                            {{this.translate.currentLang === 'pt' ? (truck.inspection?.createdBy.identifier| mask:'000.000.000-00') : (truck.inspection?.createdBy.identifier)}}
                                        </p>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <p class="text-light">
                                            Área
                                        </p>
                                        <p class="ml-2">
                                            {{truck.inspection?.createdBy.office}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row" *ngIf="truck?.inspection?.receipt?.length !== 0">
                        <div class="col-12">
                            <div class="card_ret_small flex-column col-12 row">
                                <div class="col-12 col-md-4">
                                <span>
                                    <strong>
                                        {{'notas'|translate}}
                                    </strong>
                                    {{'fiscais'|translate}}
                                </span>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6"
                                         *ngFor="let c of truck.inspection.receipt,let i = index">
                                        <div class="truck_scroll_card_comment">
                                            <div class="top">
                                                <div class="d-flex">
                                                    <b>{{i + 1}}</b>
                                                    <p>{{c.receipt}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="!isNullOrUndefined(truck.inspection.sealNumber)">
                        <div class="col-12 col-md-6">
                            <div class="card_ret_small flex-column col-12 row">
                                <div class="col-12">
                                <span>
                                    <strong>
                                        {{'numerodo'|translate}}
                                    </strong>
                                    {{'lacre'|translate}}
                                </span>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="truck_scroll_card_comment">
                                        <div class="top">
                                            <div class="d-flex">
                                                <b>1</b>
                                                <p>{{truck.inspection.sealNumber}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="uploaded mb-0 h-100">
                        <h5>{{"truck.fotolacre" | translate}}</h5>
                        <ul>
                            <li *ngIf="isNullOrUndefined(truck.inspection.sealPhoto)">
                                {{"truck.nophoto" | translate}}
                            </li>
                            <li class="ng-star-inserted" *ngIf="!isNullOrUndefined(truck.inspection.sealPhoto)">
                                <img width="300px" height="150px" src="{{truck.inspection.sealPhoto}}" alt="seal">
                            </li>
                        </ul>
                    </div>

                    <h4 class="text-primary">{{'comentario'|translate}}</h4>
                    <div class="input-material w-100">
                    <textarea name="truckCommentFinal" disabled [(ngModel)]="truck.inspection.comment"
                              id="InspectCommentFinal"
                              type="text"
                              class="form-control W-100"></textarea>
                        <label class="disabled" for="InspectCommentFinal">{{"escrevaaqui" | translate}}</label>
                    </div>


                </div>
            </div>
        </ng-container>

        <!--Saída-->
        <ng-container *ngIf="!isNullOrUndefined(truck.release)">
            <div *ngIf="tabType=='exit'">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="card_ret_small flex-column col-12 row">
                            <span>
                                <strong>
                                    {{'dataehorario'|translate}}
                                </strong>
                                {{'doprocesso'|translate}}
                            </span>

                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <p class="text-light">
                                            {{'date'|translate}}
                                        </p>
                                        <p class="ml-2">
                                            {{truck.release?.createdAt | date:'dd/MM/yyyy'}}
                                        </p>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <p class="text-light">
                                            {{'horadasaida'|translate}}
                                        </p>
                                        <p class="ml-2 ">
                                            {{truck.release?.createdAt | date:'HH:mm':'UTC+0'}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-5">
                            <div class="card_ret_small flex-column col-12 row">
                                 <span>
                                    {{'responsavel'|translate}}
                                 </span>

                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <p class="text-light">
                                            {{'nome'|translate}}
                                        </p>
                                        <p class="ml-2 ">
                                            {{truck.release?.createdBy.name}}
                                        </p>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <p class="text-light">
                                            {{'cpf'|translate}}
                                        </p>
                                        <p class="ml-2 ">
                                            {{this.translate.currentLang === 'pt' ? (truck.release?.createdBy.identifier| mask:'000.000.000-00') : (truck.release?.createdBy.identifier)}}
                                        </p>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <p class="text-light">
                                            Área
                                        </p>
                                        <p class="ml-2">
                                            {{truck.release?.createdBy.office}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="truck?.release?.receipt?.length !== 0">
                        <div class="col-12">
                            <div class="card_ret_small flex-column col-12 row">
                                <div class="col-12 col-md-4">
                                <span>
                                    <strong>
                                        {{'notas'|translate}}
                                    </strong>
                                    {{'fiscais'|translate}}
                                </span>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6" *ngFor="let c of truck.release?.receipt,let i = index">
                                        <div class="truck_scroll_card_comment">
                                            <div class="top">
                                                <div class="d-flex">
                                                    <b>{{i + 1}}</b>
                                                    <p>{{c.receipt.receipt}}</p>
                                                </div>
                                                <div class="d-flex flex-wrap justify-content-center" style="gap: 10px">
                                                    <div *ngIf="c.status" class="check-btn"
                                                         [ngClass]="{'btn-success': c.status === true}">
                                                        <svg-icon src="assets/svg/check.svg"></svg-icon>
                                                    </div>
                                                    <div *ngIf="!c.status" class="check-btn"
                                                         [ngClass]="{'btn-danger': c.status === false}">
                                                        <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row_comment">{{c.comment}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row" *ngIf="!isNullOrUndefined(truck.inspection?.sealNumber)">
                        <div class="col-12 col-md-6">
                            <div class="card_ret_small flex-column col-12 row">
                                <div class="col-12 col-md-4">
                                 <span>
                                    <strong>
                                {{'numerodo'|translate}}
                                     </strong>
                                     {{'lacre'|translate}}
                                 </span>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="truck_scroll_card_comment">
                                            <div class="top">
                                                <div class="d-flex">
                                                    <b>1</b>
                                                    <p>{{truck.inspection?.sealNumber}}</p>
                                                </div>
                                                <div class="d-flex flex-wrap justify-content-center" style="gap: 10px">
                                                    <div *ngIf="truck.release?.sealStatus" class="check-btn"
                                                         [ngClass]="{'btn-success': truck.release?.sealStatus === true}">
                                                        <svg-icon src="assets/svg/check.svg"></svg-icon>
                                                    </div>
                                                    <div *ngIf="!truck.release?.sealStatus" class="check-btn"
                                                         [ngClass]="{'btn-danger': truck.release?.sealStatus === false}">
                                                        <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="uploaded mb-0 h-100">
                        <h5>Foto do lacre</h5>
                        <ul>
                            <li *ngIf="isNullOrUndefined(truck.release.sealPhoto)">
                                Nenhuma imagem anexada
                            </li>
                            <li class="ng-star-inserted" *ngIf="!isNullOrUndefined(truck.release.sealPhoto)">
                                <img width="300px" height="150px" src="{{truck.release.sealPhoto}}" alt="seal">
                            </li>
                        </ul>
                    </div>

                    <h4 class="text-primary">{{'comentario'|translate}}</h4>
                    <div class="input-material w-100">
                    <textarea name="ReleaseCommentFinal" disabled [(ngModel)]="truck.release.comment"
                              id="ReleaseCommentFinal"
                              type="text"
                              class="form-control W-100"></textarea>
                        <label class="disabled" for="InspectCommentFinal">{{'escrevaaqui'|translate}}</label>
                    </div>

                </div>
            </div>
        </ng-container>


    </div>
</div>
