import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../base/base.component';
import {UserService} from '../../service/user.service';
import {
    AuthInput,
    AuthType,
    LoginGQL
} from '../../../generated/graphql';


@Component({
    selector: 'app-authenticate',
    templateUrl: './authenticate.component.html',
    styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent extends BaseComponent implements OnInit {

    // model: Observable<AuthInput>;
    // model: AuthInput = new AuthInput();

    modelIdentifierMock = '';
    model: any = {
        code: '',
        email: '',
        redirectUri: window.location.protocol + '//' + window.location.host + window.location.pathname
    };

    token = '';
    error = '';

    constructor(public router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                public userService: UserService,
                public login: LoginGQL) {
        super(router, translate);
    }

    ngOnInit(): void {
        if (environment.isLoginMock) {
            this.showModal('modalLoginEmail');
            return;
        }

        this.route.queryParams.subscribe(data => {
            if (data.error != null) {
                this.error = data.error_description;
                return;
            }

            if (data.code != null) {
                this.model.code = data.code;
                this.onLogin();
                return;
            }

            this.router.navigate(['/login']);
        });
    }

    onLogin(): void {
        let input: AuthInput = {
            identifier: this.modelIdentifierMock,
            password: '',
            redirectUri: window.location.protocol + '//' + window.location.host + window.location.pathname,
            code: this.model.code,
        }

        this.login.mutate({data: input}).subscribe(
            (data) => {
                const a: any = data;
                this.saveLocalUser(a.data.login);
            }, (err) => super.onError(err)
        );
    }

    saveLocalUser(data) {
        const token = data as AuthType;
        this.setToken(token.token);
        this.userService.updateUser(token.user);
        this.userService.getStatusAuthenticated();
        this.router.navigate(['/']).then();
    }

    onLoginMock() {
        const arrValidateFields = [
            {value: this.modelIdentifierMock, text: `${this.translate.instant('ID')}<br>`}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        this.model.email = this.modelIdentifierMock;
        this.closeModal('modalLoginEmail');
        this.onLogin();
    }

    closeLoginMock() {
        this.closeModal('modalLoginEmail');
        this.router.navigate(['/logout']);
    }
}
