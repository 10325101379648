<div class="page-header">
    <div class="container justify-content-between">
        <a href="javascript:history.back(-1); void('');" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <div></div>
        <!--        <div class="page-header__number">BIO Nº <strong>59745</strong></div>-->
        <h2 class="page-header__title">{{'nova'|translate}} <strong>{{'ocorrencia'|translate}}</strong></h2>
        <div class="page-header__date">{{'criadoem'|translate}}<strong></strong></div>
    </div>
</div>

<section class="custom-nav">
    <ul class="nav nav-tabs custom-nav__menu">
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': step >= 1}" data-toggle="tab">
                <span>1</span>
                <div class="bubble"></div>
                <span class="label">{{'dadosda'|translate}}<strong>{{'unidade'|translate}}</strong></span>
            </a>
        </li>
        <li class="nav-item">
            <span class="nav-link" [ngClass]="{'active': step >= 2 }">
                <span>2</span>
                <div class="bubbles" [ngClass]="{'active': step >= 2 }">
                    <div class="bubble" [ngClass]="{'active': step >= 2}"></div>
                    <div class="bubble" [ngClass]="{'active': step >= 3}"></div>
                    <div class="bubble" [ngClass]="{'active': step >= 4}"></div>
                </div>
                <span class="label">{{'ocorrencia'|translate}}</span>
            </span>
        </li>
        <li class="nav-item">
            <span class="nav-link" [ngClass]="{'active': step >= 5 }">
                <span>3</span>
                <div class="bubbles" [ngClass]="{'active': step >= 5 }">
                    <div class="bubble" [ngClass]="{'active': step >= 5}"></div>
                    <div class="bubble" [ngClass]="{'active': step >= 6}"></div>
                    <div class="bubble" [ngClass]="{'active': step >= 7}"></div>
                </div>
                <span class="label">{{'analiseda'|translate}}<strong>{{'ocorrencia'|translate}}</strong></span>
            </span>
        </li>
    </ul>
</section>

<div class="page-content">
    <div class="container">
        <section class="tab-content">
            <article id="step-1" class="tab-pane fade" [ngClass]="{'show active': step == 1 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section secondary">
                        <strong>1</strong>
                        <span>{{'dadosda'|translate}}<span
                                class="text-uppercase is-highlight">{{'unidade'|translate}}</span></span>
                    </h2>
                </div>
                <form action="#" class="page-content__form mb-0">
                    <div class="row">
                        <div class="col-12">
                            <div class="input-material countries">
                                <label class="auto mr-0">País</label>
                                <div class="row justify-content-around">
                                    <div class="col-4 col-md-2 d-flex flex-column align-items-center justify-content-center"
                                         *ngFor="let c of countries" (click)="onSelectCountry(c.id)"
                                         [ngClass]="selectedCountryId === c.id ? 'languages-active': 'languages'">
                                        <img class="country_flag" [src]="'assets/images/country/' + c.flag + '.svg'"
                                             alt="">
                                        <span style="font-size: 13px; text-align: center">{{c.name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="input-material select">
                                <select name="unity" id="unity"
                                        [(ngModel)]="selectedUnityId"
                                        [disabled]="profile"
                                        class="form-control" required>
                                    <option *ngIf="isNullOrUndefined(selectedCountryId)" [ngValue]="undefined">
                                        {{'selecionepais'|translate}}
                                    </option>

                                    <option *ngIf="(isNullOrUndefined(selectedUnityId) && !isNullOrUndefined(selectedCountryId))"
                                            [ngValue]="undefined">{{'selectUnity'|translate}}</option>

                                    <option *ngFor="let c of unities" value="{{c.id}}">{{c.name}}</option>
                                </select>
                                <label style="top:4px" for="unity">{{'unidade'|translate}}*</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input [(ngModel)]="model.area" id="area" name="area" class="form-control" type="text"
                                       required/>
                                <label for="area">Área*</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-md-6">
                            <div class="input-material">
                                <input [(ngModel)]="model.areaResponsible" id="areaResponsible" name="areaResponsible"
                                       class="form-control" type="text" required/>
                                <label for="areaResponsible">Responsável*</label>
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="input-material">
                                <input [(ngModel)]="model.bulletinNumber" id="code" name="code" class="form-control"
                                       type="text"
                                       required/>
                                <label for="code">BO Nº</label>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="page-content__buttons">
                    <button class="btn btn-warning btn-new" data-toggle="tab" (click)="actionSave()">
                        {{'proximo'|translate}}
                    </button>
                </div>
            </article>
            <article id="step-2" class="tab-pane fade" [ngClass]="{'show active': step == 2 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section secondary">
                        <strong>2</strong>
                        <span>{{'ocorrencia'|translate}} <span class="is-highlight">{{'vitima'|translate}}</span></span>
                    </h2>
                </div>
                <form action="#" class="page-content__form full mb-0">
                    <div class="d-flex gap-30 justify-content-between select-labels">
                        <label class="checkbox-styled checkbox-text">
                            <input type="radio" name="type" id="pf"
                                   (click)="modelUserVictim.type = 'pessoal'; enableDatePickers();"
                                   [checked]="modelUserVictim.type === 'pessoal'"/>
                            <span>{{'pessoafisica'|translate}}</span>
                        </label>
                        <label class="checkbox-styled checkbox-text">
                            <input type="radio" name="type" id="pj"
                                   (click)="modelUserVictim.type = 'legal'; enableDatePickers();"
                                   [checked]="modelUserVictim.type === 'legal'">
                            <span>{{'pessoajuridica'|translate}}</span>
                        </label>
                    </div>
                    <div *ngIf="modelUserVictim.type === 'pessoal'"
                         class="page-content__details box forms mt-0 mb-md-4">
                        <div class="row">
                            <div class="col-12 col-lg-3">
                                <div class="profile-type">
                                    <img src="assets/images/user.svg" alt="">
                                    <p>{{'dadosdavitima'|translate}}<br>{{'comunicante'|translate}}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-9">
                                <div class="row">
                                    <div class="col-12 col-md-5">
                                        <div class="input-material">
                                            <input name="name" id="name" [(ngModel)]="modelUserVictim.name"
                                                   class="form-control"
                                                   type="text" required/>
                                            <label for="name">{{'nome'|translate}}*</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="input-material">
                                            <input id="birth" class="form-control date-picker-birth"
                                                   type="text"
                                                   required/>
                                            <label for="birth">
                                                <span>{{'nascimento'|translate}}*</span>
                                                <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <div class="input-material select">
                                            <select name="gender" [(ngModel)]="modelUserVictim.gender" id="gender"
                                                    name="gender"
                                                    class="form-control" required>
                                                <option value="fem">Feminino</option>
                                                <option value="masc">Masculino</option>
                                            </select>
                                            <label for="gender">Sexo*</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-5">
                                        <div class="input-material">
                                            <input name="rg" id="rg"
                                                   [mask]="this.translate.currentLang==='pt'? '00.000.000-0' : ''"
                                                   [(ngModel)]="modelUserVictim.rg"
                                                   class="form-control" type="text" required/>
                                            <label for="rg">{{'rg'|translate}}*</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.uf" maxlength="2" name="ufRg" id="ufRg"
                                                   class="form-control"
                                                   type="text" required/>
                                            <label for="ufRg">UF do {{'rg'|translate}}*</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.nationality" name="nationality"
                                                   id="nationality" class="form-control"
                                                   type="text" required/>
                                            <label for="nationality">Natural*</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.document" name="document"
                                                   id="document"
                                                   [mask]="this.translate.currentLang==='pt'? '000.000.000-00' : ''"
                                                   (blur)="onClearVictim($event, 'document')"
                                                   class="form-control" type="text" required/>
                                            <label for="document">{{'cpf'|translate}}*</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.jobRole" name="jobRole" id="jobRole"
                                                   class="form-control" type="text" required/>
                                            <label for="jobRole">Cargo*</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.sector" name="sector" id="sector"
                                                   class="form-control" type="text" required/>
                                            <label for="sector">Setor*</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <div class="input-material">
                                    <input [(ngModel)]="modelUserVictim.cep" name="cepVictim" id="cepVictim"
                                           [mask]="'00.000-000'"
                                           class="form-control" type="text" required/>
                                    <label for="cepVictim">CEP*</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input [(ngModel)]="modelUserVictim.address" name="address" id="address"
                                           class="form-control"
                                           type="text" required/>
                                    <label for="address">{{'Endereco'|translate}}*</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-2">
                                <div class="input-material">
                                    <input [(ngModel)]="modelUserVictim.addressNumber" name="addressNumber"
                                           id="addressNumber" class="form-control" type="text" required/>
                                    <label for="addressNumber">Nº*</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="input-material mb-0">
                                    <input [(ngModel)]="modelUserVictim.complement" name="complement" id="complement"
                                           class="form-control" type="text" required/>
                                    <label for="complement">Complemento</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="modelUserVictim.type === 'legal'" class="page-content__details box forms mt-0 mb-md-4">
                        <div class="row">
                            <div class="col-12 col-lg-3">
                                <div class="profile-type">
                                    <img src="assets/images/user.svg" alt="">
                                    <p>{{'dadosdavitima'|translate}}<br>{{'comunicante'|translate}}</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-9">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.name" name="nameFantasy"
                                                   id="nameFantasy" class="form-control" type="text"
                                                   required/>
                                            <label for="nameFantasy">{{'nomefantasia'|translate}}*</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.document" id="documentVictim"
                                                   name="documentVictim"
                                                   [mask]="'00.000.000-0000/00'"
                                                   (blur)="onClearVictim($event, 'document')"
                                                   [dropSpecialCharacters]="false"
                                                   [clearIfNotMatch]="true"
                                                   class="form-control" type="text" required/>
                                            <label for="documentVictim">CNPJ*</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.cep" name="cepVictimCompany"
                                                   id="cepVictimCompany"
                                                   [mask]="'00.000-000'"
                                                   (blur)="onClearVictim($event, 'cep')"
                                                   [dropSpecialCharacters]="false"
                                                   [clearIfNotMatch]="true"
                                                   class="form-control" type="text" required/>
                                            <label for="cepVictimCompany">CEP*</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.address" name="addressCompany"
                                                   id="addressCompany"
                                                   class="form-control"
                                                   type="text" required/>
                                            <label for="addressCompany">{{'Endereco'|translate}}*</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.addressNumber"
                                                   name="addressNumberCompany"
                                                   id="addressNumberCompany" class="form-control" type="text" required/>
                                            <label for="addressNumberCompany">Nº*</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelUserVictim.complement" name="complementCompany"
                                                   id="complementCompany"
                                                   class="form-control" type="text" required/>
                                            <label for="complementCompany">Complemento</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-content__details box white mt-0 mb-md-4">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input [(ngModel)]="model.company" name="company" id="company" class="form-control"
                                           type="text" required/>
                                    <label for="company">Empresa</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input [(ngModel)]="model.department" name="department" id="department"
                                           class="form-control" type="text" required/>
                                    <label for="department">Departamento</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input [(ngModel)]="model.responsible" name="responsible"
                                           id="responsible" class="form-control" type="text" required/>
                                    <label for="responsible">{{'nomedoresponsavel'|translate}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="input-material">
                                    <input [(ngModel)]="model.place" name="place" id="place" class="form-control"
                                           type="text" required/>
                                    <label for="place">{{'local'|translate}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="input-material">
                                    <input [(ngModel)]="model.hour" name="hour" id="hour" class="form-control"
                                           [mask]="'00:00'"
                                           [dropSpecialCharacters]="false"
                                           [clearIfNotMatch]="true"
                                           (blur)="onClearModel($event, 'hour')"
                                           type="text" required/>
                                    <label class="d-flex justify-content-between w-100" for="hour">
                                        <span>Hora</span>
                                        <svg-icon class="right" src="assets/svg/time.svg"></svg-icon>
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="input-material">
                                    <input id="date" class="form-control date-picker" type="text"
                                           required/>
                                    <label for="date">
                                        <span>{{'dataocorrencia'|translate}}*</span>
                                        <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <div class="input-material select">
                                    <select name="classification" [(ngModel)]="model.classification" id="classification"
                                            class="form-control" required>
                                        <option value="effective">{{'ocorrenciaefetiva'|translate}}</option>
                                        <option value="try">{{'tentativa'|translate}}</option>
                                        <option value="recovered">{{'recuperado'|translate}}</option>
                                    </select>
                                    <label for="classification">{{'classificacao'|translate}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="input-material select">
                                    <select name="sinister" [(ngModel)]="model.sinister" id="sinister"
                                            class="form-control" required>
                                        <option value="opportunity">{{'crimeoportunidade'|translate}}</option>
                                        <option value="planned">{{'crimeplanejado'|translate}}</option>
                                        <option value="accident">{{'acidente'|translate}}</option>
                                        <option value="major">{{'forcamaior'|translate}}</option>
                                    </select>
                                    <label for="sinister">{{'sinistro'|translate}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="input-material switcher c-default">
                                    <label class="auto c-default" for="recorrente">{{'recorrente'|translate}}</label>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" [(ngModel)]="model.recurrent" name="recurrent"
                                               class="custom-control-input" id="recorrente">
                                        <label class="custom-control-label" for="recorrente"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="input-material switcher c-default">
                                    <label class="auto c-default" for="afastamento">{{'afastamento'|translate}}</label>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" [(ngModel)]="model.abscent" name="abscent"
                                               class="custom-control-input"
                                               id="afastamento">
                                        <label class="custom-control-label" for="afastamento"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="input-material select">
                                    <select name="victims" [(ngModel)]="model.victims" id="victims"
                                            (ngModelChange)="model.victimsDetail = undefined"
                                            class="form-control" required>
                                        <option value="employee">{{'colaborador'|translate}}</option>
                                        <option value="third">{{'terceiro'|translate}}</option>
                                        <option value="hired">{{'contratado'|translate}}</option>
                                        <option value="visitor">{{'visitante'|translate}}</option>
                                        <option value="external_agent">{{'agenteexterno'|translate}}</option>
                                        <option value="client">{{'cliente'|translate}}</option>
                                        <option value="company">{{'empresa'|translate}}</option>
                                        <option value="others">{{'outros'|translate}}</option>
                                    </select>
                                    <label for="victims">{{'vitimas'|translate}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="input-material select">
                                    <select name="type" [(ngModel)]="model.type" id="type"
                                            (ngModelChange)="model.typeDetail = undefined"
                                            class="form-control" required>
                                        <option value="rob">{{'roubo'|translate}}</option>
                                        <option value="theft">{{'furto'|translate}}</option>
                                        <option value="fraud">{{'fraude'|translate}}</option>
                                        <option value="misplacement">{{'extravio'|translate}}</option>
                                        <option value="damage">{{'dano'|translate}}</option>
                                        <option value="vandalism">{{'vandalismo'|translate}}</option>
                                        <option value="kidnapping">{{'sequestro'|translate}}</option>
                                        <option value="aggression">{{'agressao'|translate}}</option>
                                        <option value="drug_trafficking">{{'trafico'|translate}}</option>
                                        <option value="drug_consumption">{{'consumo'|translate}}</option>
                                        <option value="disintelligence">{{'desinteligencia'|translate}}</option>
                                        <option value="sex_crime">{{'crimesexual'|translate}}</option>
                                        <option value="environment_crime">{{'crimeambiental'|translate}}</option>
                                        <option value="weapon">{{'portearma'|translate}}</option>
                                        <option value="melee_weapon">{{'portearmabranca'|translate}}</option>
                                        <option value="others">{{'outro'|translate}}</option>
                                    </select>
                                    <label for="type">{{'tipoocorrencia'|translate}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div *ngIf="model.victims === 'others'" class="col-12 col-md-6">
                                <textarea [(ngModel)]="model.victimsDetail" maxlength="40" id="victimsDetail"
                                          name="victimsDetail"
                                          class="input-material form-control mb-2"
                                          required></textarea>
                                <p class="d-block text-right m-0 mb-3">{{'maximocaracteres'|translate}}</p>
                            </div>
                            <div *ngIf="model.victims !== 'others'" class="col-12 col-md-6"></div>
                            <div *ngIf="model.type === 'others'" class="col-12 col-md-6">
                                <textarea name="typeDetail" id="typeDetail" [(ngModel)]="model.typeDetail"
                                          maxlength="40"
                                          class="input-material form-control mb-2" required></textarea>
                                <p class="d-block text-right m-0 mb-3">{{'maximocaracteres'|translate}}</p>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="page-content__buttons">
                    <button class="btn btn-warning btn-new" (click)="actionSave()" data-toggle="tab">
                        {{'proximo'|translate}}
                    </button>
                </div>
            </article>
            <article id="step-3" class="tab-pane fade" [ngClass]="{'show active': step == 3 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section secondary">
                        <strong>2</strong>
                        <span>{{'ocorrencia'|translate}} <span
                                class="is-highlight">{{'autoria'|translate}}</span></span>
                    </h2>
                </div>
                <div class="mt-3 mt-lg-5 input-wrapper small">
                    <span class="label">{{'autoria'|translate}}</span>
                    <div class="input-wrapper__fields">
                        <label>
                            <input type="radio" name="type" value="unknown" id="desconhecida"
                                   [(ngModel)]="model.authorship"/>
                            <span>{{'desconhecida'|translate}}</span>
                        </label>
                        <label>
                            <input type="radio" value="known" name="type" id="conhecida" (click)="enableDatePickers()"
                                   [(ngModel)]="model.authorship"/>
                            <span>{{'conhecida'|translate}}</span>
                        </label>
                    </div>
                </div>
                <div class="page-content__form small" style="min-height:initial" *ngIf="model.authorship === 'known'">
                    <div class="page-content__details forms p-3 mt-0 mb-md-4">
                        <a href="javascript:void(0)" (click)="onAddAuthor()" class="btn btn-primary">
                            <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
                        </a>
                        <div class="table-responsive" *ngIf="!isNullOrUndefined(authors)">
                            <table class="table table_regular">
                                <thead>
                                <tr>
                                    <th scope="col">Nome</th>
                                    <th scope="col">{{'cpf' | translate}}</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let a of authors,let i = index">
                                    <td>{{a.name}}</td>
                                    <td>{{this.translate.currentLang === 'pt' ? (a.document| mask:'000.000.000-00') : (a.document)}}</td>
                                    <td>
                                        <div class="table_action">
                                            <button (click)="removeAuthor(a.id,i)">
                                                <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <form class="page-content__form small">
                    <div class="row">
                        <div class="col-12">
                            <div class="input-material select">
                                <select name="violence" [(ngModel)]="model.violence"
                                        id="violence"
                                        class="form-control" required>
                                    <option value="no_physical_aggression">{{'semagressaofisica'|translate}}</option>
                                    <option value="no_injury">{{'comagressaofisica'|translate}}</option>
                                    <option value="with_injury">{{'comagressaofisicalesao'|translate}}</option>
                                    <option value="death">{{'ocorrenciamorte'|translate}}</option>
                                </select>
                                <label for="violence">{{'violencia'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="model.authorship === 'known'">
                            <div class="input-material select">
                                <select [(ngModel)]="model.author" id="authorType" name="authorType"
                                        class="form-control" required>
                                    <option value="employee">{{'colaborador'|translate}}</option>
                                    <option value="third">{{'terceiro'|translate}}</option>
                                    <option value="hired">{{'contratado'|translate}}</option>
                                    <option value="visitor">{{'visitante'|translate}}</option>
                                    <option value="client">{{'cliente'|translate}}</option>
                                    <option value="company">{{'empresa'|translate}}</option>
                                    <option value="others">{{'outros'|translate}}</option>
                                    <option value="invader">{{'invasor'|translate}}</option>
                                </select>
                                <label for="authorType">{{'author'|translate}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper small">
                        <span class="label">Colaborador(es) <br>envolvidos(s)?</span>
                        <div class="input-wrapper__fields">
                            <label>
                                <input [(ngModel)]="model.employeesInvolved" type="radio" name="colab" value="yes"/>
                                <span>{{'sim'|translate}}</span>
                            </label>
                            <label>
                                <input [(ngModel)]="model.employeesInvolved" type="radio" name="colab" value="no"/>
                                <span>{{'nao'|translate}}</span>
                            </label>
                            <label>
                                <input [(ngModel)]="model.employeesInvolved" type="radio" name="colab" value="likely">
                                <span>{{'provavel'|translate}}</span>
                            </label>
                        </div>
                    </div>
                    <ng-container *ngIf="model.employeesInvolved === 'yes'">
                        <div class="row input-collaborator mb-3" style="row-gap: 30px">
                            <div class="col col-12">
                                <div class="input-material mb-0">
                                    <input name="collaboratorName" id="collaboratorName" [(ngModel)]="collaborator.name"
                                           class="form-control"
                                           type="text" required
                                           [maxLength]="80"/>
                                    <label for="collaboratorName">
                                        <span>{{'nome'|translate}}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-material mb-0">
                                    <input name="collaboratorRe" id="re" [(ngModel)]="collaborator.re"
                                           class="form-control" type="text"
                                           required
                                           [maxLength]="6"/>
                                    <label for="re">
                                        <span>RE</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col col-auto">
                                <a href="javascript:void(0)" (click)="addCollaborator()" class="btn btn-lg btn-primary">
                                    <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <div class="table-responsive" *ngIf="!isNullOrUndefined(collaborators)">
                            <table class="table table_regular">
                                <thead>
                                <tr>
                                    <th scope="col">Nome</th>
                                    <th scope="col">RE</th>
                                    <th class="text-center" scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let c of collaborators,let i = index">
                                    <td>{{c.name}}</td>
                                    <td>{{c.re}}</td>
                                    <td class="text-center">
                                        <a (click)="removeCollaborator(c.id,i)">
                                            <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>


                </form>
                <div class="page-content__buttons">
                    <button class="btn btn-primary btn-new" (click)="stepBack();" data-toggle="tab">
                        {{'voltar'|translate}}
                    </button>
                    <button class="btn btn-warning btn-new" data-toggle="tab" (click)="actionSave()">
                        {{'proximo'|translate}}
                    </button>
                </div>
            </article>
            <article id="step-4" class="tab-pane fade" [ngClass]="{'show active': step == 4 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section secondary">
                        <strong>2</strong>
                        <span>{{'ocorrencia'|translate}} <span
                                class="is-highlight">{{'resumodaocorrencia'|translate}}</span></span>
                    </h2>
                </div>
                <form action="#" class="page-content__form full justify-content-center mb-0 ml-0 mr-0">
                    <div class="row w-full">
                        <div class="col-12 col-lg-8">
                            <p><strong>{{'detalharocorrencia'|translate}}</strong></p>
                            <div class="input-material w-full mt-3">
                                <textarea [(ngModel)]="model.occurrenceDetail" name="occurrenceDetail"
                                          class="input-material form-control w-full small" rows="13" required>
                                    {{'escrevaaqui'|translate}}</textarea>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="page-content__upload single with-margin">
                                <p>{{'escolhaarquivo'|translate}}</p>
                                <div class="input-material select mt-4 mb-2">
                                    <select [(ngModel)]="uploadTypeSelected" name="uploadTypeSelected"
                                            class="form-control w-full pt-0" required>
                                        <option value="">{{'selecionetipo'|translate}}</option>
                                        <option value="video">{{'videos'|translate}}</option>
                                        <option value="picture">{{'fotos'|translate}}</option>
                                        <option value="sound">{{'audios'|translate}}</option>
                                    </select>
                                </div>
                                <ng-container *ngIf="uploadTypeSelected !== ''">
                                    <label
                                            class="btn btn-primary  page-content__upload__input w-full mb-0">
                                        <span>{{'anexararquivo'|translate}}</span>
                                        <input (change)="handlerUpload($event.target.files)" type="file" id="files"
                                               [accept]="getAcceptTypes()"
                                               name="files" required/>
                                    </label>
                                    <!--                                    <label *ngIf="uploadTypeSelected === 'picture'"-->
                                    <!--                                           (click)="showModal('uploadPhoto')"-->
                                    <!--                                           class="btn btn-primary  page-content__upload__input w-full mb-0">-->
                                    <!--                                        <span>{{'anexararquivo'|translate}}</span>-->
                                    <!--                                    </label>-->
                                    <br>
                                    <input name="comment" [(ngModel)]="fileComment"
                                           id="comment"
                                           class="form-control"
                                           type="text" required/>
                                    <label for="comment">{{'comentário' | translate}}</label>
                                    <!--                                    <label *ngIf="uploadTypeSelected === 'picture'"-->
                                    <!--                                           (click)="showModal('uploadPhoto')"-->
                                    <!--                                           class="btn btn-primary  page-content__upload__input w-full mb-0">-->
                                    <!--                                        <span>{{'anexararquivo'|translate}}</span>-->
                                    <!--                                    </label>-->
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </form>
                <div class="row mx-0">
                    <div class="col-12 col-lg-4">
                        <div class="uploaded">
                            <h5>{{'videos'|translate}}</h5>
                            <ul>
                                <li *ngIf="isNullOrUndefined(videos) && isNullOrUndefined(filteredFiles(model.files, 'video'))">
                                    {{'nenhumvideoanexado'|translate}}
                                </li>
                                <li *ngFor="let video of filteredFiles(model.files, 'video')">
                                    <span>{{video.originalName}}</span>
                                    <svg-icon (click)="removeFile('video', null, video.id)" class="remove"
                                              src="assets/svg/trash.svg"></svg-icon>
                                </li>
                                <li *ngFor="let video of videos; let i = index">
                                    <span>{{video.file.name}}</span>
                                    <svg-icon (click)="removeFile('video', i)" class="remove"
                                              src="assets/svg/trash.svg"></svg-icon>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="uploaded">
                            <h5>{{'fotos'|translate}}</h5>
                            <ul>
                                <li *ngIf="isNullOrUndefined(pictures) && isNullOrUndefined(filteredFiles(model.files, 'picture'))">
                                    {{'nenhumimagemanexado'|translate}}
                                </li>
                                <li *ngFor="let img of filteredFiles(model.files, 'picture')">
                                    <span>{{img.originalName}}</span>
                                    <svg-icon (click)="removeFile('picture', null, img.id)" class="remove"
                                              src="assets/svg/trash.svg"></svg-icon>
                                </li>
                                <li *ngFor="let img of pictures; let i = index">
                                    <span>{{img.file.name}} - {{img.comment}}</span>
                                    <svg-icon (click)="removeFile('picture', i)" class="remove"
                                              src="assets/svg/trash.svg"></svg-icon>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="uploaded">
                            <h5>{{'audios'|translate}}</h5>
                            <ul>
                                <li *ngIf="isNullOrUndefined(sounds) && isNullOrUndefined(filteredFiles(model.files, 'sound'))">
                                    {{'nenhumaudioanexado'|translate}}
                                </li>
                                <li *ngFor="let sound of filteredFiles(model.files, 'sound')">
                                    <span>{{sound.originalName}}</span>
                                    <svg-icon (click)="removeFile('sound', null, sound.id)" class="remove"
                                              src="assets/svg/trash.svg"></svg-icon>
                                </li>
                                <li *ngFor="let music of sounds; let i = index">
                                    <span>{{music.file.name}}</span>
                                    <svg-icon (click)="removeFile('sound', i)" class="remove"
                                              src="assets/svg/trash.svg"></svg-icon>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="page-content__buttons">
                    <button class="btn btn-primary btn-new" (click)="stepBack();" data-toggle="tab">
                        {{'voltar'|translate}}
                    </button>
                    <button class="btn btn-warning btn-new" data-toggle="tab" (click)="actionUpload()">
                        {{'proximo'|translate}}
                    </button>
                </div>
            </article>
            <article id="step-5" class="tab-pane fade" [ngClass]="{'show active': step == 5 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section secondary">
                        <strong>3</strong>
                        <span>{{'analiseocorrencia'|translate}} <span class="is-highlight">{{'perdas'|translate}}</span></span>
                    </h2>
                </div>
                <form action="#" class="page-content__form full mx-0">
                    <div class="row w-full pt-4 mx-0">
                        <div class="col-12 col-lg-6 col-bordered">
                            <h4 class="input-head">{{'perdas'|translate}} <strong>{{'diretas'|translate}}</strong></h4>
                            <span class="label">{{'tipo'|translate}}</span>
                            <div class="row row-first">
                                <div class="input-wrapper__fields">
                                    <label>
                                        <input class="mr-2" [(ngModel)]="model.directLossType" id="directLossTypeValue"
                                               (ngModelChange)="model.directLossDescription = undefined" type="radio"
                                               name="directLossType"
                                               value="value"/>
                                        <span>{{'especie'|translate}}</span>
                                    </label>
                                    <label>
                                        <input class="mr-2" [(ngModel)]="model.directLossType"
                                               id="directLossTypeMaterial"
                                               (ngModelChange)="model.directLossDescription = undefined" type="radio"
                                               name="directLossType"
                                               value="material"/>
                                        <span>{{'material'|translate}}</span>
                                    </label>
                                    <label>
                                        <input class="mr-2" [(ngModel)]="model.directLossType" id="directLossTypeOthers"
                                               (ngModelChange)="model.directLossDescription = undefined" type="radio"
                                               name="directLossType"
                                               value="others">
                                        <span>{{'outros'|translate}}</span>
                                    </label>
                                </div>

                                <div *ngIf="model.directLossType === 'others'" class="col-12">
                                    <textarea [(ngModel)]="model.directLossDescription" id="directLossDescription"
                                              name="directLossDescription"
                                              class="input-material form-control" rows="3" required></textarea>
                                </div>
                                <div class="col-12">
                                    <div class="mb-4">
                                        <h4 class="input-divider small">{{'objetos'|translate}}</h4>
                                        <textarea [(ngModel)]="model.directLossObjects" id="directLossObjects"
                                                  name="directLossObjects"
                                                  class="input-material form-control" rows="3" required></textarea>
                                    </div>
                                    <div class="mb-4">
                                        <h4 class="input-divider small">Valor em R$</h4>
                                        <input id="valueMoney" currencyMask maxlength="17"
                                               [(ngModel)]="model.directLossValue"
                                               name="valueMoney" class="form-control" type="text" required/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <h4 class="input-head mt-5 mt-lg-0">{{'perdas'|translate}}
                                <strong>{{'indiretas'|translate}}</strong></h4>
                            <div class="row row-first">
                                <div class="col-12">
                                    <h4 class="input-divider small d-none d-lg-block"></h4>
                                    <div class="input-material switcher c-default">
                                        <label class="auto c-default"
                                               for="atividade">{{'interrupcaoatividade'|translate}}</label>
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" [(ngModel)]="model.indirectLossInterruption"
                                                   name="indirectLossInterruption"
                                                   class="custom-control-input" id="atividade">
                                            <label class="custom-control-label" for="atividade"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-material">
                                        <input [(ngModel)]="model.indirectLossActivity" id="indirectLossActivity"
                                               name="indirectLossActivity" class="form-control" type="text"
                                               required/>
                                        <label for="indirectLossActivity">{{'atividade'|translate}}</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-material">
                                        <input [(ngModel)]="model.indirectLossTime" id="indirectLossTime"
                                               [mask]="'Hh:m0'"
                                               [dropSpecialCharacters]="false"
                                               [clearIfNotMatch]="true"
                                               (blur)="onClearModel($event, 'indirectLossTime')"
                                               name="indirectLossTime" class="form-control" type="text" required/>
                                        <label class="w-100 d-flex justify-content-between" for="indirectLossTime">
                                            <span>{{'tempoparada'|translate}}</span>
                                            <svg-icon class="right" src="assets/svg/time.svg"></svg-icon>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-material">
                                        <input [(ngModel)]="model.indirectLossCost" name="indirectLossCost"
                                               id="indirectLossCost"
                                               currencyMask
                                               class="form-control" type="text" required/>
                                        <label for="indirectLossCost">{{'custohoraatividade'|translate}}</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-material">
                                        <input [(ngModel)]="model.indirectLossRemoval" id="indirectLossRemoval"
                                               currencyMask
                                               name="indirectLossRemoval" class="form-control" type="text" required/>
                                        <label for="indirectLossRemoval">{{'removal'|translate}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row w-full row-dark mx-0">
                        <div class="col-12 col-lg-6 col-bordered">
                            <h4 class="input-head"><strong>{{'recuperado'|translate}}</strong></h4>
                            <div class="input-wrapper__fields">
                                <label>
                                    <input class="mr-2" [(ngModel)]="model.recoveryStatus" type="radio"
                                           name="recoveryStatus"
                                           value="avoided"/>
                                    <span>{{'evitada'|translate}}</span>
                                </label>
                                <label>
                                    <input class="mr-2" [(ngModel)]="model.recoveryStatus" type="radio"
                                           name="recoveryStatus"
                                           value="retrieved"/>
                                    <span>{{'recuperada'|translate}}</span>
                                </label>
                                <label>
                                    <input class="mr-2" [(ngModel)]="model.recoveryStatus" type="radio"
                                           name="recoveryStatus"
                                           value="lost">
                                    <span>{{'naorecuperada'|translate}}</span>
                                </label>
                            </div>
                            <div class="col-12">
                                <textarea [(ngModel)]="model.recoveryStatusDescription" id="recoveryStatusDescription"
                                          name="recoveryStatusDescription"
                                          class="input-material form-control" rows="3" required></textarea>
                                <div class="row row-first">
                                    <div class="col-12 mb-4">
                                        <h4 class="input-divider small">Valor em R$</h4>
                                        <input id="valueMoney2" currencyMask maxlength="17"
                                               [(ngModel)]="model.retrievedValue"
                                               name="valueMoney2" class="form-control" type="text" required/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <h4 class="input-head mt-5 mt-lg-0"><strong>{{'estimativaperdas'|translate}}</strong></h4>
                            <div class="row row-first">
                                <div class="col-12">
                                    <h4 class="input-divider small d-none d-lg-block"></h4>
                                    <div class="input-material">
                                        <label>{{'diretas'|translate}} {{model.directLossValue | currency: 'BRL'}}</label>

                                        <!--                                        <input [(ngModel)]="model.directLoss" id="directLoss" name="directLoss"-->
                                        <!--                                               currencyMask-->
                                        <!--                                               class="form-control" type="text" required/>-->
                                        <!--                                        <label for="directLoss">{{'diretas'|translate}}</label>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-material">
                                        <label>{{'indiretas'|translate}} {{((((model.indirectLossCost * ((((this.model.indirectLossTime)?.slice(0, 2) * 60) + (((this.model.indirectLossTime)?.slice(3, 5) * 1))) / 60)) + model.indirectLossRemoval))) | currency: 'BRL'}}</label>

                                        <!--                                        <input [(ngModel)]="model.indirectLoss" id="indirectLoss" name="indirectLoss"-->
                                        <!--                                               currencyMask-->
                                        <!--                                               class="form-control" type="text"-->
                                        <!--                                               required/>-->
                                        <!--                                        <label for="indirectLoss">{{'indiretas'|translate}}</label>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-material">
                                        <label>{{'perdatotal'|translate}} {{((model.directLossValue + ((model.indirectLossCost * ((((this.model.indirectLossTime)?.slice(0, 2) * 60) + (((this.model.indirectLossTime)?.slice(3, 5) * 1))) / 60)) + model.indirectLossRemoval)) - model.retrievedValue) | currency: 'BRL'}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="page-content__buttons">
                    <button class="btn btn-primary btn-new" (click)="stepBack();" data-toggle="tab">
                        {{'voltar'|translate}}
                    </button>
                    <button (click)="actionSave()" class="btn btn-warning btn-new" data-toggle="tab">
                        {{'proximo'|translate}}
                    </button>
                </div>
            </article>
            <article id="step-6" class="tab-pane fade" [ngClass]="{'show active': step == 6 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section secondary">
                        <strong>3</strong>
                        <span>{{'analiseocorrencia'|translate}}<span
                                class="is-highlight">{{'facilitadoresocorrencia'|translate}}</span></span>
                    </h2>
                </div>
                <form class="page-content__form small mt-3 mt-lg-5 pt-2 mb-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="input-material select">
                                <select [(ngModel)]="model.favor" name="favor" id="favor"
                                        (ngModelChange)="model.favorDetail = undefined;"
                                        class="form-control" required>
                                    <option *ngFor="let f of favors" value="{{f.value}}">{{f.value}}</option>
                                </select>
                                <label for="favor">{{'favorecemocorrencia'|translate}}*</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <p><strong>{{'detalharcondicoes'|translate}}</strong></p>
                            <textarea [(ngModel)]="model.favorDetail"
                                      name="favorDetail" id="favorDetail"
                                      class="input-material form-control mb-2" rows="5" required></textarea>
                        </div>
                    </div>
                </form>
                <div class="page-content__buttons">
                    <button class="btn btn-primary btn-new" (click)="stepBack()" data-toggle="tab">
                        {{'voltar'|translate}}
                    </button>
                    <button (click)="actionSave()" class="btn btn-warning btn-new" data-toggle="tab">
                        {{'proximo'|translate}}
                    </button>
                </div>
            </article>
            <article id="step-7" class="tab-pane fade" [ngClass]="{'show active': step == 7 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section secondary">
                        <strong>3</strong>
                        <span>{{'analiseocorrencia'|translate}}<span
                                class="is-highlight">{{'providencias'|translate}}</span></span>
                    </h2>
                </div>
                <form action="#" class="page-content__form full justify-content-center mt-0 mt-lg-2 mb-0 ml-0 mr-0">
                    <div class="row w-full">
                        <div class="col-12 col-lg-6">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <p><strong>{{'quemfoicomunicado'|translate}}</strong></p>
                                    <div class="input-material">
                                        <ng-multiselect-dropdown
                                                [placeholder]="'&nbsp;'"
                                                [settings]="dropdownSettingsUnities"
                                                [data]="dropdownListUnities"
                                                (onSelect)="onItemSelectUnities($event)"
                                                (onDeSelect)="onItemDeselectUnities($event)"
                                        >
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <p><strong>{{'resumomedidasmitigatorias'|translate}}</strong></p>
                                    <div class="input-material w-full mt-3">
                                        <textarea [(ngModel)]="model.mitigatingMeasuresDescription"
                                                  id="mitigatingMeasuresDescription"
                                                  name="mitigatingMeasuresDescription"
                                                  class="input-material form-control w-full small" rows="10"
                                                  required>{{'escrevaaqui'|translate}}</textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5 offset-lg-1">
                            <div class="page-content__upload single with-margin mt-4 text-left">
                                <p class="m-0 mb-3"><strong>{{'responsavelprovidencia'|translate}}</strong></p>
                                <div class="input-material">
                                    <input [(ngModel)]="model.measuresResponsibleName" name="measuresResponsibleName"
                                           id="measuresResponsibleName"
                                           class="form-control" type="text" required/>
                                    <label for="measuresResponsibleName">{{'nome'|translate}}*</label>
                                </div>
                                <div class="input-material">
                                    <input [(ngModel)]="model.measuresResponsibleRole" name="measuresResponsibleRole"
                                           id="measuresResponsibleRole"
                                           class="form-control" type="text" required/>
                                    <label for="measuresResponsibleRole">Cargo*</label>
                                </div>
                                <div class="input-material mb-0">
                                    <input id="dealine" class="form-control date-picker-deadline" type="text" required/>
                                    <label for="dealine">
                                        <span>{{'prazoexecucao'|translate}}*</span>
                                        <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                    <div class="page-content__details mt-0 mb-2 mb-lg-5">-->
                    <!--                        <div class="row">-->
                    <!--                            <div class="col-12 col-md-4 col-lg-2">-->
                    <!--                                <h4 class="input-head no-top text-lg-right">{{'assinatura'|translate}} <strong-->
                    <!--                                        class="d-md-block">Digital</strong>-->
                    <!--                                </h4>-->
                    <!--                            </div>-->
                    <!--                            <div class="col-12 col-md-8 col-lg-10">-->
                    <!--                                <div class="signature" (click)="actionSignature()">-->
                    <!--                                    <p>{{'desenheassinatura'|translate}}</p>-->
                    <!--                                    <div class="signature-thumbnail"-->
                    <!--                                         [ngClass]="{'d-none': isNullOrUndefined(imageSignature)}">-->
                    <!--                                        <img id="sig-image" [src]="imageSignature" alt="assinatura"/>-->
                    <!--                                    </div>-->
                    <!--                                    <textarea id="sig-dataUrl" class="form-control d-none" rows="5"></textarea>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </form>
                <div class="page-content__buttons">
                    <button class="btn btn-primary btn-new" (click)="stepBack();" data-toggle="tab">
                        {{'voltar'|translate}}
                    </button>
                    <button (click)="actionSave()" class="btn btn-warning btn-new">{{'finalizar'|translate}}
                    </button>
                </div>
            </article>
        </section>
    </div>
</div>

<!--<div class="paint-container" [ngClass]="{'is-open' : flagSignature}">-->
<!--    <div id="sketch" class="paint-box">-->
<!--        <canvas id="sig-canvas" style="object-fit: cover;"></canvas>-->
<!--        <div class="signature">-->
<!--            <p>{{'desenheassinaturadedo'|translate}}</p>-->
<!--            <button class="clear" id="sig-clearBtn">-->
<!--                <svg-icon src="assets/svg/cross.svg"></svg-icon>-->
<!--                <span>{{'limpar'|translate}}</span>-->
<!--            </button>-->
<!--        </div>-->
<!--        <div class="page-content__buttons button_back">-->
<!--            <button (click)="actionSignature()">-->
<!--                <svg-icon src="assets/svg/arrow.svg"></svg-icon>-->
<!--            </button>-->
<!--        </div>-->
<!--        <div class="page-content__buttons button_save">-->
<!--            <button class="btn btn-warning" id="sig-submitBtn">{{'salvar'|translate}}</button>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="modal fade" id="authorModal" tabindex="-1" role="dialog" aria-labelledby="authorModal" aria-hidden="true">
    <div class="modal-dialog modal-large modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{isNullOrUndefined(this.modelUserAuthor.id) ? 'Novo Autoria' : 'Editar Autoria'}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body mh-0 mb-0">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <div class="profile-type">
                            <img src="assets/images/user.svg" alt="">
                            <p>{{'dadosautoria'|translate}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9">
                        <div class="row">
                            <div class="col-12 col-md-5">
                                <div class="input-material">
                                    <input name="nameAuthor" id="nameAuthor" [(ngModel)]="modelUserAuthor.name"
                                           class="form-control"
                                           type="text" required/>
                                    <label for="nameAuthor">Nome</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input id="birthAuthor" class="form-control date-picker-birth-author" type="text" required/>
                                    <label for="birthAuthor">
                                        <span>{{'nascimento'|translate}}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="input-material select">
                                    <select name="genderAuthor" [(ngModel)]="modelUserAuthor.gender"
                                            id="genderAuthor"
                                            class="form-control" required>
                                        <option value="fem">Feminino</option>
                                        <option value="masc">Masculino</option>
                                    </select>
                                    <label for="genderAuthor">Sexo</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-5">
                                <div class="input-material">
                                    <input name="rgAuthor" id="rgAuthor" [(ngModel)]="modelUserAuthor.rg"
                                           class="form-control" type="text" required
                                           [mask]="this.translate.currentLang==='pt'? '00.000.000-0' : ''"/>
                                    <label for="rgAuthor">{{'rg'|translate}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="input-material">
                                    <input [(ngModel)]="modelUserAuthor.uf" maxlength="2" name="ufRgAuthor"
                                           id="ufRgAuthor"
                                           class="form-control"
                                           type="text" required/>
                                    <label for="ufRgAuthor">UF do {{'rg'|translate}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input [(ngModel)]="modelUserAuthor.nationality" name="nationalityAuthor"
                                           id="nationalityAuthor" class="form-control"
                                           type="text" required/>
                                    <label for="nationalityAuthor">Natural</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input [(ngModel)]="modelUserAuthor.document" name="documentAuthor"
                                           id="documentAuthor"
                                           [mask]="this.translate.currentLang==='pt'? '000.000.000-00' : ''"
                                           (blur)="onClearAuthor($event, 'document')"
                                           class="form-control" type="text" required/>
                                    <label for="documentAuthor">{{'cpf' | translate}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input [(ngModel)]="modelUserAuthor.jobRole" name="jobRoleAuthor"
                                           id="jobRoleAuthor"
                                           class="form-control" type="text" required/>
                                    <label for="jobRoleAuthor">Cargo</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input [(ngModel)]="modelUserAuthor.sector" name="sectorAuthor"
                                           id="sectorAuthor"
                                           class="form-control" type="text" required/>
                                    <label for="sectorAuthor">Setor</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="input-material">
                            <input [(ngModel)]="modelUserAuthor.cep" name="cepVictimAuthor" id="cepVictimAuthor"
                                   [mask]="'00.000-000'"
                                   class="form-control" type="text" required/>
                            <label for="cepVictimAuthor">CEP</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="input-material">
                            <input [(ngModel)]="modelUserAuthor.address" name="addressAuthor" id="addressAuthor"
                                   class="form-control"
                                   type="text" required/>
                            <label for="addressAuthor">{{'Endereco'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-2">
                        <div class="input-material">
                            <input [(ngModel)]="modelUserAuthor.addressNumber" name="addressNumberAuthor"
                                   id="addressNumberAuthor" class="form-control" type="text" required/>
                            <label for="addressNumberAuthor">Nº</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="input-material">
                            <input [(ngModel)]="modelUserAuthor.complement" name="complementAuthor"
                                   id="complementAuthor"
                                   class="form-control" type="text" required/>
                            <label for="complementAuthor">Complemento</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="input-material">
                            <input [(ngModel)]="modelUserAuthor.mother" name="mother"
                                   id="mother"
                                   class="form-control" type="text" required/>
                            <label for="mother">Mãe</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="input-material">
                            <input [(ngModel)]="modelUserAuthor.father" name="father"
                                   id="father"
                                   class="form-control" type="text" required/>
                            <label for="father">Pai</label>
                        </div>
                    </div>
                </div>
                <a href="javascript:void('modal')" (click)="addAuthor()" class="col-12 btn btn-secondary mt-3">
                    {{'salvar'|translate}}
                </a>
            </div>
        </div>
    </div>
</div>

<div id="success" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <span class="modal-body-success">
                    <svg-icon src="assets/svg/success.svg"></svg-icon>
                    <p>Ok, sua <strong>{{'ocorrencia'}}</strong> <br>{{'salvacomsucesso'|translate}}</p>
                    <button class="btn btn-block btn-warning mt-4" data-dismiss="modal">{{'fechar'|translate}}</button>
                </span>
            </div>
        </div>
    </div>
</div>

<app-crop-image [cropId]="'uploadPhoto'"
                (croppedImageResult)="setCroppedPhoto($event)"></app-crop-image>
