<div class="single__page">
    <h1 class="mb-5">
        <svg-icon src="assets/svg/logo-eurofarma.svg"></svg-icon>
    </h1>
    <p>
        {{"login-message" | translate}}
    </p>
    <p>
        <a href="javascript:void('Login');" [routerLink]="['/login']">{{"action-login" | translate}}
            <strong>{{"enter" | translate}}</strong></a>
    </p>
</div>
