import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    Bloc, BlocPage,
    Country,
    CreateBlocGQL,
    CreateBlocInput,
    GetCountriesGQL,
    GetCountriesQuery,
    GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery,
    GetUnitiesGQL,
    RemoveBlocGQL,
    SearchBlocInput,
    SearchBlocsGQL,
    SearchBlocsQuery,
    Unity,
    UpdateBlocGQL,
    UpdateBlocInput, User
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {UserService} from '../../../service/user.service';

@Component({
    selector: 'app-block',
    templateUrl: './block.component.html',
    styleUrls: ['./block.component.scss']
})
export class BlockComponent extends BaseComponent implements OnInit, AfterViewInit {
    user = new User();
    countries: Country[] = [];
    countryQuery: QueryRef<GetCountriesQuery>;

    // create
    modelCreate: Bloc = new Bloc();
    selectedCreateUnityId: string;
    selectedCreateCountryId: string;

    createUnities: Array<Unity> = [];
    createUnityQuery: QueryRef<GetUnitiesByCountryQuery>;

    // list
    filterUnityId: string;
    filterCountryId: string;

    filter: SearchBlocInput = new SearchBlocInput();
    searchBlocsQuery: QueryRef<SearchBlocsQuery>;
    result: BlocPage = new BlocPage();

    filterUnities: Array<Unity>;
    filterUnityQuery: QueryRef<GetUnitiesByCountryQuery>;

    constructor(private searchBlocsGQL: SearchBlocsGQL,
                private createBloc: CreateBlocGQL,
                private updateBloc: UpdateBlocGQL,
                private removeBloc: RemoveBlocGQL,
                private getCountriesGQL: GetCountriesGQL,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                private getUnitiesGQL: GetUnitiesGQL,
                public userService: UserService,
                public router: Router,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.getCountries();
    }

    ngAfterViewInit(): void {
        this.createUnityQuery = this.getUnitiesByCountryGQL
            .watch({countryId: this.isNullOrUndefined(this.selectedCreateCountryId) ? '' : this.selectedCreateCountryId});
        this.createUnityQuery.valueChanges.subscribe(({data}) => {
            this.createUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            const c = data.countries as Array<Country>;
            const x = JSON.stringify(c);
            this.countries = JSON.parse(x) as Array<Country>;
            this.listBlocs();
        });
    }

    listBlocs() {
        this.searchBlocsQuery = this.searchBlocsGQL.watch({input: this.filter});
        this.searchBlocsQuery.valueChanges.subscribe(({data}) => {
            this.result = data.searchBloc as BlocPage;
        });
    }

    getUnitiesFilter() {
        this.filterUnityId = null;
        this.filterUnities = [];
        if (this.isNullOrUndefined(this.filterCountryId)) {
            return;
        }
        this.filterUnityQuery = this.getUnitiesByCountryGQL.watch({countryId: this.filterCountryId});
        this.filterUnityQuery.valueChanges.subscribe(({data}) => {
            this.filterUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    onFilter() {
        this.filter.unity = this.filterUnityId;
        this.filter.country = this.filterCountryId;
        this.searchBlocsQuery.refetch({input: this.filter});
    }

    create() {
        this.modelCreate = new Bloc();
        this.selectedCreateCountryId = null;
        this.selectedCreateUnityId = null;
        this.showModal('blocModal');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.modelCreate.name, text: `Nome<br>`},
            {value: this.selectedCreateUnityId, text: `Unidade<br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelCreate.id)) {
            this.actionCreateBloc();
        } else {
            this.actionUpdateBloc();
        }
    }

    actionCreateBloc() {
        const data: CreateBlocInput = {
            name: this.modelCreate.name,
            unity: {
                id: this.selectedCreateUnityId,
            }
        };
        this.createBloc.mutate({input: data}).subscribe((result) => {
            this.modelCreate = new Bloc();
            this.searchBlocsQuery.refetch();
            this.closeModal('blocModal');
        });
        this.showMessage(this.translate.instant('success'), this.translate.instant('blococriadocomsucesso'), 'success');
        this.closeModal('blocModal');
    }

    actionEdit(bloc: Bloc) {
        Object.assign(this.modelCreate, bloc);
        this.selectedCreateUnityId = this.modelCreate.unity.id;
        this.selectedCreateCountryId = this.modelCreate.unity.country.id;
        this.createUnityQuery.refetch({countryId: this.selectedCreateCountryId});
        this.showModal('blocModal');
    }

    actionDelete(blocId) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('desejarealmenteexcluiresseBloco'), () => {
            this.removeBloc.mutate({input: blocId}).subscribe((result) => {
                this.searchBlocsQuery.refetch();
                super.showMessage(this.translate.instant('success'), this.translate.instant('unidadedeletadacomsucesso'), 'success');
            });
        });
    }

    actionUpdateBloc() {
        const data: UpdateBlocInput = {
            id: this.modelCreate.id,
            name: this.modelCreate.name,
            unity: {
                id: this.selectedCreateUnityId
            }
        };
        this.updateBloc.mutate({input: data}).subscribe(() => {
            this.modelCreate = new Bloc();
            this.searchBlocsQuery.refetch();
            this.closeModal('blocModal');
        });
        this.showMessage(this.translate.instant('success'), this.translate.instant('blocoeditadocomsucesso'), 'success');
        this.closeModal('blocModal');
    }

    getUnitiesForCreate() {
        this.selectedCreateUnityId = null;
        this.createUnities = [];
        if (this.isNullOrUndefined(this.selectedCreateCountryId)) {
            return;
        }
        this.createUnityQuery.refetch({countryId: this.selectedCreateCountryId});
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.onFilter();
    }
}
