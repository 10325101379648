import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../../../base/base.component';
import {
    GetOccurrenceByIdGQL,
    GetOccurrenceByIdQuery, Maybe,
    Occurrence,
    OccurrenceFile,
    OccurrenceUser, Scalars, User
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../../service/user.service';

declare var moment;

@Component({
    selector: 'app-occurrence-detail',
    templateUrl: './occurrence-detail.component.html',
    styleUrls: ['./occurrence-detail.component.scss']
})


export class OccurrenceDetailComponent extends BaseComponent implements OnInit {
    user: User = new User();
    model: Occurrence = new Occurrence();
    occurrenceQuery: QueryRef<GetOccurrenceByIdQuery>;
    modelVictim: OccurrenceUser = new OccurrenceUser();
    modelAuthor: OccurrenceUser = new OccurrenceUser();
    signature: OccurrenceFile = new OccurrenceFile();

    authors: OccurrenceUser[] = [];

    profile: boolean;

    constructor(public router: Router,
                private activatedRoute: ActivatedRoute,
                private getOccurrenceById: GetOccurrenceByIdGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }
        });
        this.profile = this.actionUserProfile();
        setTimeout(() => {
            this.handlerBoxSize();
        }, 500);
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.model.id = url.id;
                this.occurrenceQuery = this.getOccurrenceById.watch({id: this.model.id});
                this.occurrenceQuery.valueChanges.subscribe(({data}) => {
                    this.model = data.occurrence as Occurrence;
                    this.modelVictim = this.getVictim(this.model.users);
                    // this.modelAuthor = this.getAuthor(this.model.users);
                    this.getAuthors();
                    const sig = this.model.files.filter(p => p.media === 'signature');
                    if (!this.isNullOrUndefined(sig)) {
                        this.signature = sig[0];
                    }
                });
            }
        });
    }

    getVictim(users: Array<OccurrenceUser>): OccurrenceUser {
        return Object.assign([], users.find(a => a.type === 'pessoal' || a.type === 'legal'));
    }

    getAuthors() {
        this.model.users.forEach((e) => {
            if (e.type == 'author') {
                this.authors.push({
                    address: e.address,
                    addressNumber: e.addressNumber,
                    birth: e.birth,
                    cep: e.cep,
                    complement: e.complement,
                    document: e.document,
                    father: e.father,
                    gender: e.gender,
                    id: e.id,
                    jobRole: e.jobRole,
                    mother: e.mother,
                    name: e.name,
                    nationality: e.nationality,
                    occurrence: e.occurrence,
                    rg: e.rg,
                    sector: e.sector,
                    type: e.type,
                    uf: e.uf
                });
            }
        });
    }

    // getAuthor(users: Array<OccurrenceUser>): OccurrenceUser {
    //     return Object.assign([], users.find(a => a.type === 'author'));
    // }

    getAge(date) {
        if (this.isNullOrUndefined(date)) {
            return;
        }
        return Math.floor(moment(new Date()).diff(moment(date), 'years', true));
    }

    getDays(date) {
        if (this.isNullOrUndefined(date)) {
            return;
        }
        return Math.floor(moment(new Date()).diff(moment(date), 'days', true));
    }

    filteredFiles(files: Array<OccurrenceFile>, type: string): Array<OccurrenceFile> {
        if (!this.isNullOrUndefined(files)) {
            return files.filter(f => f.media === type);
        } else {
            return [];
        }
    }

    actionExcel() {
        const lang = this.translate.currentLang as string;
        const token = localStorage.getItem('token') as string;
        const id = this.model.id as string;
        const url = `${environment.base_url}/occurrence/pdf?id=${id}&token=${token}&language=${lang}`;
        window.open(encodeURI(url));
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return true;
            case 'user':
                return false;
            case 'vigilant-leader':
                return true;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
        }
    }

    dateHandler(s: string) {
        if (s.endsWith('00:00')) {
            return s.slice(0, 10);
        }
        return s;
    }
}
