import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {QueryRef} from 'apollo-angular';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ExcelService} from '../../../service/excel.service';
import {
    Country, CreateCheckListCftvGQL, GetCountriesGQL,
    GetCountriesQuery, GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery, RemoveCheckListCftvGQL,
    SearchCheckListCftv, SearchCheckListCftvGQL, SearchCheckListCftvQuery, SearchCheckListCftvResult,
    Unity, User
} from '../../../../generated/graphql';
import {UserService} from '../../../service/user.service';

declare var $: any;

@Component({
    selector: 'app-cftv-home',
    templateUrl: './cftv-home.component.html',
    styleUrls: ['./cftv-home.component.scss']
})
export class CftvHomeComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    user = new User();
    unityId: string;
    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesByCountryQuery>;

    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    filterUnities: Array<Unity>;
    filterCountries: Array<Country>;
    filterUnityId: string;
    filterCountryId: string;

    selectedCountryId: string;

    // novos campos
    filter: SearchCheckListCftv = new SearchCheckListCftv();

    checklistResult: SearchCheckListCftvResult = new SearchCheckListCftvResult();
    checklistQuery: QueryRef<SearchCheckListCftvQuery>;

    labelCountry: boolean;
    labelUnity: boolean;
    exportExcel: boolean;
    actionNew: boolean;
    actionUpdate: boolean;

    constructor(public router: Router,
                public translate: TranslateService,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                private getCountriesGQL: GetCountriesGQL,
                public searchChecklistGQL: SearchCheckListCftvGQL,
                public removeChecklistGQL: RemoveCheckListCftvGQL,
                public createChecklistGQL: CreateCheckListCftvGQL,
                public userService: UserService,
                public excelService: ExcelService) {
        super(router, translate);
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.actionUserProfileBTN();
        this.getCountries();
        this.getUnities();
        this.actionList();
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.setDatePicker('js-daterangepicker', (start, end, label) => {
            this.filter.start = start.utcOffset(0, true).format();
            this.filter.end = end.utcOffset(0, true).format();
        });
        if (this.actionUserProfile() === false) {
            this.filterUnityId = this.user.unity.id;
        }
    }

    actionCreate() {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('criarchecklist'), () => {
            this.createChecklistGQL.mutate().subscribe(dt => {
                this.checklistQuery.refetch();
                this.router.navigate([`cftv/create/${dt.data.createCheckListCftv.id}`]);
            });
        });
    }

    actionList() {
        if (this.actionUserProfile() === false) {
            this.filter.unityId = this.user.unity.id;
        }
        this.checklistQuery = this.searchChecklistGQL.watch({data: this.filter});
        this.checklistQuery.valueChanges.subscribe(({data}) => {
            this.checklistResult = data.searchCheckListCftv as SearchCheckListCftvResult;
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.filterCountries = data.countries as Array<Country>;
        });
    }

    getUnities() {
        this.unityQuery = this.getUnitiesByCountryGQL.watch(
            {countryId: this.isNullOrUndefined(this.selectedCountryId) ? '' : this.selectedCountryId});
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    actionFilter() {
        if (this.actionUserProfile() === false) {
            this.filterUnityId = this.user.unity.id;
        }
        this.filter.unityId = this.filterUnityId;
        this.filter.countryId = this.filterCountryId;
        this.checklistQuery.refetch({data: this.filter});
    }

    actionEdit(id: string) {
        this.router.navigate([`cftv/create/${id}`]);
    }

    actionDelete(id: string) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('excluirchecklist'), () => {
            this.removeChecklistGQL.mutate({id}).subscribe((result) => {
                super.showMessage(this.translate.instant('success'), this.translate.instant('checklistdeletada'), 'success');
                this.actionFilter();
            });
        });
    }

    getUnitiesFilter() {
        this.filterUnityId = null;
        this.unityQuery = this.getUnitiesByCountryGQL.watch(
            {countryId: this.isNullOrUndefined(this.filterCountryId) ? '' : this.filterCountryId});
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.filterUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    actionExcel() {
        this.excelService.getExcelCftvControl(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.actionFilter();
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return true;
            case 'local-operator':
                return false;
        }
    }

    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelCountry = true;
                this.labelUnity = true;
                this.exportExcel = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'local-manager':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = true;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'user':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant-leader':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant-conductor':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'lecturer':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'corporate-operator':
                this.labelCountry = true;
                this.labelUnity = true;
                this.exportExcel = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'local-operator':
                this.labelCountry = false;
                this.labelUnity = false;
                this.exportExcel = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
        }
    }
}
