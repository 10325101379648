import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class ExcelService extends ServiceBase {

    constructor(http: HttpClient, public userService: UserService) {
        super(http);
    }

    getExcelOccurrences(model) {
        const url = `${this.BASE_URL}/occurrence/search-occurrence/excel`;
        return this.http.post(url, model);
    }

    getExcelUsers(model) {
        const url = `${this.BASE_URL}/users/search-user/excel`;
        return this.http.post(url, model);
    }

    getExcelAlarms(model) {
        const url = `${this.BASE_URL}/check-list-alarm/search-check-list-alarm/excel`;
        return this.http.post(url, model);
    }

    getExcelCheckListAlarms(model) {
        const url = `${this.BASE_URL}/check-list-alarm/check-list-alarm/excel`;
        return this.http.post(url, model);
    }

    getExcelAccessControl(model) {
        const url = `${this.BASE_URL}/check-list-access/search-check-list-access/excel`;
        return this.http.post(url, model);
    }

    getExcelCheckListAccess(model) {
        const url = `${this.BASE_URL}/check-list-access/check-list-access/excel`;
        return this.http.post(url, model);
    }

    getExcelPhysicalSecurity(model) {
        const url = `${this.BASE_URL}/physical-security/search-physical-security/excel`;
        return this.http.post(url, model);
    }

    getExcelCftvControl(model) {
        const url = `${this.BASE_URL}/check-list-cftv/search-check-list-cftv/excel`;
        return this.http.post(url, model);
    }

    getExcelCheckListCftv(model) {
        const url = `${this.BASE_URL}/check-list-cftv/check-list-cftv/excel`;
        return this.http.post(url, model);
    }

    getExcelTrucks(model) {
        const url = `${this.BASE_URL}/trucks/search-trucks/excel`;
        return this.http.post(url, model);
    }

    getExcelServiceRequest(model) {
        const url = `${this.BASE_URL}/service-request/search-service-request/excel`;
        return this.http.post(url, model);
    }

    uploadOccurrence(file, urlParameter, comment) {
        const url = `${this.BASE_URL}${urlParameter}`;
        return new Observable((observer) => {
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
                formData.append('comment', comment );
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
                console.log(progress);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.userService.getToken());
            xhr.send(formData);
        });
    }

    upload(file, urlParameter) {
        const url = `${this.BASE_URL}${urlParameter}`;
        return new Observable((observer) => {
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
                console.log(progress);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.userService.getToken());
            xhr.send(formData);
        });
    }

}
