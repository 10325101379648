import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../../service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {
    Bloc,
    BlocPage,
    Country,
    CreateBlocGQL,
    CreateBlocInput,
    CreateEnumTypeGQL,
    CreateEnumTypeInput,
    EnumType,
    EnumTypePage,
    GetCountriesGQL,
    GetCountriesQuery,
    GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery,
    RemoveBlocGQL,
    RemoveEnumTypeGQL,
    SearchBlocInput,
    SearchBlocsGQL,
    SearchBlocsQuery,
    SearchEnumTypeGQL,
    SearchEnumTypeInput,
    SearchEnumTypeQuery,
    Unity,
    UpdateBlocGQL,
    UpdateBlocInput,
    UpdateEnumTypeGQL,
    UpdateEnumTypeInput,
    User
} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";

@Component({
    selector: 'app-general-config',
    templateUrl: './general-config.component.html',
    styleUrls: ['./general-config.component.scss']
})
export class GeneralConfigComponent extends BaseComponent implements OnInit {
    user = new User();
    countries: Country[] = [];
    countryQuery: QueryRef<GetCountriesQuery>;

    // create
    modelCreate: EnumType = new EnumType();
    selectedCreateValue: string;
    selectedCreateType: string;
    selectedCreateCulture: string;
    selectedCreateUnityId: string;
    selectedCreateCountryId: string;

    createUnities: Array<Unity> = [];
    createUnityQuery: QueryRef<GetUnitiesByCountryQuery>;

    // list
    filterTypeId: string;
    filterCultureId: string;
    filterUnityId: string;
    filterCountryId: string;

    filterUnities: Array<Unity>;
    filterUnityQuery: QueryRef<GetUnitiesByCountryQuery>;

    filter: SearchEnumTypeInput = new SearchEnumTypeInput();
    result: EnumTypePage = new EnumTypePage();
    searchEnumQuery: QueryRef<SearchEnumTypeQuery>;

    constructor(public router: Router,
                public userService: UserService,
                public translate: TranslateService,
                private getCountriesGQL: GetCountriesGQL,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                private createEnumType: CreateEnumTypeGQL,
                private updateEnumType: UpdateEnumTypeGQL,
                private removeEnumType: RemoveEnumTypeGQL,
                private searchEnumTypeGQL: SearchEnumTypeGQL) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.listEnumType();
        this.getCountries();
    }

    ngAfterViewInit(): void {
        this.createUnityQuery = this.getUnitiesByCountryGQL
            .watch({countryId: this.isNullOrUndefined(this.selectedCreateCountryId) ? '' : this.selectedCreateCountryId});
        this.createUnityQuery.valueChanges.subscribe(({data}) => {
            this.createUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    listEnumType() {
        this.searchEnumQuery = this.searchEnumTypeGQL.watch({input: this.filter});
        this.searchEnumQuery.valueChanges.subscribe(({data}) => {
            this.result = data.searchEnumType as EnumTypePage;
        });
    }

    getUnitiesFilter() {
        this.filterUnityId = null;
        this.filterUnities = [];
        if (this.isNullOrUndefined(this.filterCountryId)) {
            return;
        }
        this.filterUnityQuery = this.getUnitiesByCountryGQL.watch({countryId: this.filterCountryId});
        this.filterUnityQuery.valueChanges.subscribe(({data}) => {
            this.filterUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.modelCreate.type, text: `Tipo<br>`},
            {value: this.modelCreate.value, text: `Nome<br>`},
            {value: this.modelCreate.culture, text: `Cultura<br>`},
        ];

        switch (this.modelCreate.type) {
            case "localdeentrega":
                arrValidateFields.push(
                    {value: this.selectedCreateCountryId, text: 'País<br>'},
                    {value: this.selectedCreateUnityId, text: 'Unidade<br>'},
                )
                break;
        }

        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelCreate.id)) {
            this.actionCreateType();
        } else {
            this.actionUpdateType();
        }
    }

    actionCreateType() {
        const data: CreateEnumTypeInput = {
            type: this.modelCreate.type,
            value: this.modelCreate.value,
            culture: this.modelCreate.culture,
        };

        switch (this.modelCreate.type) {
            case "localdeentrega":
                Object.assign(data, {
                    unity: {
                        id: this.selectedCreateUnityId
                    }
                })
                break;
        }

        this.createEnumType.mutate({input: data}).subscribe((result) => {
            this.modelCreate = new EnumType();
            this.searchEnumQuery.refetch();
            this.closeModal('configModal');
        });
        this.showMessage(this.translate.instant('success'), this.translate.instant('enumcriadocomsucesso'), 'success');
        this.closeModal('configModal');
    }

    actionEdit(_enum: EnumType) {
        Object.assign(this.modelCreate, _enum);
        this.selectedCreateValue = this.modelCreate.value;
        this.selectedCreateType = this.modelCreate.type;
        this.selectedCreateCulture = this.modelCreate.culture;

        switch (this.modelCreate.type) {
            case "localdeentrega":
                this.selectedCreateCountryId = this.modelCreate.unity.country.id;
                this.selectedCreateUnityId = this.modelCreate.unity.id;
                this.createUnityQuery.refetch({countryId: this.selectedCreateCountryId});
                break;
        }


        this.showModal('configModal');
    }

    actionUpdateType() {
        const data: UpdateEnumTypeInput = {
            id: this.modelCreate.id,
            type: this.modelCreate.type,
            value: this.modelCreate.value,
            culture: this.modelCreate.culture,
        };

        switch (this.modelCreate.type) {
            case "localdeentrega":
                Object.assign(data, {
                    unity: {
                        id: this.selectedCreateUnityId
                    }
                })
                break;
        }

        this.updateEnumType.mutate({input: data}).subscribe(() => {
            this.modelCreate = new EnumType();
            this.searchEnumQuery.refetch();
            this.closeModal('configModal');
        });
        this.showMessage(this.translate.instant('success'), this.translate.instant('enumeditadocomsucesso'), 'success');
        this.closeModal('configModal');
    }

    actionDelete(enumId) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('desejarealmenteexcluiressaConfig'), () => {
            this.removeEnumType.mutate({input: enumId}).subscribe((result) => {
                this.searchEnumQuery.refetch();
                super.showMessage(this.translate.instant('success'), this.translate.instant('configdeletadacomsucesso'), 'success');
            });
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            const c = data.countries as Array<Country>;
            const x = JSON.stringify(c);
            this.countries = JSON.parse(x) as Array<Country>;
        });
    }

    getUnitiesForCreate() {
        this.selectedCreateUnityId = null;
        this.createUnities = [];
        if (this.isNullOrUndefined(this.selectedCreateCountryId)) {
            return;
        }
        this.createUnityQuery.refetch({countryId: this.selectedCreateCountryId});
    }

    onFilter() {
        this.filter.type = this.filterTypeId;
        this.filter.culture = this.filterCultureId;
        this.filter.unity = this.filterUnityId;
        this.filter.country = this.filterCountryId;
        this.searchEnumQuery.refetch({input: this.filter});
    }

    create() {
        this.modelCreate = new EnumType();
        this.selectedCreateCountryId = null;
        this.selectedCreateUnityId = null;
        this.showModal('configModal');
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.onFilter();
    }
}
