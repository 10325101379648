import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {

    constructor(public router: Router,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        moment.locale('pt-br');
    }

    ngAfterViewInit(): void {
        $(document).on('show.bs.modal', '.modal', function () {
            $(this).appendTo('body');
        });
    }

}
