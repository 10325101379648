import {Component, AfterViewInit, OnDestroy} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    Country, CreatePolicyCountryInput, CreatePolicyGQL, CreatePolicyInput,
    GetCountriesGQL,
    Policy, RemovePolicyGQL,
    SearchPolicyGQL,
    SearchPolicyInput,
    SearchPolicyQuery, UpdatePolicyGQL, UpdatePolicyInput, User
} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-policies-procedures-home',
    templateUrl: './policies-procedures-home.component.html',
    styleUrls: ['./policies-procedures-home.component.scss']
})
export class PoliciesProceduresHomeComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    user: User = new User();
    policies: Policy[] = [];
    policy: Policy = new Policy();
    filter: SearchPolicyInput = new SearchPolicyInput();
    countries: Country[] = [];
    searchPolicyQuery: QueryRef<SearchPolicyQuery>;
    countryId: string;

    labelCountry: boolean;
    actionNew: boolean;
    actionUpdate: boolean;

    constructor(public router: Router,
                private searchPolicyGQL: SearchPolicyGQL,
                private getCountriesGQL: GetCountriesGQL,
                private createPolicyGQL: CreatePolicyGQL,
                private updatePolicyGQL: UpdatePolicyGQL,
                private removePolicyGQL: RemovePolicyGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.actionUserProfileBTN();
        if (this.actionUserProfile() === false) {
            this.filter.country = this.user.unity.country.id;
        }
        this.getCountriesGQL.watch().valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Country[];
        }, error => super.onError(error));
        this.searchPolicyQuery = this.searchPolicyGQL.watch({data: this.filter});
        this.searchPolicyQuery.valueChanges.subscribe(({data}) => {
            this.policies = data.searchPolicy as Policy[];
        }, error => super.onError(error));
    }

    ngOnDestroy(): void {
        this.destroyModal('policiesModal');
    }

    onSearch() {
        this.searchPolicyQuery.refetch({data: this.filter});
    }

    actionDelete(id: string) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('desejaexcluiressearquivo'), () => {
            this.removePolicyGQL.mutate({id: id}).subscribe((result) => {
                super.showMessage(this.translate.instant('success'), this.translate.instant('arquivodeletado'), 'success');
                this.onSearch();
            });
        });
    }

    newPolicy() {
        this.policy = new Policy();
        this.countryId = undefined;
        this.showModal('policiesModal');
    }

    editPolicy(p: Policy) {
        this.policy = Object.assign({}, p);
        this.countryId = this.policy.country.id;
        this.showModal('policiesModal');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.policy.title, text: `Título<br>`},
            {value: this.policy.description, text: `Descrição<br>`},
            // {value: this.countryId, text: `País<br>`},
            {value: this.policy.link, text: `Link<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }
        if (this.isNullOrUndefined(this.policy.id)) {
            this.createPolicy();
        } else {
            this.updatePolicy();
        }

    }

    createPolicy() {
        if (this.actionUserProfile() === false) {
            this.countryId = this.user.unity.country.id;
        }
        const countryInput: CreatePolicyCountryInput = {
            id: this.countryId,
        }
        const input: CreatePolicyInput = {
            title: this.policy.title,
            description: this.policy.description,
            link: this.policy.link,
            country: countryInput,
            isPortuguese: this.isNullOrUndefined(this.policy.isPortuguese) ? false : this.policy.isPortuguese,
            isSpanish: this.isNullOrUndefined(this.policy.isSpanish) ? false : this.policy.isSpanish
        }
        this.createPolicyGQL.mutate({data: input}).subscribe(({data}) => {
            this.onSearch();
            this.showMessage(this.translate.instant('success'), this.translate.instant('arquivocriadocomsucesso'), 'success');
            this.closeModal('policiesModal');
        }, error => super.onError(error));
    }

    updatePolicy() {
        if (this.actionUserProfile() === false) {
            this.countryId = this.user.unity.country.id;
        }
        const countryInput: CreatePolicyCountryInput = {
            id: this.countryId,
        }
        const input: UpdatePolicyInput = {
            id: this.policy.id,
            title: this.policy.title,
            description: this.policy.description,
            link: this.policy.link,
            country: countryInput,
            isPortuguese: this.isNullOrUndefined(this.policy.isPortuguese) ? false : this.policy.isPortuguese,
            isSpanish: this.isNullOrUndefined(this.policy.isSpanish) ? false : this.policy.isSpanish
        }
        this.updatePolicyGQL.mutate({data: input}).subscribe(({data}) => {
            this.onSearch();
            this.showMessage(this.translate.instant('success'), this.translate.instant('arquivoatualizadocomsucesso'), 'success');
            this.closeModal('policiesModal');
        }, error => super.onError(error));
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                return true;
            case 'local-manager':
                return false;
            case 'user':
                return false;
            case 'vigilant-leader':
                return false;
            case 'vigilant-conductor':
                return false;
            case 'vigilant':
                return false;
            case 'lecturer':
                return false;
            case 'corporate-operator':
                return false;
            case 'local-operator':
                return false;
        }
    }

    actionUserProfileBTN() {
        switch (this.user.profile) {
            case 'admin':
                this.labelCountry = true;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'local-manager':
                this.labelCountry = false;
                this.actionNew = true;
                this.actionUpdate = true;
                break;
            case 'user':
                this.labelCountry = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant-leader':
                this.labelCountry = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant-conductor':
                this.labelCountry = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'vigilant':
                this.labelCountry = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'lecturer':
                this.labelCountry = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'corporate-operator':
                this.labelCountry = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
            case 'local-operator':
                this.labelCountry = false;
                this.actionNew = false;
                this.actionUpdate = false;
                break;
        }
    }
}
