<section class="single-page">
    <div class="btn-hero is-cursor" [routerLink]="'/settings/camera'" *ngIf="pemission.settings">
        <svg-icon src="assets/svg/config.svg"></svg-icon>
        <span>{{'gestao'|translate}}</span>
    </div>
    <div class="btn-hero2 is-cursor" [routerLink]="'/user'" *ngIf="pemission.userM">
        <svg-icon src="assets/svg/user-blue.svg"></svg-icon>
        <span>{{'usuarios'|translate}}</span>
    </div>
    <div class="hero" *ngIf="translate.currentLang === 'pt'">
        <img src="assets/images/background.png" alt="Clic"/>
    </div>
    <div class="hero" *ngIf="translate.currentLang === 'es'">
        <img src="assets/images/background_ES.png" alt="Clic"/>
    </div>

    <div class="list-featured">
        <div class="item" *ngIf="pemission.occurrence">
            <a href="javascript:void('');" [routerLink]="'/occurrence'">
                <svg-icon src="assets/svg/occurrence.svg"></svg-icon>
                <span>{{'ocorrencia'|translate}}</span>
            </a>
        </div>
        <div class="item" *ngIf="pemission.segurancaeletronica">
            <a href="javascript:void('');" [routerLink]="routerByprofile()">
                <svg-icon src="assets/svg/camera.svg"></svg-icon>
                <span>{{'segurancaeletronica'|translate}}</span>
            </a>
        </div>
        <div class="item" *ngIf="pemission.physicalSecurity">
            <a href="javascript:void('');" [routerLink]="'/physical-security'">
                <svg-icon src="assets/svg/shield.svg"></svg-icon>
                <span>{{'segurancafisica'|translate}}</span>
            </a>
        </div>
        <div class="item" *ngIf="pemission.gestaoR">
            <a href="{{isRiskManagement}}" target="_blank">
                <svg-icon src="assets/svg/management.svg"></svg-icon>
                <span>{{'gestaoriscos'|translate}}</span>
            </a>
        </div>
    </div>
    <div class="list-featured justify-content-center">
        <div class="item" *ngIf="pemission.truck">
            <a href="javascript:void('');" [routerLink]="['/truck']">
                <svg-icon src="assets/svg/board.svg"></svg-icon>
                <span>{{'caminhoes'|translate}}</span>
            </a>
        </div>
        <div class="item" *ngIf="pemission.services">
            <a href="javascript:void('');" [routerLink]="['/services']">
                <svg-icon src="assets/svg/board.svg"></svg-icon>
                <span>{{'servicos'|translate}}</span>
            </a>
        </div>
        <div class="item" *ngIf="pemission.policiesProcedures">
            <a href="javascript:void('');" [routerLink]="['/policies-procedures']">
                <svg-icon class="p-0" src="assets/svg/news.svg"></svg-icon>
                <span>{{'poloiticaeprocedimento'|translate}}</span>
            </a>
        </div>
        <div class="item" *ngIf="pemission.dashboard">
            <a href="javascript:void('');" [routerLink]="['/dashboard']">
                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                <span>Dashboard</span>
            </a>
        </div>
    </div>
</section>

<section class="home" *ngIf="false">
    <div class="home_header">
        <h2 class="page_title">CLIC | <span>Dashboard</span></h2>
        <div class="home_divider">&nbsp;</div>
    </div>
</section>


