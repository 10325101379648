<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon [svgStyle]="{'fill': '#0d2e5d'}" src="assets/svg/videocam.svg"></svg-icon>
            <h1 class="page-title">{{'segurancaeletronica'|translate}}| <span>{{'controleacesso'|translate}}</span></h1>
            <button class=" btn btn-gray-fill" (click)="actionExcel()" *ngIf="exportExcel">
                <svg-icon src="assets/svg/downloads.svg"></svg-icon>
                {{'exportarexcel'|translate}}
            </button>
            <a (click)="actionCreate()" *ngIf="actionNew" class="btn btn-secondary">
                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                {{"checklist" | translate}}
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" required [(ngModel)]="filter.responsible"
                       [maxLength]="80"/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>{{'responsible'|translate}}</span>
                </label>
            </div>
            <div class="input-material">
                <input id="start" class="form-control js-daterangepicker" type="text"
                       required/>
                <label for="start">
                    <span>{{'period'|translate}}</span>
                    <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                </label>
            </div>
            <div class="input-material select"  *ngIf="labelCountry">
                <select name="country" id="country" (change)="getUnitiesFilter()"
                        [(ngModel)]="filterCountryId"
                        class="form-control" required>
                    <option [ngValue]="null">{{'all'|translate}}</option>
                    <option *ngFor="let c of filterCountries" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="country">{{'country'|translate}}</label>
            </div>
            <div class="input-material select"  *ngIf="labelUnity">
                <select name="unity" id="unity" [(ngModel)]="filterUnityId"
                        class="form-control" required>
                    <option [ngValue]="null">{{'selectcountry'|translate}}</option>
                    <option *ngFor="let c of filterUnities" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="unity">{{'unit'|translate}}</label>
            </div>
            <a href="javascript:void(0)" (click)="actionFilter()"
               class="btn btn-primary btn-lg text-white flex-grow-1"
               type="button">
                <span>{{'settings.dosearch'|translate}}</span>
            </a>
        </div>

        <div class="table-responsive">
            <table class="table table_regular custom-table-width" >
                <thead>
                <tr>
                    <th scope="col">{{'date'|translate}}</th>
                    <th scope="col">{{'country'|translate}}</th>
                    <th scope="col">{{'responsible'|translate}}</th>
                    <th scope="col">{{'unit'|translate}}</th>
                    <th class="text-center" scope="col">{{'total'|translate}}</th>
                    <th class="text-center" scope="col">{{'damaged'|translate}}</th>
                    <th class="text-center" scope="col">{{'late'|translate}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(checklistResult?.result)">
                    <td colspan="8" class="text-center">{{'noresults'|translate}}</td>
                </tr>
                <tr *ngFor="let x of checklistResult.result |
                paginate: {itemsPerPage: checklistResult.PageSize,
                currentPage: checklistResult.currentPage,
                totalItems: checklistResult.totalCount }">
                    <td>{{x.date | date: 'dd/MM/yyyy'}}</td>
                    <td>
                        <img *ngIf="!isNullOrUndefined(x.unity?.country?.name)"
                             [src]="'assets/images/country/' + x.unity?.country?.flag + '.svg'"
                             alt="img"
                             width="30px"/>
                    </td>
                    <td>{{x.createdBy.name}}</td>
                    <td>{{x.unity?.name}}</td>
                    <td class="text-center">{{x.total}}</td>
                    <td class="text-center">{{x.damage}}</td>
                    <td class="text-center">{{x.late}}</td>
                    <td class="text-center">
                        <div class="table_action">
                            <a *ngIf="x.status === 'done'"[routerLink]="['detail/' + x.id]">
                                <svg-icon src="assets/svg/eyes.svg" ></svg-icon>
                            </a>
                        </div>
                        <div class="table_action"  *ngIf="actionUpdate">
                            <a *ngIf="x.status === 'pending'" [routerLink]="['create/' + x.id]">
                                <svg-icon  src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <div *ngIf="x.status === 'pending'" (click)="actionDelete(x.id)">
                                <svg-icon  src="assets/svg/close-red.svg" ></svg-icon>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
