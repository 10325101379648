import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {CheckListAccessComment} from "../../../../generated/graphql";

declare var $: any;

@Component({
    selector: 'app-modal-comment',
    templateUrl: './modal-comment.component.html',
    styleUrls: ['./modal-comment.component.scss']
})
export class ModalCommentComponent implements OnInit, OnDestroy {
    @Input()
    commentModel: CheckListAccessComment = new CheckListAccessComment();
    @Output() returnComment = new EventEmitter<CheckListAccessComment>();

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        const el = $('#commentModal');
        if (el[0]) {
            el.remove();
        }
    }

    actionAddComment() {
        this.returnComment.emit(this.commentModel);
    }


}
