<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon src="assets/svg/shield-blue.svg"></svg-icon>
            <h1 class="page-title">{{'segurancafisica'|translate}}</h1>

                <a class="btn btn-gray-fill" (click)="actionExcel()" *ngIf="exportExcel">
                    <svg-icon class="right" src="assets/svg/downloads.svg">

                    </svg-icon>{{'physicalsecurity.exportexcel'|translate}}
                </a>
                    <a routerLink="/physical-security/create" class="btn btn-secondary" *ngIf="actionNew">
                    <svg-icon  class="mr-1" src="assets/svg/plus-circle.svg"></svg-icon>{{'physicalsecurity.newcompany'|translate}}</a>


        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'busca'|translate}}</span>
                </label>
            </div>
            <div class="input-material">
                <input id="date" class="form-control js-picker-period" type="text" required/>
                <label for="date">
                    <svg-icon class="right ml-2" src="assets/svg/calendar.svg"></svg-icon>
                    <span>{{'timecourse'|translate}}</span>
                </label>
            </div>
            <a (click)="actionFilter()" class="btn btn-primary btn-lg text-white offset-xl-3">{{'settings.dosearch'|translate}}</a>
        </div>

        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col">{{'physicalsecurity.code'|translate}}</th>
                    <th scope="col">{{'empresa'|translate}}</th>
                    <th class="text-center" scope="col">{{'physicalsecurity.numberemployees'|translate}}</th>
                    <th class="text-center" scope="col">{{"settings.status" | translate}}</th>
                    <th class="text-center" scope="col">{{'physicalsecurity.registrationdate'|translate}}</th>
                    <th class="w-10" scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(security.physicalSecurity)">
                    <td colspan="6">
                        <p class="text-center my-5">{{'physicalsecurity.nofound'|translate}}</p>
                    </td>
                </tr>
                <tr *ngFor="let l of security.physicalSecurity |
                paginate: {itemsPerPage: security.PageSize,
                currentPage: security.currentPage,
                totalItems: security.totalCount}">
                    <td>{{l.code}}</td>
                    <td>{{l.name}}</td>
                    <td class="text-center">{{l.members.length}}</td>
                    <td>
                        <ng-container *ngIf="l.companyStatus">
                            <p class="status_active bg-info">{{'active'|translate}}</p>
                        </ng-container>
                        <ng-container *ngIf="!l.companyStatus">
                            <p class="status_active bg-danger">{{'inactive'|translate}}</p>
                        </ng-container>
                    </td>
                    <td class="text-center">{{l.createdAt | date:'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <div class="table_action">
                            <svg-icon [routerLink]="'/physical-security/detail/'+ l.id"
                                      src="assets/svg/eye.svg"></svg-icon>
                        </div>
                        <div class="table_action" *ngIf="actionUpdate">
                            <svg-icon [routerLink]="'/physical-security/create/'+ l.id"
                                      src="assets/svg/edit.svg"></svg-icon>
                            <svg-icon (click)="actionDelete(l.id)"
                                      src="assets/svg/close-red.svg"></svg-icon>
                        </div>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
