import {AfterViewInit, Component, OnInit} from '@angular/core';
import {
    Country,
    CreateOccurrenceGQL,
    CreateOccurrenceInput,
    GetCountriesGQL,
    GetCountriesQuery,
    GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery,
    Occurrence,
    Unity,
    UpdateOccurrenceGQL,
    UpdateOccurrenceInput,
    GetOccurrenceByIdGQL,
    GetOccurrenceByIdQuery,
    OccurrenceUser,
    UpdateOccurrenceUserInput,
    OccurrenceFile,
    RemoveOccurrenceGQL,
    RemoveOccurrenceFileGQL,
    UpdateOccurrenceUserResponsibleCommunicatedInput,
    OccurrenceColaborator,
    UpdateOccurrenceColaboratorsInput,
    User,
    GetEnumByCultureGQL,
    EnumType,
    GetEnumByCultureQuery,
    GetEnumByTypeAndCultureGQL,
    GetEnumByTypeAndCultureQuery,
    InputMaybe, Scalars, UpdateOccurrenceUserOccurrenceInput
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ExcelService} from '../../../service/excel.service';
import {UserService} from "../../../service/user.service";
import {ocuurencceFiles} from "../model/ocuurencce-files";

declare var moment;
declare var $;
declare var window;
declare var requestAnimFrame;

@Component({
    selector: 'app-occurrence-new',
    templateUrl: './occurrence-new.component.html',
    styleUrls: ['./occurrence-new.component.scss']
})
export class OccurrenceNewComponent extends BaseComponent implements OnInit, AfterViewInit {
    user: User = new User();
    step: number;
    clickedStepBack = false;
    selectedCountryId: string;
    selectedUnityId: string;
    uploadTypeSelected = '';

    pictures: Array<ocuurencceFiles> = [];
    sounds: Array<ocuurencceFiles> = [];
    videos: Array<ocuurencceFiles> = [];

    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    favors: Array<EnumType>
    favorQuery: QueryRef<GetEnumByTypeAndCultureQuery>;

    victims: Array<EnumType>
    victimsQuery: QueryRef<GetEnumByTypeAndCultureQuery>;

    typeOccurrence: Array<EnumType>
    typeOccurrenceQuery: QueryRef<GetEnumByTypeAndCultureQuery>;

    author: Array<EnumType>;
    authors: OccurrenceUser[] = [];
    authorQuery: QueryRef<GetEnumByTypeAndCultureQuery>;

    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesByCountryQuery>;

    model: Occurrence = new Occurrence();
    collaborator: OccurrenceColaborator = new OccurrenceColaborator();
    collaborators: OccurrenceColaborator[] = [];
    occurrenceQuery: QueryRef<GetOccurrenceByIdQuery>;

    modelUserVictim: OccurrenceUser = new OccurrenceUser();

    modelUserAuthor: OccurrenceUser = new OccurrenceUser();

    flagSignature = false;
    // imageSignature = undefined;
    // imageSignatureFile: File;

    dropdownListUnities = [];
    selectedItemsUnities = [];
    dropdownSettingsUnities = {};
    responsiblesCommunicated = [];

    profile: boolean = false;
    canvas: any;
    ctx: any;

    fileComment: string;
    fileComments: string[] = [];

    pictureFiles: ocuurencceFiles[] = [];

    constructor(private createOccurrence: CreateOccurrenceGQL,
                private activatedRoute: ActivatedRoute,
                private updateOccurrence: UpdateOccurrenceGQL,
                private getCountriesGQL: GetCountriesGQL,
                private getOccurrenceById: GetOccurrenceByIdGQL,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                private getEnumByTypeAndCulture: GetEnumByTypeAndCultureGQL,
                public router: Router,
                private removeOccurrenceFile: RemoveOccurrenceFileGQL,
                public excelService: ExcelService,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.profile = this.handlerProfile();
                if (this.profile) {
                    this.selectedCountryId = this.user.unity.country.id;
                    this.selectedUnityId = this.user.unity.id;
                    this.getUnities();
                }
            }
        });
        this.dropdownSettingsUnities = {
            idField: 'id',
            textField: 'name',
            enableCheckAll: false,
            allowSearchFilter: true
        };
        this.dropdownListUnities = [
            {id: 'security_head', name: this.translate.instant('gestordeseguranca')},
            {id: 'security_manager', name: this.translate.instant('gerenciacorporativa')},
            {id: 'adm_manager', name: this.translate.instant('gerenciaadmin')},
            {id: 'executive_manager', name: this.translate.instant('gerenciaexecutiva')},
            {id: 'military_police', name: this.translate.instant('policiamilitar')},
            {id: 'civil_police', name: this.translate.instant('policiacivil')},
            {id: 'fireman', name: this.translate.instant('bombeiros')},
            {id: 'surveillance_team', name: this.translate.instant('surveillance_team')},
            {id: 'compliance', name: this.translate.instant('compliance')},
            {id: 'others', name: this.translate.instant('outros')}
        ];

        this.model.date = moment().endOf('day').utcOffset(0, true).format();
        this.getCountries();
        this.unityQuery = this.getUnitiesByCountryGQL.watch({countryId: this.isNullOrUndefined(this.selectedCountryId) ? '' : this.selectedCountryId});
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.getUnitiesByCountry as Array<Unity>;
        });
        this.getFavors();
        // this.getVictims();
        // this.getTypeOccurrences();
        // this.getAuthors();
    }

    ngAfterViewInit(): void {
        this.activatedRoute.params.subscribe(url => {
            if (!this.isNullOrUndefined(url?.id)) {
                this.model.id = url.id;
                this.occurrenceQuery = this.getOccurrenceById.watch({id: this.model.id});
                this.occurrenceQuery.valueChanges.subscribe(({data}) => {
                    this.updateModel(data);
                });
            } else {
                this.step = 1;
                this.enableDatePickers();
            }
            // $(() => {
            //     this.canvas = document.getElementById('sig-canvas');
            //     this.ctx = this.canvas.getContext('2d');
            //     // this.handlerPaint();
            //     $(window).resize(() => {
            //         let W = this.canvas.offsetWidth, H = this.canvas.offsetHeight;
            //         let temp = this.ctx.getImageData(0, 0, W, H)
            //         this.ctx.canvas.width = parseInt(document.getElementById('sketch').offsetWidth.toString(), 0);
            //         this.ctx.canvas.height = parseInt(document.getElementById('sketch').offsetHeight.toString(), 0);
            //         this.ctx.putImageData(temp, 0, 0)
            //     })
            // });
        });
    }

    updateModel(data) {
        Object.assign(this.model, data.occurrence as Occurrence);
        if (!this.isNullOrUndefined(data.occurrence) && !this.isNullOrUndefined(data.occurrence.colaborators)) {
            this.collaborators = Object.assign([], data.occurrence.colaborators as OccurrenceColaborator[]);
        }

        if (this.clickedStepBack) {
            this.step = this.step + 1;
            if (this.step === this.model.step) {
                this.clickedStepBack = false;
            }
        } else {
            if (this.isNullOrUndefined(this.model?.step)) {
                this.step = 1;
            } else {
                this.step = this.model.step;
            }
        }
        this.modelUserVictim = this.getVictim(this.model.users);
        // this.modelUserAuthor = this.getAuthor(this.model.users);

        this.authors = this.getAuthors(this.model.users);

        if (this.model.step === 2) {
            this.modelUserVictim.type = 'pessoal';
        }
        this.enableDatePickers();
    }

    getUnities() {
        this.unityQuery.refetch({countryId: this.isNullOrUndefined(this.selectedCountryId) ? '' : this.selectedCountryId});
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    getCulture() {
        let culture;
        switch (this.translate.currentLang) {
            case 'pt':
                culture = 'pt-BR';
                break;
            case 'es':
                culture = 'es-ES';
                break;
            default:
                culture = 'pt-BR';
        }
        return culture;
    }

    getFavors() {
        this.favorQuery = this.getEnumByTypeAndCulture.watch({
            type: 'facilitadoresocorrencia',
            culture: this.getCulture()
        });
        this.favorQuery.valueChanges.subscribe(({data}) => {
            this.favors = data.enumByTypeCulture as Array<EnumType>;
        });
    }

    // getVictims() {
    //     this.victimsQuery = this.getEnumByTypeAndCulture.watch({type: 'vitimas', culture: this.getCulture()});
    //     this.victimsQuery.valueChanges.subscribe(({data}) => {
    //         this.victims = data.enumByTypeCulture as Array<EnumType>;
    //     });
    // }
    //
    // getTypeOccurrences() {
    //     this.typeOccurrenceQuery = this.getEnumByTypeAndCulture.watch({type: 'tipoocorrencia', culture: this.getCulture()});
    //     this.typeOccurrenceQuery.valueChanges.subscribe(({data}) => {
    //         this.typeOccurrence = data.enumByTypeCulture as Array<EnumType>;
    //     });
    // }
    //
    // getAuthors() {
    //     this.authorQuery = this.getEnumByTypeAndCulture.watch({type: 'author', culture: this.getCulture()});
    //     this.authorQuery.valueChanges.subscribe(({data}) => {
    //         this.author = data.enumByTypeCulture as Array<EnumType>;
    //     });
    // }

    onSelectCountry(id: string) {
        if (this.profile) {
            return;
        }
        this.selectedCountryId = id;
        this.selectedUnityId = undefined;
        this.getUnities();
    }

    actionSave() {
        let arrValidateFields = [];

        if (this.step === 1) {
            arrValidateFields = [
                {value: this.selectedUnityId, text: `Unidade <br>`},
                {value: this.model.area, text: `Área<br>`},
                {value: this.model.areaResponsible, text: `Responsável<br>`},
                {value: this.model.date, text: `Data<br>`},
            ];
        }
        if (this.step === 2) {
            if (this.modelUserVictim.type === 'pessoal') {
                arrValidateFields = [
                    {value: this.modelUserVictim.name, text: `Nome da vítima <br>`},
                    {value: this.modelUserVictim.address, text: `Endereço<br>`},
                    {value: this.modelUserVictim.birth, text: `Nascimento<br>`},
                    {value: this.modelUserVictim.gender, text: `Gênero<br>`},
                    {value: this.modelUserVictim.rg, text: `RG<br>`},
                    {value: this.modelUserVictim.uf, text: `Uf do RG<br>`},
                    {value: this.modelUserVictim.nationality, text: `Natural<br>`},
                    {value: this.modelUserVictim.document, text: `ID<br>`},
                    {value: this.modelUserVictim.jobRole, text: `Cargo<br>`},
                    {value: this.modelUserVictim.sector, text: `Setor<br>`},
                    {value: this.modelUserVictim.cep, text: `CEP<br>`},
                    {value: this.modelUserVictim.address, text: `Endereço<br>`},
                    {value: this.modelUserVictim.addressNumber, text: `Nº<br>`},
                    {value: this.model.date, text: `Data da Ocorrência<br>`},
                    {value: this.model.type, text: `Tipo da Ocorrência<br>`},
                ];
            } else if (this.modelUserVictim.type === 'legal') {
                this.modelUserVictim.birth = undefined;
                this.modelUserVictim.gender = undefined;
                this.modelUserVictim.rg = undefined;
                this.modelUserVictim.uf = undefined;
                this.modelUserVictim.nationality = undefined;
                this.modelUserVictim.jobRole = undefined;
                this.modelUserVictim.sector = undefined;

                arrValidateFields = [
                    {value: this.modelUserVictim.name, text: `Nome da vítima <br>`},
                    {value: this.modelUserVictim.address, text: `Endereço<br>`},
                    {value: this.modelUserVictim.document, text: `CNPJ<br>`},
                    {value: this.modelUserVictim.cep, text: `CEP<br>`},
                    {value: this.modelUserVictim.address, text: `Endereço<br>`},
                    {value: this.modelUserVictim.addressNumber, text: `Nº<br>`},
                    {value: this.model.date, text: `Data da Ocorrência<br>`},
                    {value: this.model.type, text: `Tipo da Ocorrência<br>`},
                ];
            }
        } else if (this.step === 3) {
            if (this.model.authorship === 'known' && this.authors.length == 0) {
                arrValidateFields = [
                    {value: this.modelUserAuthor.name, text: `[AUTORIA] Nome do autor <br>`},
                    {value: this.modelUserAuthor.address, text: `[AUTORIA] Endereço<br>`},
                    {value: this.modelUserAuthor.document, text: `[AUTORIA] ID<br>`},
                    {value: this.modelUserAuthor.cep, text: `[AUTORIA] CEP<br>`},
                    {value: this.modelUserAuthor.address, text: `[AUTORIA] Endereço<br>`},
                    {value: this.modelUserAuthor.addressNumber, text: `[AUTORIA] Nº<br>`},
                    {value: this.model.authorship, text: `Autoria <br>`},
                    {value: this.model.employeesInvolved, text: `Colaborador(es) envolvido(s)<br>`},
                    {value: this.model.violence, text: `Violência<br>`},
                    {value: this.model.author, text: `Autor <br>`},
                ];
            } else {
                arrValidateFields = [
                    {value: this.model.authorship, text: `Autoria <br>`},
                    {value: this.model.employeesInvolved, text: `Colaborador(es) envolvido(s)<br>`},
                    {value: this.model.violence, text: `Violência<br>`},
                    // {value: this.model.author, text: `Autor<br>`},
                ];
                if (this.model.employeesInvolved === 'yes') {
                    arrValidateFields.push({value: this.collaborators, text: `Ao menos um Colaborador<br>`})
                }
            }
        } else if (this.step === 4) {
            arrValidateFields = [
                {value: this.model.occurrenceDetail, text: `Detalhe da ocorrência <br>`},
            ];
        } else if (this.step === 5) {
            arrValidateFields = [];
        } else if (this.step === 6) {
            arrValidateFields = [
                {value: this.model.favor, text: `Favoreceram a ocorrência <br>`},
            ];

            if (this.model.favor === 'others') {
                arrValidateFields.push({
                    value: this.model.favorDetail,
                    text: `Detalhar fatores que favoreceram a ocorrência <br>`
                });
            }
        } else if (this.step === 7) {
            arrValidateFields = [
                {value: this.model.responsiblesCommunicated, text: `Quem foi comunicado? <br>`},
                {value: this.model.mitigatingMeasuresDescription, text: `Resumo das medidas <br>`},
                {value: this.model.measuresResponsibleName, text: `Nome do Responsável pelas providências <br>`},
                {value: this.model.measuresResponsibleRole, text: `Cargo do Responsável pelas providências <br>`},
                {value: this.model.mitigatingDeadLine, text: `Prazo para execução das providências <br>`},
                // {value: this.imageSignature, text: `Assinatura Digital <br>`},
            ];

        }

        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreatedOccurrence();
        } else {
            this.actionUpdateOccurrence();
        }
    }

    actionCreatedOccurrence() {
        const data: CreateOccurrenceInput = {
            area: this.model.area,
            areaResponsible: this.model.areaResponsible,
            bulletinNumber: this.model.bulletinNumber,
            unity: {
                id: this.selectedUnityId
            }
        };
        this.createOccurrence.mutate({input: data}).subscribe((result) => {
            this.router.navigate(['/occurrence/new/' + result.data.createOccurrence.id]);
        });
    }

    actionUpdateOccurrence() {
        const responsible: UpdateOccurrenceUserResponsibleCommunicatedInput[] = [];
        this.model.responsiblesCommunicated.forEach((e) => {
            responsible.push({type: e.type});
        });
        const colaboratorsInput: UpdateOccurrenceColaboratorsInput[] = [];
        this.collaborators.forEach((e) => {
            colaboratorsInput.push({name: e.name, re: e.re});
        });
        const data: UpdateOccurrenceInput = {
            abscent: this.model.abscent,
            date: this.model.date,
            area: this.model.area,
            areaResponsible: this.model.areaResponsible,
            author: this.model.author,
            authorship: this.model.authorship,
            bulletinNumber: this.model.bulletinNumber,
            classification: this.model.classification,
            company: this.model.company,
            department: this.model.department,
            directLoss: this.model.directLoss,
            directLossType: this.model.directLossType,
            directLossDescription: this.model.directLossDescription,
            directLossObjects: this.model.directLossObjects,
            directLossValue: this.model.directLossValue,
            retrievedValue: this.model.retrievedValue,
            employeesInvolved: this.model.employeesInvolved,
            favor: this.model.favor,
            recoveryStatusDescription: this.model.recoveryStatusDescription,
            favorDetail: this.model.favorDetail,
            hour: this.model.hour,
            id: this.model.id,
            indirectLoss: this.model.indirectLoss,
            indirectLossActivity: this.model.indirectLossActivity,
            indirectLossCost: this.model.indirectLossCost,
            indirectLossInterruption: this.model.indirectLossInterruption,
            indirectLossRemoval: this.model.indirectLossRemoval,
            indirectLossTime: this.model.indirectLossTime,
            mitigatingMeasuresDescription: this.model.mitigatingMeasuresDescription,
            mitigatingDeadLine: this.model.mitigatingDeadLine,
            occurrenceDetail: this.model.occurrenceDetail,
            place: this.model.place,
            recoveryStatus: this.model.recoveryStatus,
            recurrent: this.model.recurrent,
            responsible: this.model.responsible,
            responsibleCommunicated: responsible,
            measuresResponsibleName: this.model.measuresResponsibleName,
            measuresResponsibleRole: this.model.measuresResponsibleRole,
            sinister: this.model.sinister,
            type: this.model.type,
            typeDetail: this.model.typeDetail,
            unity: {
                id: this.model.unity.id
            },
            victims: this.model.victims,
            victimsDetail: this.model.victimsDetail,
            violence: this.model.violence,
            step: this.step + 1,
            colaborator: this.model.employeesInvolved === 'yes' ? colaboratorsInput : []
        };

        const usersInput: Array<UpdateOccurrenceUserInput> = [];

        //if (this.step === 2) {
            const dataUser: UpdateOccurrenceUserInput = {
                address: this.modelUserVictim.address,
                addressNumber: this.modelUserVictim.addressNumber,
                birth: this.modelUserVictim.birth,
                cep: this.modelUserVictim.cep,
                complement: this.modelUserVictim.complement,
                document: this.modelUserVictim.document,
                gender: this.modelUserVictim.gender,
                id: this.modelUserVictim.id,
                jobRole: this.modelUserVictim.jobRole,
                name: this.modelUserVictim.name,
                nationality: this.modelUserVictim.nationality,
                occurrence: this.modelUserVictim.occurrence,
                rg: this.modelUserVictim.rg,
                sector: this.modelUserVictim.sector,
                type: this.modelUserVictim.type,
                uf: this.modelUserVictim.uf
            };
            usersInput.push(dataUser);
        //}

        //if (this.step === 3 && this.model.authorship === 'known') {
            // const dataUserAuthor: UpdateOccurrenceUserInput = {
            //     address: this.modelUserAuthor.address,
            //     addressNumber: this.modelUserAuthor.addressNumber,
            //     birth: this.modelUserAuthor.birth,
            //     cep: this.modelUserAuthor.cep,
            //     complement: this.modelUserAuthor.complement,
            //     document: this.modelUserAuthor.document,
            //     gender: this.modelUserAuthor.gender,
            //     id: this.modelUserAuthor.id,
            //     jobRole: this.modelUserAuthor.jobRole,
            //     name: this.modelUserAuthor.name,
            //     nationality: this.modelUserAuthor.nationality,
            //     occurrence: this.modelUserAuthor.occurrence,
            //     rg: this.modelUserAuthor.rg,
            //     mother: this.modelUserAuthor.mother,
            //     father: this.modelUserAuthor.father,
            //     sector: this.modelUserAuthor.sector,
            //     type: 'author',
            //     uf: this.modelUserAuthor.uf
            // };

            this.authors.forEach((e) => {
                usersInput.push({
                    address: e.address,
                    addressNumber: e.addressNumber,
                    birth: e.birth,
                    cep: e.cep,
                    complement: e.complement,
                    document: e.document,
                    father: e.father,
                    gender: e.gender,
                    id: e.id,
                    jobRole: e.jobRole,
                    mother: e.mother,
                    name: e.name,
                    nationality: e.nationality,
                    occurrence: e.occurrence,
                    rg: e.rg,
                    sector: e.sector,
                    type: 'author',
                    uf: e.uf
                });
            });

        //}
        if (this.step === 7) {
            this.updateOccurrence.mutate({input: data, users: usersInput}).subscribe((result) => {
                this.router.navigate(['occurrence/detail/' + this.model.id]);
            });
        } else {
            this.updateOccurrence.mutate({input: data, users: usersInput}).subscribe((result) => {
                this.model = result.data.updateOccurrence as Occurrence;
                this.updateModel(data);
            });
        }

    }

    getVictim(users: Array<OccurrenceUser>): OccurrenceUser {
        return Object.assign([], users.find(a => a.type === 'pessoal' || a.type === 'legal'));
    }

    getAuthor(users: Array<OccurrenceUser>): OccurrenceUser {
        return Object.assign([], users.find(a => a.type === 'author'));
    }

    getAuthors(users: Array<OccurrenceUser>): OccurrenceUser[] {
        return Object.assign([], users.filter(a => a.type === 'author'));
    }

    enableDatePickers() {
        $(() => {
            switch (this.step) {
                case 2:
                    if (this.isNullOrUndefined(this.model?.date)) {
                        this.model.date = Date.now();
                    }
                    if (this.isNullOrUndefined(this.modelUserVictim.birth)) {
                        this.modelUserVictim.birth = Date.now();
                    }
                    this.setDatePicker('date-picker',
                        (start, end, label) => {
                            this.model.date = start.utcOffset(0, true).format();
                        }, this.model.date, true);
                    this.setDatePicker('date-picker-birth', (start, end, label) => {
                        this.modelUserVictim.birth = start.utcOffset(0, true).format();
                    }, this.modelUserVictim.birth, true);
                    break;
                case 3:
                    if (this.isNullOrUndefined(this.modelUserAuthor.birth)) {
                        this.modelUserAuthor.birth = Date.now();
                    }
                    this.setDatePicker('date-picker-birth-author', (start, end, label) => {
                        this.modelUserAuthor.birth = start.utcOffset(0, true).format();
                    }, this.modelUserAuthor.birth, true);
                    break;
                case 7:
                    if (this.isNullOrUndefined(this.model.mitigatingDeadLine)) {
                        this.model.mitigatingDeadLine = Date.now();
                    }
                    this.setDatePicker('date-picker-deadline', (start, end, label) => {
                        this.model.mitigatingDeadLine = start.utcOffset(0, true).format();
                    }, this.model.mitigatingDeadLine, true);
            }
        });
    }

    // handlerPaint() {
    //     $(() => {
    //         const st = this;
    //         this.canvas.width = parseInt(document.getElementById('sketch').offsetWidth.toString(), 0);
    //         this.canvas.height = parseInt(document.getElementById('sketch').offsetHeight.toString(), 0);
    //         window.requestAnimFrame = (function (callback) {
    //             return window.requestAnimationFrame ||
    //                 window.webkitRequestAnimationFrame ||
    //                 window.mozRequestAnimationFrame ||
    //                 window.oRequestAnimationFrame ||
    //                 window.msRequestAnimaitonFrame ||
    //                 function (callback) {
    //                     window.setTimeout(callback, st.canvas.width / st.canvas.height);
    //                 };
    //         })();
    //
    //         this.ctx.strokeStyle = '#222222';
    //         this.ctx.lineWith = 2;
    //
    //         // Set up the UI
    //         const sigText: any = document.getElementById('sig-dataUrl');
    //         const sigImage: any = document.getElementById('sig-image');
    //         const clearBtn: any = document.getElementById('sig-clearBtn');
    //         const submitBtn: any = document.getElementById('sig-submitBtn');
    //         clearBtn.addEventListener('click', function (e) {
    //             clearCanvas();
    //             // sigText.innerHTML = 'Data URL for your signature will go here!';
    //             // sigImage.setAttribute('src', '');
    //         }, false);
    //         submitBtn.addEventListener('click', function (e) {
    //             const dataUrl = st.canvas.toDataURL('image/png');
    //             const blobBin = atob(dataUrl.split(',')[1]);
    //             const array = [];
    //             for (let i = 0; i < blobBin.length; i++) {
    //                 array.push(blobBin.charCodeAt(i));
    //             }
    //             st.imageSignatureFile = new File([new Uint8Array(array)], 'signature.png', {
    //                 type: 'image/png',
    //                 lastModified: new Date().getTime()
    //             });
    //             st.imageSignature = dataUrl;
    //             st.actionSignature();
    //         }, false);
    //         // Set up mouse events for drawing
    //         let drawing = false;
    //         let mousePos = {x: 0, y: 0};
    //         let lastPos = mousePos;
    //         this.canvas.addEventListener('mousedown', function (e) {
    //             // st.flagSignature = true; // FLAG ASSINATURA PREENCHIDA
    //             drawing = true;
    //             lastPos = getMousePos(st.canvas, e);
    //         }, false);
    //         this.canvas.addEventListener('mouseup', function (e) {
    //             drawing = false;
    //         }, false);
    //         this.canvas.addEventListener('mousemove', function (e) {
    //             mousePos = getMousePos(st.canvas, e);
    //         }, false);
    //         // Set up touch events for mobile, etc
    //         this.canvas.addEventListener('touchstart', function (e) {
    //             mousePos = getTouchPos(st.canvas, e);
    //             const touch: any = e.touches[0];
    //             const mouseEvent: any = new MouseEvent('mousedown', {
    //                 clientX: touch.clientX,
    //                 clientY: touch.clientY
    //             });
    //             st.canvas.dispatchEvent(mouseEvent);
    //         }, false);
    //         this.canvas.addEventListener('touchend', function (e) {
    //             const mouseEvent: any = new MouseEvent('mouseup', {});
    //             st.canvas.dispatchEvent(mouseEvent);
    //         }, false);
    //         this.canvas.addEventListener('touchmove', function (e) {
    //             const touch: any = e.touches[0];
    //             const mouseEvent: any = new MouseEvent('mousemove', {
    //                 clientX: touch.clientX,
    //                 clientY: touch.clientY
    //             });
    //             st.canvas.dispatchEvent(mouseEvent);
    //         }, false);
    //         // Prevent scrolling when touching the canvas
    //         document.body.addEventListener('touchstart', function (e) {
    //             if (e.target === st.canvas) {
    //                 e.preventDefault();
    //             }
    //         }, false);
    //         document.body.addEventListener('touchend', function (e) {
    //             if (e.target === st.canvas) {
    //                 e.preventDefault();
    //             }
    //         }, false);
    //         document.body.addEventListener('touchmove', function (e) {
    //             if (e.target === st.canvas) {
    //                 e.preventDefault();
    //             }
    //         }, false);
    //
    //         // Get the position of the mouse relative to the canvas
    //         function getMousePos(canvasDom, mouseEvent) {
    //             const rect = canvasDom.getBoundingClientRect();
    //             return {
    //                 x: mouseEvent.clientX - rect.left,
    //                 y: mouseEvent.clientY - rect.top
    //             };
    //         }
    //
    //         // Get the position of a touch relative to the canvas
    //         function getTouchPos(canvasDom, touchEvent) {
    //             const rect = canvasDom.getBoundingClientRect();
    //             return {
    //                 x: touchEvent.touches[0].clientX - rect.left,
    //                 y: touchEvent.touches[0].clientY - rect.top
    //             };
    //         }
    //
    //         // Draw to the canvas
    //         function renderCanvas() {
    //             if (drawing) {
    //                 st.ctx.moveTo(lastPos.x, lastPos.y);
    //                 st.ctx.lineTo(mousePos.x, mousePos.y);
    //                 st.ctx.stroke();
    //                 lastPos = mousePos;
    //             }
    //         }
    //
    //         function clearCanvas() {
    //             st.canvas.width = st.canvas.width;
    //         }
    //
    //         // Allow for animation
    //         (function drawLoop() {
    //             requestAnimFrame(drawLoop);
    //             renderCanvas();
    //         })();
    //     });
    // }

    handlerUpload(event): void {
        const _comment = this.fileComment;
        const files = event;
        if (this.uploadTypeSelected !== '' && files.length > 0) {
            if (files[0].size > 10000000) {
                super.showMessage(this.translate.instant('atencao'), this.translate.instant('arquivonaodeveultrapassar10MB'), 'info');
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(files[0]);

            switch (this.uploadTypeSelected) {
                case 'sound':
                    this.sounds.push({file: files[0], comment: _comment});
                    break;
                case 'video':
                    this.videos.push({file: files[0], comment: _comment});
                    break;
                case 'picture':
                    this.pictures.push({file: files[0], comment: _comment})
                    break;
            }
            this.fileComment = '';
        }
    }

    removeFile(type: string, index = null, idInput = null) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('desejarealmenteremoverestearquivo'), () => {
            if (!this.isNullOrUndefined(index)) {
                switch (type) {
                    case 'sound':
                        this.sounds.splice(index, 1);
                        break;
                    case 'video':
                        this.videos.splice(index, 1);
                        break;
                    case 'picture':
                        this.pictures.splice(index, 1);
                        break;
                }
            } else {
                this.removeOccurrenceFile.mutate({id: idInput}).subscribe(() => {
                    this.model.files = this.model.files.filter(f => f.id !== idInput);
                });
            }
        });
    }

    getAcceptTypes() {
        switch (this.uploadTypeSelected) {
            case 'sound':
                return '.mp3,.wav,.wma,.aac';
            case 'video':
                return '.mp4,.wmv';
            case 'picture':
                return '.jpeg,.jpg,.png';
        }
    }

    actionUpload() {
        const filesToUpload = [];
        let arrValidateFields = [];

        arrValidateFields = [
            {value: this.model.occurrenceDetail, text: `Detalhe da ocorrência <br>`},
        ];

        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }

        for (const f of this.pictures) {
            filesToUpload.push({type: 'picture', file: f.file, uploaded: false , comment: f.comment});
        }

        for (const f of this.sounds) {
            filesToUpload.push({type: 'sound', file: f.file, uploaded: false , comment: f.comment});
        }

        for (const f of this.videos) {
            filesToUpload.push({type: 'video', file: f.file, uploaded: false , comment: f.comment});
        }

        super.toggleLoader(true);
        if (!this.isNullOrUndefined(filesToUpload)) {
            this.upload(filesToUpload[0].type, filesToUpload[0].file, filesToUpload[0], filesToUpload);
        } else {
            super.toggleLoader(false);
            this.actionSave();
        }
    }

    upload(type: string, file, arrayItem, array) {
        console.log(file)
        console.log(arrayItem)
        console.log(array)
        this.excelService.uploadOccurrence(file, `/upload/occurrence/${this.model.id}/${type}`, arrayItem.comment).subscribe({
            next: data2 => {
                arrayItem.uploaded = true;
                const nextItem = array.find(p => p.uploaded === false);
                if (!this.isNullOrUndefined(nextItem, false)) {
                    this.upload(nextItem.type, nextItem.file, nextItem, array);
                } else {
                    this.pictures = [];
                    this.sounds = [];
                    this.videos = [];
                    super.toggleLoader(false);
                    this.actionSave();
                }
            }, error: err => super.onError(err)
        });
    }

    filteredFiles(files: Array<OccurrenceFile>, type: string) {
        if (!this.isNullOrUndefined(files)) {
            return files.filter(f => f.media === type);
        } else {
            return [];
        }
    }

    stepBack() {
        this.step = this.step - 1;
        this.clickedStepBack = true;
        this.enableDatePickers();
    }

    // actionSignature() {
    //     // $('html, body').animate({scrollTop: 0});
    //     let a = 0;
    //     const b = $('html, body');
    //     b.toggleClass('no-scroll');
    //     this.flagSignature = !this.flagSignature;
    //     if (!this.flagSignature) {
    //         a = $('.signature').offset().top;
    //     }
    //     b.stop().animate({ scrollTop: a });
    //     this.toggleFullScreen();
    // }

    onClearModel(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.model[str] = '';
            }
        });
    }

    onClearVictim(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.modelUserVictim[str] = '';
            }
        });
    }

    onClearAuthor(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.modelUserAuthor[str] = '';
            }
        });
    }

    onItemSelectUnities(item: any) {
        // NO SETTINGS DO MULTI-SELECT ID É O TYPE
        this.model.responsiblesCommunicated.push({id: '', type: item.id});
        console.log(this.model.responsiblesCommunicated);
    }

    onItemDeselectUnities(item: any) {
        this.model.responsiblesCommunicated = this.model.responsiblesCommunicated.filter((e) => {
            return e.type !== item.id;
        });
        console.log(this.model.responsiblesCommunicated);
    }

    addCollaborator() {

        if (this.isNullOrUndefined(this.collaborator.name) || this.isNullOrUndefined(this.collaborator.re)) {
            return this.showMessage(this.translate.instant('baseComponent.warningAttention'), 'Preencha o formulário corretamente para adicionar um colaborador', 'warning');
        }
        this.collaborators.push(this.collaborator);
        this.collaborator = new OccurrenceColaborator();
    }

    onAddAuthor() {
        this.modelUserAuthor =  new OccurrenceUser();
        this.showModal('authorModal');
        this.enableDatePickers();
    }

    addAuthor() {
        let arrValidateFields = [];

        arrValidateFields = [
            {value: this.modelUserAuthor.name, text: `Nome do autor <br>`},
            {value: this.modelUserAuthor.address, text: `Endereço<br>`},
            {value: this.modelUserAuthor.document, text: `ID<br>`},
            {value: this.modelUserAuthor.cep, text: `CEP<br>`},
            {value: this.modelUserAuthor.address, text: `Endereço<br>`},
            {value: this.modelUserAuthor.addressNumber, text: `Nº<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }

        this.authors.push(this.modelUserAuthor);
        this.modelUserAuthor = new OccurrenceUser();
        this.closeModal('authorModal');
    }

    removeCollaborator(memberId: string, index: number) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse colaborador?', () => {
            if (this.isNullOrUndefined(memberId)) {
                this.collaborators.splice(index, 1);
            }
        });
    }

    removeAuthor(memberId: string, index: number) {
        console.log(this.authors)
        console.log(memberId);
        console.log(index);
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse autor?', () => {
                this.authors.splice(index, 1);
        });
    }

    setCroppedPhoto(o: any) {
        this.handlerUpload(o.file);
    }

    handlerProfile() {
        return this.user.profile === 'vigilant' || this.user.profile === 'lecturer';
    }
}
