import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {User} from '../../../generated/graphql';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit {
    user: User = new User();
    navBarShort = false;
    btnNew = false;

    home: boolean;

    occurrence: boolean;
    dashboard: boolean;

    segurancaeletronica: boolean;
    accessControl: boolean;
    alarm: boolean;
    cftv: boolean;

    physicalSecurity: boolean;

    gestaoR: boolean;

    policiesProcedures: boolean;

    praanchetaeletronica: boolean;
    truck: boolean;
    services: boolean;

    userM: boolean;

    settings: boolean;
    settingsCamera: boolean;
    settingsAccess: boolean;
    settingsAlarm: boolean;
    settingsBlock: boolean;
    settingsUnity: boolean;
    settingsGeneral: boolean;


    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService) {

        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.btnNew = data.profile === 'local' || data.profile === 'administrator';
            }, error: err => super.onError(err)
        });
        this.actionUserProfile();
        this.handlerCollapse();
    }

    ngAfterViewInit(): void {
        this.actionUserProfile();
        this.navActive();
    }

    handlerCollapse() {
        $('.navbar-nav .nav-item').click(function () {
            $('.navbar-nav .nav-item').removeClass('active');
            $(this).addClass('active');
        });
    }

    actionCloseCollapse() {
        this.actionSideBar();
        $('#idea').collapse('hide');
        $('#clipboard').collapse('hide');
        $('#setting').collapse('hide');
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
    }

    navActive() {
        if (this.router.url === '/calibration' || this.router.url === '/engines') {
            return this.handlerNavActive('.report-ts');
        }
        if (this.router.url === '/training' || this.router.url === '/faq') {
            return this.handlerNavActive('.help-ts');
        }
    }

    handlerNavActive(el) {
        $('.navbar-nav .nav-item').removeClass('active');
        $('.navbar-nav .nav-link').removeClass('text-white');
        $(el).addClass('active');
        $(el + ' .nav-link').addClass('text-white');

    }

    actionSideBar() {
        this.navBarShort = !this.navBarShort;
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                this.home = true;
                this.occurrence = true;
                this.dashboard = true;
                this.segurancaeletronica = true;
                this.accessControl = true;
                this.alarm = true;
                this.cftv = true;
                this.physicalSecurity = true;
                this.gestaoR = true;
                this.policiesProcedures = true;
                this.praanchetaeletronica = true;
                this.truck = true;
                this.services = true;
                this.userM = true;
                this.settings = true;
                this.settingsCamera = true;
                this.settingsAccess = true;
                this.settingsAlarm = true;
                this.settingsBlock = true;
                this.settingsUnity = true;
                this.settingsGeneral = true;
                break;
            case 'local-manager':
                this.home = true;
                this.occurrence = true;
                this.dashboard = true;
                this.segurancaeletronica = true;
                this.accessControl = true;
                this.alarm = true;
                this.cftv = true;
                this.physicalSecurity = true;
                this.gestaoR = true;
                this.policiesProcedures = true;
                this.praanchetaeletronica = true;
                this.truck = true;
                this.services = true;
                this.userM = true;
                this.settings = true;
                this.settingsCamera = true;
                this.settingsAccess = true;
                this.settingsAlarm = true;
                this.settingsBlock = true;
                this.settingsUnity = true;
                this.settingsGeneral = true;
                break;
            case 'user':
                this.home = true;
                this.occurrence = false;
                this.dashboard = false;
                this.segurancaeletronica = false;
                this.accessControl = false;
                this.alarm = false;
                this.cftv = false;
                this.physicalSecurity = false;
                this.gestaoR = false;
                this.policiesProcedures = true;
                this.praanchetaeletronica = true;
                this.truck = false;
                this.services = true;
                this.userM = false;
                this.settings = false;
                this.settingsCamera = false;
                this.settingsAccess = false;
                this.settingsAlarm = false;
                this.settingsBlock = false;
                this.settingsUnity = false;
                this.settingsGeneral = false;
                break;
            case 'vigilant-leader':
                this.home = true;
                this.occurrence = true;
                this.dashboard = false;
                this.segurancaeletronica = false;
                this.accessControl = false;
                this.alarm = false;
                this.cftv = false;
                this.physicalSecurity = false;
                this.gestaoR = false;
                this.policiesProcedures = true;
                this.praanchetaeletronica = true;
                this.truck = true;
                this.services = true;
                this.userM = false;
                this.settings = false;
                this.settingsCamera = false;
                this.settingsAccess = false;
                this.settingsAlarm = false;
                this.settingsBlock = false;
                this.settingsUnity = false;
                this.settingsGeneral = false;
                break;
            case 'vigilant-conductor':
                this.home = true;
                this.occurrence = true;
                this.dashboard = false;
                this.segurancaeletronica = false;
                this.accessControl = false;
                this.alarm = false;
                this.cftv = false;
                this.physicalSecurity = false;
                this.gestaoR = false;
                this.policiesProcedures = true;
                this.praanchetaeletronica = true;
                this.truck = true;
                this.services = true;
                this.userM = false;
                this.settings = false;
                this.settingsCamera = false;
                this.settingsAccess = false;
                this.settingsAlarm = false;
                this.settingsBlock = false;
                this.settingsUnity = false;
                this.settingsGeneral = false;
                break;
            case 'vigilant':
                this.home = true;
                this.occurrence = true;
                this.dashboard = false;
                this.segurancaeletronica = false;
                this.accessControl = false;
                this.alarm = false;
                this.cftv = false;
                this.physicalSecurity = false;
                this.gestaoR = false;
                this.policiesProcedures = true;
                this.praanchetaeletronica = true;
                this.truck = true;
                this.services = true;
                this.userM = false;
                this.settings = false;
                this.settingsCamera = false;
                this.settingsAccess = false;
                this.settingsAlarm = false;
                this.settingsBlock = false;
                this.settingsUnity = false;
                this.settingsGeneral = false;
                break;
            case 'lecturer':
                this.home = true;
                this.occurrence = false;
                this.dashboard = false;
                this.segurancaeletronica = false;
                this.accessControl = false;
                this.alarm = false;
                this.cftv = false;
                this.physicalSecurity = false;
                this.gestaoR = false;
                this.policiesProcedures = true;
                this.praanchetaeletronica = true;
                this.truck = true;
                this.services = false;
                this.userM = false;
                this.settings = false;
                this.settingsCamera = false;
                this.settingsAccess = false;
                this.settingsAlarm = false;
                this.settingsBlock = false;
                this.settingsUnity = false;
                this.settingsGeneral = false;
                break;
            case 'corporate-operator':
                this.home = true;
                this.occurrence = false;
                this.dashboard = false;
                this.segurancaeletronica = true;
                this.accessControl = true;
                this.alarm = true;
                this.cftv = true;
                this.physicalSecurity = false;
                this.gestaoR = false;
                this.policiesProcedures = true;
                this.praanchetaeletronica = false;
                this.truck = false;
                this.services = false;
                this.userM = false;
                this.settings = false;
                this.settingsCamera = false;
                this.settingsAccess = false;
                this.settingsAlarm = false;
                this.settingsBlock = false;
                this.settingsUnity = false;
                this.settingsGeneral = false;
                break;
            case 'local-operator':
                this.home = true;
                this.occurrence = false;
                this.dashboard = false;
                this.segurancaeletronica = true;
                this.accessControl = true;
                this.alarm = true;
                this.cftv = true;
                this.physicalSecurity = false;
                this.gestaoR = false;
                this.policiesProcedures = true;
                this.praanchetaeletronica = false;
                this.truck = false;
                this.services = false;
                this.userM = false;
                this.settings = false;
                this.settingsCamera = false;
                this.settingsAccess = false;
                this.settingsAlarm = false;
                this.settingsBlock = false;
                this.settingsUnity = false;
                this.settingsGeneral = false;
                break;
            case 'transport':
                this.home = true;
                this.occurrence = false;
                this.dashboard = false;
                this.segurancaeletronica = false;
                this.accessControl = false;
                this.alarm = false;
                this.cftv = false;
                this.physicalSecurity = false;
                this.gestaoR = false;
                this.policiesProcedures = false;
                this.praanchetaeletronica = true;
                this.truck = true;
                this.services = true;
                this.userM = false;
                this.settings = false;
                this.settingsCamera = false;
                this.settingsAccess = false;
                this.settingsAlarm = false;
                this.settingsBlock = false;
                this.settingsUnity = false;
                this.settingsGeneral = false;
                break;
        }
    }
}
