<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon src="assets/svg/services.svg"></svg-icon>
            <h1 class="page-title">{{'praanchetaeletronica'|translate}} | <span>{{'servicos'|translate}}</span></h1>
            <a class="btn btn-gray-fill" (click)="actionExcel()" *ngIf="exportExcel">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                {{'exportarexcel'|translate}}
            </a>
            <a [routerLink]="'/services/create/new'" class="btn btn-secondary" *ngIf="actionNew">
                <svg-icon src="assets/svg/schedule.svg"></svg-icon>
                {{'agendarservico'|translate}}
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input [(ngModel)]="filter.keyword" id="name" class="form-control" type="text" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'busca'|translate}}</span>
                </label>
            </div>
            <div class="input-material">
                <input [(ngModel)]="filter.companyName" id="companyName" class="form-control" type="text" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'nomeprestador'|translate}}</span>
                </label>
            </div>
            <div class="input-material">
                <input id="date" class="form-control date-picker" type="text" required/>
                <label for="date">
                    <span>Período</span>
                    <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                </label>
            </div>
            <div class="input-material select" *ngIf="labelUnity">
                <select name="unity" id="unity"
                        [(ngModel)]="filter.unity"
                        class="form-control" required>
                    <option [ngValue]="undefined">{{'selectUnity'|translate}}</option>
                    <option *ngFor="let c of unities" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="unity">{{'unidade'|translate}}</label>
            </div>
            <div class="input-material select">
                <select name="unity" id="status"
                        [(ngModel)]="filter.status"
                        class="form-control" required>
                    <option [ngValue]="undefined">{{'selectStatus'|translate}}</option>
                    <option value="finished">{{'finished'|translate}}</option>
                    <option value="inspect">{{'inspect'|translate}}</option>
                    <option value="check">{{'check'|translate}}</option>
                    <option value="expired">{{'expired'|translate}}</option>
                </select>
                <label for="unity">{{'status'|translate}}</label>
            </div>
            <a class="btn btn-primary btn-lg text-white" (click)="onSearch()">{{'settings.dosearch'|translate}}</a>
        </div>

        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">{{"truck.plate" | translate}}</th>
                    <th scope="col">{{'entry'|translate}}</th>
                    <th class="text-center" scope="col">{{'saida'|translate}}</th>
                    <th class="text-center" scope="col">{{'nomeprestador'|translate}}</th>
                    <th class="text-center" scope="col">{{'responsavel'|translate}}</th>
                    <th class="text-center" scope="col">{{"area" | translate}}</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(listService)">
                    <td colspan="8">
                        <p class="text-center my-5">{{"settings.not-result" | translate}}</p>
                    </td>
                </tr>
                <tr *ngFor="let l of listService.results |
                paginate: {itemsPerPage: listService.PageSize,
                currentPage: listService.currentPage,
                totalItems: listService.totalCount }">
                    <td>
                        <a href="javascript:void('');" (click)="actionShowRequest(l)" *ngIf="l.inspect.length > 0">
                            <svg-icon src="assets/svg/plus.svg"></svg-icon>
                        </a>
                    </td>
                    <td>{{l?.licensePlate}}</td>
                    <td>{{l?.entranceDate | date: 'dd/MM/yyyy - HH:mm' : 'GMT'}}</td>
                    <td class="text-center">{{l?.exitDate | date: 'dd/MM/yyyy - HH:mm' : 'GMT'}}</td>
                    <td class="text-center">{{l?.name}}</td>
                    <td class="text-center">{{l?.createdBy?.name}}</td>
                    <td class="text-center">{{l?.area}}</td>
                    <td *ngIf="check">
                        <!--                        <span class="status is-cursor" [routerLink]="(l.status !== 'finished' ? 'survey/' : 'detail/') + l.id"-->
                        <!--                              *ngIf="!handlerRequestIsStart(l)"-->
                        <!--                              [ngClass]="{'check': l.status === 'check',-->
                        <!--                                          'inspect': l.status === 'inspect',-->
                        <!--                                          'finished': l.status === 'finished'}">-->
                        <!--                            {{getTranslate(l.status)}}-->
                        <!--                        </span>-->

                        <span class="status is-cursor" (click)="validateUser(l)"
                              *ngIf="!handlerRequestIsStart(l)"
                              [ngClass]="{'check': l.status === 'check',
                                          'inspect': l.status === 'inspect',
                                          'finished': l.status === 'finished',
                                          'expired': l.status === 'expired'}">
                            {{getTranslate(l.status)}}
                        </span>
                    </td>
                    <td class="text-center">
                        <div class="table_action">
                            <a [routerLink]="['create/' + l.id]" href="javascript:void('');"
                               *ngIf="handlerRequestIsStart(l)">
                                <svg-icon src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <a [routerLink]="['detail/' + l.id]" href="javascript:void('');"
                               *ngIf="!handlerRequestIsStart(l)">
                                <svg-icon src="assets/svg/eye.svg"></svg-icon>
                            </a>
                            <a href="javascript:void('');" (click)="actionRemoveRequest(l)"
                               *ngIf="handlerRequestIsStart(l)">
                                <svg-icon src="assets/svg/close-red.svg"></svg-icon>
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>


<div class="modal fade" id="modalServices" tabindex="-1" role="dialog" aria-labelledby="cameraModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{model.name}} | <small>ID: {{model.cpf}}</small>
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body mh-0 mb-0">
                <div class="table-responsive">
                    <table class="table table-striped table_regular">
                        <thead>
                        <tr>
                            <th>{{'date'|translate}}</th>
                            <th>{{'tipo'|translate}}</th>
                            <th>Vigilante</th>
                            <th class="text-right w-5">Equipamentos</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let x of model.inspect">
                            <td>{{x.createdAt | date: 'dd/MM/yyyy - HH:mm' : 'GMT'}}</td>
                            <td>{{getTranslate(x.type)}}</td>
                            <td>{{x.createdBy?.name}}</td>
                            <td class="text-right">
                                <a (click)="actionShowRequestInspect(x)" href="javascript:void('');">
                                    <svg-icon src="assets/svg/eye.svg"></svg-icon>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalServicesInspect" tabindex="-1" role="dialog" aria-labelledby="cameraModal"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{getTranslate(modelInspect.type)}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body mh-0 mb-0">
                <div class="table-responsive">
                    <table class="table table-striped table_regular">
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th class="text-right">Quantidade</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let x of modelInspect.equipment">
                            <td>{{x.name}}</td>
                            <td class="text-right">{{x.quantity}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!isNullOrUndefined(modelInspect.others)">
                    <h3>Outros</h3>
                    <p>
                        {{modelInspect.others}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


