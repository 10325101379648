import {AfterViewInit, Component, OnInit} from '@angular/core';
import {
    Access,
    Bloc,
    Country,
    GetBlocByUnityGQL,
    GetBlocByUnityQuery,
    GetCountriesGQL,
    GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery,
    SearchAccessQuery,
    Unity,
    CreateAccessGQL,
    UpdateAccessGQL,
    RemoveAccessGQL,
    SearchAccessGQL,
    UpdateAccessInput,
    CreateAccessInput, GetCountriesQuery, SearchAccessInput, AccessPage, User
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../base/base.component';
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-access',
    templateUrl: './access.component.html',
    styleUrls: ['./access.component.scss']
})
export class AccessComponent extends BaseComponent implements OnInit, AfterViewInit {
    user = new User();
    countries: Country[] = [];
    countryQuery: QueryRef<GetCountriesQuery>;

    // create
    modelCreate: Access = new Access();
    selectedCreateUnityId: string;
    selectedCreateBlocId: string;
    selectedCreateCountryId: string;

    createUnities: Array<Unity> = [];
    createUnityQuery: QueryRef<GetUnitiesByCountryQuery>;

    createBlocs: Array<Bloc> = [];
    createBlocsByUnityQuery: QueryRef<GetBlocByUnityQuery>;

    // list
    filterUnityId: string;
    filterBlocId: string;
    filterCountryId: string;

    filter: SearchAccessInput = new SearchAccessInput();
    searchAccessQuery: QueryRef<SearchAccessQuery>;
    accesses: AccessPage = new AccessPage();

    filterUnities: Array<Unity>;
    filterUnityQuery: QueryRef<GetUnitiesByCountryQuery>;

    filterBlocs: Array<Bloc>;
    filterBlocsByUnityQuery: QueryRef<GetBlocByUnityQuery>;

    constructor(
        private searchAccessGQL: SearchAccessGQL,
        private createAccess: CreateAccessGQL,
        private updateAccess: UpdateAccessGQL,
        private removeAccess: RemoveAccessGQL,
        private getCountriesGQL: GetCountriesGQL,
        private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
        private getBlocByUnityGQL: GetBlocByUnityGQL,
        public userService: UserService,
        public router: Router,
        public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.getCountries();
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.createUnityQuery = this.getUnitiesByCountryGQL
            .watch({countryId: this.isNullOrUndefined(this.selectedCreateCountryId) ? '' : this.selectedCreateCountryId});
        this.createUnityQuery.valueChanges.subscribe(({data}) => {
            this.createUnities = data.getUnitiesByCountry as Array<Unity>;

            this.createBlocsByUnityQuery = this.getBlocByUnityGQL
                .watch({unityId: this.isNullOrUndefined(this.selectedCreateUnityId) ? '' : this.selectedCreateUnityId});
            this.createBlocsByUnityQuery.valueChanges.subscribe(({data}) => {
                this.createBlocs = data.blocByUnity as Array<Bloc>;
            });
        });


    }

    listAccesses() {
        this.searchAccessQuery = this.searchAccessGQL.watch({data: this.filter});
        this.searchAccessQuery.valueChanges.subscribe(({data}) => {
            this.accesses = data.searchAccess as AccessPage;
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            const c = data.countries as Array<Country>;
            const x = JSON.stringify(c);
            this.countries = JSON.parse(x) as Array<Country>;
            this.listAccesses();
        });
    }

    getUnitiesForCreate() {
        this.selectedCreateBlocId = null;
        this.selectedCreateUnityId = null;
        this.createBlocs = [];
        this.createUnities = [];
        if (this.isNullOrUndefined(this.selectedCreateCountryId)) {
            return;
        }
        this.createUnityQuery.refetch({countryId: this.selectedCreateCountryId});
    }

    getBlocsForCreate() {
        this.createBlocs = [];
        this.selectedCreateBlocId = null;
        if (this.isNullOrUndefined(this.selectedCreateUnityId)) {
            return;
        }
        this.createBlocsByUnityQuery.refetch({unityId: this.selectedCreateUnityId});
    }

    getUnitiesFilter() {
        this.filterUnityId = null;
        this.filterBlocId = null;
        this.filterUnities = [];
        this.filterBlocs = [];
        if (this.isNullOrUndefined(this.filterCountryId)) {
            return;
        }
        this.filterUnityQuery = this.getUnitiesByCountryGQL.watch({countryId: this.filterCountryId});
        this.filterUnityQuery.valueChanges.subscribe(({data}) => {
            this.filterUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    getBlocsFilter() {
        this.filterBlocId = null;
        this.filterBlocs = [];
        if (this.isNullOrUndefined(this.filterUnityId)) {
            return;
        }
        this.filterBlocsByUnityQuery = this.getBlocByUnityGQL.watch({unityId: this.filterUnityId});
        this.filterBlocsByUnityQuery.valueChanges.subscribe(({data}) => {
            this.filterBlocs = data.blocByUnity as Array<Bloc>;
        });
    }

    onFilter() {
        this.filter.bloc = this.filterBlocId;
        this.filter.unity = this.filterUnityId;
        this.filter.country = this.filterCountryId;
        this.filter.page = 1;
        this.searchAccessQuery.refetch({data: this.filter});
    }

    create() {
        this.modelCreate = new Access();
        this.selectedCreateCountryId = null;
        this.selectedCreateUnityId = null;
        this.selectedCreateBlocId = null;
        this.modelCreate.status = true;
        this.showModal('accessModal');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.modelCreate.place, text: `Local<br>`},
            {value: this.selectedCreateCountryId, text: `País<br>`},
            {value: this.selectedCreateUnityId, text: `Unidade<br>`},
            {value: this.selectedCreateBlocId, text: `Bloco<br>`},
            {value: this.modelCreate.type, text: `Tipo<br>`},
            {value: this.modelCreate.status, text: `Status<br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelCreate.id)) {
            this.actionCreateAccess();
        } else {
            this.actionUpdateAccess();
        }
    }

    actionCreateAccess() {
        const data: CreateAccessInput = {
            place: this.modelCreate.place,
            type: this.modelCreate.type,
            status: this.modelCreate.status,
            bloc: {
                id: this.selectedCreateBlocId,
            }
        };
        this.createAccess.mutate({data: data}).subscribe((result) => {
            this.modelCreate = new Access();
            this.searchAccessQuery.refetch({data: this.filter});
            this.showMessage(this.translate.instant('success'), this.translate.instant('accesocriadocomsucesso'), 'success');
            this.closeModal('accessModal');
        });
    }

    actionUpdateAccess() {
        const data: UpdateAccessInput = {
            id: this.modelCreate.id,
            place: this.modelCreate.place,
            type: this.modelCreate.type,
            status: this.modelCreate.status,
            bloc: {
                id: this.selectedCreateBlocId,
            }
        };
        this.updateAccess.mutate({data: data}).subscribe(() => {
            this.modelCreate = new Access();
            this.searchAccessQuery.refetch({data: this.filter});
            this.showMessage(this.translate.instant('success'), this.translate.instant('acessoeditadocomsucesso'), 'success');
            this.closeModal('accessModal');
        });
    }

    actionEdit(access: Access) {
        this.modelCreate = Object.assign({}, access);
        this.selectedCreateUnityId = this.modelCreate.bloc.unity.id;
        this.selectedCreateBlocId = this.modelCreate.bloc.id;
        this.selectedCreateCountryId = this.modelCreate.bloc.unity.country.id;
        this.createUnityQuery.refetch({countryId: this.selectedCreateCountryId});
        this.createBlocsByUnityQuery.refetch({unityId: this.selectedCreateUnityId});
        this.showModal('accessModal');
    }

    actionDelete(accessId) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('desejarealmenteexcluiresseacesso'), () => {
            this.removeAccess.mutate({id: accessId}).subscribe((result) => {
                this.searchAccessQuery.refetch({data: this.filter});
                super.showMessage(this.translate.instant('success'), this.translate.instant('acessodeletadocomsucesso'), 'success');
            });
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.searchAccessQuery.refetch({data: this.filter});
    }

}
