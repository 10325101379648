<div class="page">
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" required [(ngModel)]="filter.keyword" [maxLength]="80"/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>Busca</span>
                </label>
            </div>
            <div class="input-material select">
                <select name="country" id="country" (change)="getUnitiesFilter()"
                        [(ngModel)]="filterCountryId"
                        class="form-control" required>
                    <option [ngValue]="null">Todos</option>
                    <option *ngFor="let c of filtercountries" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="country">País</label>
            </div>
            <div class="input-material select">
                <select name="unity" id="unity" (change)="getBlocsFilter()"
                        [(ngModel)]="filterUnityId"
                        class="form-control" required>
                    <option [ngValue]="null">Selecione um país</option>
                    <option *ngFor="let c of filterUnities" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="unity">Unidade</label>
            </div>
            <div class="input-material select">
                <select name="bloc" id="bloc"
                        [(ngModel)]="filterBlocId"
                        class="form-control" required>
                    <option [ngValue]="null">Selecione uma unidade</option>
                    <option *ngFor="let b of filterBlocs" value="{{b.id}}">{{b.name}}</option>
                </select>
                <label for="bloc">Bloco</label>
            </div>
            <!-- FILTER -->
            <a href="javascript:void(0)" (click)="onFilter()"
               class="btn btn-primary btn-lg text-white flex-grow-1"
               type="button">
                <span>{{'buscar'|translate}}</span>
            </a>
        </div>
</div>

