import {Component, OnInit} from '@angular/core';
import {Bloc, Country, GetBlocByUnityGQL, GetBlocByUnityQuery, GetCountriesGQL,
    GetCountriesQuery, GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery, SearchCameraInput, Unity} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent extends BaseComponent implements OnInit {

    filter: SearchCameraInput = new SearchCameraInput();

    filterCountryId: string;
    filtercountries: Array<Country>;
    filtercountryQuery: QueryRef<GetCountriesQuery>;

    filterUnityId: string;
    filterUnities: Array<Unity>;
    filterUnityQuery: QueryRef<GetUnitiesByCountryQuery>;

    filterBlocId: string;
    filterBlocs: Array<Bloc>;
    filterBlocsByUnityQuery: QueryRef<GetBlocByUnityQuery>;

    constructor(
        private filterGetCountriesGQL: GetCountriesGQL,
        private filterGetUnitiesByCountryGQL: GetUnitiesByCountryGQL,
        private filterGetBlocByUnityGQL: GetBlocByUnityGQL,
        public router: Router,
        public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.filterGetCountries();
        this.getUnitiesFilter();
        this.getBlocsFilter();
    }


    filterGetCountries() {
        this.filtercountryQuery = this.filterGetCountriesGQL.watch();
        this.filtercountryQuery.valueChanges.subscribe(({data}) => {
            this.filtercountries = data.countries as Array<Country>;
        });
    }

    getUnitiesFilter() {
        this.filterUnityQuery = this.filterGetUnitiesByCountryGQL.watch({countryId: this.isNullOrUndefined(this.filterCountryId) ? '' : this.filterCountryId});
        this.filterUnityQuery.valueChanges.subscribe(({data}) => {
            this.filterUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    getBlocsFilter() {
        this.filterBlocsByUnityQuery = this.filterGetBlocByUnityGQL.watch({unityId: this.isNullOrUndefined(this.filterUnityId) ? '' : this.filterUnityId});
        this.filterBlocsByUnityQuery.valueChanges.subscribe(({data}) => {
            this.filterBlocs = data.blocByUnity as Array<Bloc>;
        });
    }

    onFilter() {
        this.filter.bloc = this.filterBlocId;
        this.filter.unity = this.filterUnityId;
        this.filter.country = this.filterCountryId;
        // this.searchCameraQuery.refetch({data: this.filter});
    }

}
