import {AfterViewInit, Component, OnInit} from '@angular/core';
import {
    Country,
    CreateServiceRequestGQL,
    CreateServiceRequestHelperInput,
    CreateTruckCheckListObject,
    InspectServiceRequestEquipmentInput,
    InspectServiceRequestGQL,
    InspectServiceRequestInput,
    InspectServiceRequestServiceInput,
    RemoveTruckGQL,
    SearchServiceRequestGQL,
    ServiceRequest, ServiceRequestEquipment,
    ServiceRequestGQL, ServiceRequestHelper,
    ServiceRequestInspect,
    ServiceRequestInspectionGQL,
    ServiceRequestInspectionQuery,
    ServiceRequestQuery,
    Truck,
    TruckHelper,
    TruckReceipt,
    UpdateServiceRequestDetailGQL,
    UpdateServiceRequestDetailInput,
    UpdateServiceRequestGQL,
    UpdateServiceRequestInput
} from '../../../../generated/graphql';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {QueryRef} from 'apollo-angular';
import {BaseComponent} from '../../../base/base.component';
import moment from 'moment';
import {UserService} from "../../../service/user.service";

declare var $: any;
declare var window;
declare var requestAnimFrame;

@Component({
    selector: 'app-services-survey',
    templateUrl: './services-survey.component.html',
    styleUrls: ['./services-survey.component.scss']
})
export class ServicesSurveyComponent extends BaseComponent implements OnInit, AfterViewInit {
    paramId = '';
    paramSub = '';
    model: ServiceRequest = new ServiceRequest();
    modelUpdate: ServiceRequest = new ServiceRequest();
    modelQuery: QueryRef<ServiceRequestQuery>;
    modelInspectQuery: QueryRef<ServiceRequestInspectionQuery>;
    flagEdit = false;
    flagFinish = false;
    flagSignature = false;
    imageSignature = undefined;
    imageSignatureFile: File;

    helper: ServiceRequestHelper = new ServiceRequestHelper();

    quantity = 0;
    arrItems: InspectServiceRequestEquipmentInput[] = [
        {name: 'Aspirador de pó', quantity: 0},
        {name: 'Balometro', quantity: 0},
        {name: 'Cabos, tomadas, e diversos', quantity: 0},
        {name: 'Carrinho', quantity: 0},
        {name: 'Carro oficina', quantity: 0},
        {name: 'Chave catraca kit', quantity: 0}
    ];
    modelInspect: InspectServiceRequestInput = new InspectServiceRequestInput();

    modelEquipament: ServiceRequestEquipment[] = [];
    modelImspectFilter: ServiceRequestInspect[] = [];

    fileEquipment: File = null;
    thumbEquipment: any = null;

    modelInspectOthers = undefined;
    modelInspectObs = undefined;
    modelIsFinalize: boolean;
    canvas: any;
    ctx: any;

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute,
                public translate: TranslateService,
                private userService: UserService,
                private serviceRequestGQL: ServiceRequestGQL,
                private serviceRequestInspectionGQL: ServiceRequestInspectionGQL,
                private updateServiceRequestDetailGQL: UpdateServiceRequestDetailGQL,
                private inspectServiceRequestGQL: InspectServiceRequestGQL) {
        super(router, translate);
        this.model.helper = [];
        this.modelInspect.serviceRequest = new InspectServiceRequestServiceInput();
    }

    ngOnInit(): void {
        $(() => {
            $('#downArrow').click(() => {
                $('#downDiv').slideToggle('slow');
            });
        });
    }

    ngAfterViewInit(): void {
        this.activatedRoute.params.subscribe(u => {
            if (!this.isNullOrUndefined(u.id)) {
                console.log(u);
                this.paramId = u.id;
                this.paramSub = !this.isNullOrUndefined(u.sub) ? u.sub : '';
                this.modelQuery = this.serviceRequestGQL.watch({data: this.paramId});
                this.modelQuery.valueChanges.subscribe(({data}) => {
                    this.model = data.serviceRequest as ServiceRequest;
                    this.modelEquipament = data.serviceRequest.virtualEquipment as ServiceRequestEquipment[];
                    this.getFilterInspect();
                    this.thumbEquipment = this.model.inspect[0]?.equipmentImage;
                    if (this.model.status === 'finished') {
                        this.flagEdit = false;
                    } else if (this.model.status !== 'check') {
                        this.flagEdit = true;
                    }
                    if (!this.isNullOrUndefined(this.paramSub)) {
                        this.getInspection();
                    }
                    // else {
                    //     if (this.model.inspect[0]) {
                    //         this.model.inspect[0]?.equipment.map((x) => {
                    //             const el = this.model.virtualEquipment.filter((f) => f.name === x.name);
                    //             el[0].quantity = x.quantity;
                    //         });
                    //     }
                    // }
                });
            }
            // $(() => {
            //     this.canvas = document.getElementById('sig-canvas');
            //     this.ctx = this.canvas.getContext('2d');
            //     this.handlerPaint();
            //     $(window).resize(() => {
            //         let W = this.canvas.offsetWidth, H = this.canvas.offsetHeight;
            //         let temp = this.ctx.getImageData(0, 0, W, H)
            //         this.ctx.canvas.width = parseInt(document.getElementById('sketch').offsetWidth.toString(), 0);
            //         this.ctx.canvas.height = parseInt(document.getElementById('sketch').offsetHeight.toString(), 0);
            //         this.ctx.putImageData(temp, 0, 0)
            //     })
            // });
        });
    }

    // actionSignature(isFinish?) {
    //     if (this.flagFinish) {
    //         return;
    //     }
    //     let a = 0;
    //     const b = $('html, body');
    //     b.toggleClass('no-scroll');
    //     this.flagSignature = !this.flagSignature;
    //     if (!this.flagSignature) {
    //         a = $('.signature').offset().top;
    //     }
    //     b.stop().animate({ scrollTop: a });
    //     this.toggleFullScreen();
    // }

    // handlerPaint() {
    //     $(() => {
    //         const st = this;
    //         this.canvas.width = parseInt(document.getElementById('sketch').offsetWidth.toString(), 0);
    //         this.canvas.height = parseInt(document.getElementById('sketch').offsetHeight.toString(), 0);
    //
    //         // Get a regular interval for drawing to the screen
    //         window.requestAnimFrame = (function (callback) {
    //             return window.requestAnimationFrame ||
    //                 window.webkitRequestAnimationFrame ||
    //                 window.mozRequestAnimationFrame ||
    //                 window.oRequestAnimationFrame ||
    //                 window.msRequestAnimaitonFrame ||
    //                 function (callback) {
    //                     window.setTimeout(callback, st.canvas.width / st.canvas.height);
    //                 };
    //         })();
    //
    //         this.ctx.strokeStyle = '#222222';
    //         this.ctx.lineWith = 2;
    //
    //         // Set up the UI
    //         const sigText: any = document.getElementById('sig-dataUrl');
    //         const sigImage: any = document.getElementById('sig-image');
    //         const clearBtn: any = document.getElementById('sig-clearBtn');
    //         const submitBtn: any = document.getElementById('sig-submitBtn');
    //         clearBtn.addEventListener('click', function (e) {
    //             clearCanvas();
    //             // sigText.innerHTML = 'Data URL for your signature will go here!';
    //             // sigImage.setAttribute('src', '');
    //         }, false);
    //         submitBtn.addEventListener('click', function (e) {
    //             const dataUrl = st.canvas.toDataURL('image/png');
    //             const blobBin = atob(dataUrl.split(',')[1]);
    //             const array = [];
    //             for (let i = 0; i < blobBin.length; i++) {
    //                 array.push(blobBin.charCodeAt(i));
    //             }
    //             st.imageSignatureFile = new File([new Uint8Array(array)], 'signature.png', {
    //                 type: 'image/png',
    //                 lastModified: new Date().getTime()
    //             });
    //             st.imageSignature = dataUrl;
    //             st.actionSignature();
    //         }, false);
    //         // Set up mouse events for drawing
    //         let drawing = false;
    //         let mousePos = {x: 0, y: 0};
    //         let lastPos = mousePos;
    //         this.canvas.addEventListener('mousedown', function (e) {
    //             // st.flagSignature = true; // FLAG ASSINATURA PREENCHIDA
    //             drawing = true;
    //             lastPos = getMousePos(st.canvas, e);
    //         }, false);
    //         this.canvas.addEventListener('mouseup', function (e) {
    //             drawing = false;
    //         }, false);
    //         this.canvas.addEventListener('mousemove', function (e) {
    //             mousePos = getMousePos(st.canvas, e);
    //         }, false);
    //         // Set up touch events for mobile, etc
    //         this.canvas.addEventListener('touchstart', function (e) {
    //             mousePos = getTouchPos(st.canvas, e);
    //             const touch: any = e.touches[0];
    //             const mouseEvent: any = new MouseEvent('mousedown', {
    //                 clientX: touch.clientX,
    //                 clientY: touch.clientY
    //             });
    //             st.canvas.dispatchEvent(mouseEvent);
    //         }, false);
    //         this.canvas.addEventListener('touchend', function (e) {
    //             const mouseEvent: any = new MouseEvent('mouseup', {});
    //             st.canvas.dispatchEvent(mouseEvent);
    //         }, false);
    //         this.canvas.addEventListener('touchmove', function (e) {
    //             const touch: any = e.touches[0];
    //             const mouseEvent: any = new MouseEvent('mousemove', {
    //                 clientX: touch.clientX,
    //                 clientY: touch.clientY
    //             });
    //             st.canvas.dispatchEvent(mouseEvent);
    //         }, false);
    //         // Prevent scrolling when touching the canvas
    //         document.body.addEventListener('touchstart', function (e) {
    //             if (e.target === st.canvas) {
    //                 e.preventDefault();
    //             }
    //         }, false);
    //         document.body.addEventListener('touchend', function (e) {
    //             if (e.target === st.canvas) {
    //                 e.preventDefault();
    //             }
    //         }, false);
    //         document.body.addEventListener('touchmove', function (e) {
    //             if (e.target === st.canvas) {
    //                 e.preventDefault();
    //             }
    //         }, false);
    //
    //         // Get the position of the mouse relative to the canvas
    //         function getMousePos(canvasDom, mouseEvent) {
    //             const rect = canvasDom.getBoundingClientRect();
    //             return {
    //                 x: mouseEvent.clientX - rect.left,
    //                 y: mouseEvent.clientY - rect.top
    //             };
    //         }
    //
    //         // Get the position of a touch relative to the canvas
    //         function getTouchPos(canvasDom, touchEvent) {
    //             const rect = canvasDom.getBoundingClientRect();
    //             return {
    //                 x: touchEvent.touches[0].clientX - rect.left,
    //                 y: touchEvent.touches[0].clientY - rect.top
    //             };
    //         }
    //
    //         // Draw to the canvas
    //         function renderCanvas() {
    //             if (drawing) {
    //                 st.ctx.moveTo(lastPos.x, lastPos.y);
    //                 st.ctx.lineTo(mousePos.x, mousePos.y);
    //                 st.ctx.stroke();
    //                 lastPos = mousePos;
    //             }
    //         }
    //
    //         function clearCanvas() {
    //             st.canvas.width = st.canvas.width;
    //         }
    //
    //         // Allow for animation
    //         (function drawLoop() {
    //             requestAnimFrame(drawLoop);
    //             renderCanvas();
    //         })();
    //     });
    // }

    getInspection() {
        this.modelInspectQuery = this.serviceRequestInspectionGQL.watch({data: this.paramSub});
        this.modelInspectQuery.valueChanges.subscribe(({data}) => {
            const a = data.serviceRequestInspection;
            this.flagEdit = false;
            this.flagFinish = true;
            this.imageSignature = a.signature;
            if (a) {
                this.modelInspect.type = a.type;
                this.modelInspect.others = a.others;
                if (this.model.status === 'finished') {
                    this.modelEquipament = a.equipment;
                }
            }
        });
    }

    dropCol() {
        // $('.col_hide').toggleClass('d-none');
        $('.col_hide').stop().slideToggle();
        $('#downArrow').toggleClass('rotate');
    }

    handlerItemQuantity(t: string, x: any) {
        if (t === 'add') {
            x.quantity++;
        } else {
            x.quantity = x.quantity <= 1 ? 0 : x.quantity - 1;
        }
    }

    actionSave() {
        // if (!this.validateSaveRequest()) {
        //     return false;
        // }
        this.confirmMessage(this.translate.instant('atencao'), this.translate.instant('temcertezadesejaprosseguir'), () => {
            this.onCreateInspection();
        });
    }

    actionFinish() {
        // if (!this.validateSaveRequest()) {
        //     return false;
        // }
        this.confirmMessage(this.translate.instant('atencao'), this.translate.instant('desejarealmenteencerrar'), () => {
            this.modelIsFinalize = true;
            this.onCreateInspection();
        });
    }

    // validateSaveRequest() {
    //     let str = true;
    //     if (this.isNullOrUndefined(this.imageSignatureFile)) {
    //         super.showMessage(this.translate.instant('atencao'),
    //             this.translate.instant('assinerequisiçaoparaprosseguir'), 'warning');
    //         str = false;
    //     }
    //     return str;
    // }

    onCreateInspection() {
        this.modelInspect = new InspectServiceRequestInput();
        this.modelInspect.serviceRequest = {id: this.paramId};
        this.modelInspect.equipment = [];
        if (this.model.status === 'inspect') {
            this.model.virtualEquipment.forEach((e) => {
                this.modelInspect.equipment.push({name: e.name, quantity: e.quantity});
            });
        }
        this.modelInspect.others = this.modelInspectOthers;
        this.modelInspect.obs = this.modelInspectObs;
        this.modelInspect.isFinalize = this.modelIsFinalize;
        this.modelInspect.date = moment();
        this.modelInspect.type = this.model.status === 'check' ? 'exit' : 'entry';
        console.log('this.modelInspect');
        console.log(this.modelInspect);
        this.inspectServiceRequestGQL.mutate({data: this.modelInspect}).subscribe((result) => {
            this.onCreateImage(result.data.inspectServiceRequest);
        });
        this.modelIsFinalize = false;
    }

    onCreateImage(o: any) {
        // this.userService.uploadFile(this.imageSignatureFile, `/upload/service-request/inspect/${o.id}/signature`).subscribe({
        //     next: data => {
                this.userService.uploadFile(this.fileEquipment, `/upload/service-request/inspect/${o.id}/equip-image`).subscribe({
                    next: data => {
                    }, error: err => super.onError(err)
                });
                this.router.navigate(['/services']);
                super.showMessage(this.translate.instant('success'), this.translate.instant('Servicoalteradocomsucesso'), 'success');
            }
        // });

    pushHelpersList() {
        if (this.isNullOrUndefined(this.helper.name) || this.isNullOrUndefined(this.helper.doc)) {
            return this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('form'), 'warning');
        }
        this.modelUpdate.helper.push(this.helper);
        this.helper = new ServiceRequestHelper();
    }

    removeHelpers(memberId: string, index: number) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse ajudante?', () => {
            this.modelUpdate.helper.splice(index, 1);
        });
    }

    editProvider() {
        this.modelUpdate = new ServiceRequest();
        this.modelUpdate = JSON.parse(JSON.stringify(this.model)) as ServiceRequest;
        this.showModal('updateProvider');
    }

    saveProvider() {
        const arrValidateFields = [
            {value: this.modelUpdate.name, text: `Nome do prestador<br>`},
            {value: this.modelUpdate.licensePlate, text: `RG<br>`},
            {value: this.modelUpdate.vehicle, text: `Placa do Veículo<br>`},
            {value: this.modelUpdate, text: `Veículo<br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('camposobrigatorios')} <br> ${stringError}`);
            return;
        }

        let helpers: CreateServiceRequestHelperInput[] = [];
        this.modelUpdate.helper.forEach((e) => {
            helpers.push({name: e.name, doc: e.doc})
        })
        const data: UpdateServiceRequestDetailInput = {
            id: this.modelUpdate.id,
            name: this.modelUpdate.name,
            licensePlate: this.modelUpdate.licensePlate,
            vehicle: this.modelUpdate.vehicle,
            helper: helpers,
        }
        this.updateServiceRequestDetailGQL.mutate({data: data}).subscribe(({data}) => {
            this.closeModal('updateProvider');
            this.modelQuery.refetch({data: this.paramId});
            super.showMessage(this.translate.instant('success'), this.translate.instant('Servicoalteradocomsucesso'), 'success');
        }, error => super.onError(error));
    }

    setCroppedEquipment(o: any) {
        this.thumbEquipment = o.thumb;
        this.fileEquipment = this.handleUpload(o.file);
    }

    getFilterInspect() {
        this.modelImspectFilter = this.model.inspect.filter(e => !super.isNullOrUndefined(e.others));
    }
}
