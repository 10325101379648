<div class="truck page">
    <div class="section_top">
        <div class="page-padding">
            <div class="page-header-custom-secondary">
                <svg-icon routerLink="/services" class="is-cursor" src="assets/svg/back.svg"></svg-icon>
                <h2 class="page-title"><span>Vistoria de </span><strong> &nbsp;Ferramentas</strong></h2>
            </div>
            <div class="mt-4 mb-5 d-block text-center" *ngIf="flagFinish">
                <span class="highlighted-text bg-warning">
                    {{getTranslate(modelInspect.type)}}
                </span>
            </div>
            <div class="tab_selector">
                <div class="tab_selector_content">
                    <div class="tab_selector_content_div">
                        <div class="d-flex align-items-center w-100 px-3" style="gap: 15px">
                            <p class="flex-grow-1 text-center mb-0">Data e Local</p>
                        </div>
                        <div class="col_hide flex-column align-items-center justify-content-center"
                             style="display: none;">
                            <div class="d-flex align-items-end">
                                <div class="col mb-2">
                                    <p class="text-light">
                                        {{'areaservico'|translate}}
                                    </p>
                                    <span class="ml-2 mb-5">
                                        {{model?.area}}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex align-items-end">
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Data Entrada
                                    </p>
                                    <span class="ml-2">
                                        {{model?.entranceDate | date: 'dd/MM/yyyy'}}
                                    </span>
                                </div>
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Hora Prevista
                                    </p>
                                    <span class="ml-2">
                                        {{model?.entranceDate | date: 'HH:mm'}}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex align-items-end">
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Data Saída
                                    </p>
                                    <span class="ml-2">
                                        {{model?.exitDate | date: 'dd/MM/yyyy'}}
                                    </span>
                                </div>
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Hora Prevista
                                    </p>
                                    <span class="ml-2">
                                        {{model?.exitDate | date: 'HH:mm'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab_selector_content_div mid">
                        <div class="d-flex align-items-center w-100 px-3" style="gap: 15px">
                            <p class="flex-grow-1 text-center mb-0">Informações do prestador</p>
                            <a class="btn btn-xs btn-primary" (click)="editProvider()">
                                <svg-icon [svgStyle]="{'width': '20px'}" class="svg-white"
                                          src="assets/svg/edit.svg"></svg-icon>
                            </a>
                        </div>
                        <div class="col_hide flex-column align-items-center justify-content-center"
                             style="display: none;">
                            <div class="d-flex align-items-end">
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Nome do Prestador
                                    </p>
                                    <span class="ml-2">
                                        {{model?.name}}
                                    </span>

                                </div>
                                <div class="col mb-2">
                                    <p class="text-light">
                                        ID
                                    </p>
                                    <span class="ml-2">
                                        {{model?.cpf}}
                                    </span>

                                </div>
                            </div>
                            <div class="d-flex align-items-end">
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Empresa
                                    </p>
                                    <span class="ml-2">
                                        {{model?.company}}
                                    </span>
                                </div>
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Veiculo
                                    </p>
                                    <span class="ml-2">
                                        {{model?.vehicle}}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex align-items-end">
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Placa do Veiculo
                                    </p>
                                    <span class="ml-2">
                                        {{model?.licensePlate}}
                                    </span>
                                </div>
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Nome do ajudante ({{model?.helper.length}})
                                    </p>
                                    <span class="d-block" *ngFor="let x of model?.helper; let i = index">
                                     {{x.name}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab_selector_content_div">
                        <div class="d-flex align-items-center w-100 px-3" style="gap: 15px">
                            <p class="flex-grow-1 text-center mb-0">Responsável</p>
                        </div>
                        <div class="col_hide  flex-column align-items-center justify-content-center"
                             style="display: none;">
                            <div class="d-flex align-items-end">
                                <div class="col mb-2">
                                    <p class="text-light">
                                        Nome
                                    </p>
                                    <span class="ml-2 mb-5">
                                        {{model?.createdBy?.name}}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex align-items-end">
                                <div class="col mb-2">
                                    <p class="text-light">
                                        E-mail
                                    </p>
                                    <span class="ml-2">
                                        {{model?.createdBy?.email}}
                                    </span>
                                    <p class="text-light">
                                        Telefone
                                    </p>
                                    <span class="ml-2">
                                        {{model?.createdBy?.phone}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="downArrow" class="d-flex justify-content-center align-items-center animation" style="margin-top: -20px">
        <svg-icon (click)="dropCol()" class="is-cursor" src="assets/svg/downarrow.svg"></svg-icon>
    </div>
    <div class="page-padding_lg">
        <ng-container *ngIf="!isNullOrUndefined(thumbEquipment) || !isNullOrUndefined(modelEquipament)">
            <h2 class="page-title mb-3">Ferramentas - {{model.status === 'check' ? 'saída' : 'entrada'}}</h2>
            <div class="row mb-3" style="row-gap: 30px">
                <div class="col">
                    <div *ngIf="isNullOrUndefined(thumbEquipment)" class="uploaded is-cursor mb-0"
                         (click)="showModal('uploadEquipment')"
                         style="height: 100%;">
                        <h5>Foto</h5>
                        <ul>
                            <li>
                                Nenhuma imagem anexada
                            </li>

                        </ul>
                    </div>
                    <div *ngIf="!isNullOrUndefined(thumbEquipment)" class="uploaded mb-0" style="height: 100%;">
                        <div class="d-flex align-items-center">
                            <h5 class="mb-0 flex-grow-1">Foto</h5>
                            <ng-container *ngIf="isNullOrUndefined(paramSub)">
                                <a href="javascript:void(0)" (click)="thumbEquipment = null;fileEquipment = null">
                                    <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </ng-container>
                        </div>
                        <ul>

                            <li>
                                <img style="width: 100%;" src="{{thumbEquipment}}" alt="seal">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-xxl-8">
                    <div class="row" style="row-gap: 20px;">
                        <div class="col-sm-12" *ngFor="let x of modelEquipament">
                            <div class="tools_div">
                                <span>{{x.name}}</span>
                                <div class="quantity">
                                    <a href="javascript:void('');" *ngIf="flagEdit"
                                       (click)="handlerItemQuantity('minus', x)">
                                        <svg-icon src="assets/svg/minus.svg"></svg-icon>
                                    </a>
                                    <div class="number">
                                        {{x.quantity}}
                                    </div>
                                    <a href="javascript:void('');" *ngIf="flagEdit"
                                       (click)="handlerItemQuantity('add', x)">
                                        <svg-icon src="assets/svg/add.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-12" *ngIf="model.status ==='check'">
                    <h2 class="page-title">{{'others'|translate}}</h2>
                </div>
                <div class="table-responsive" *ngIf="model.status === 'check'">
                    <table class="table table_regular custom-table-width">
                        <thead>
                        <tr>
                            <th scope="col">Data</th>
                            <th scope="col">Item</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="isNullOrUndefined(modelImspectFilter)">
                            <td colspan="7" class="text-center">
                                Não há outros objetos a serem exibidos
                            </td>
                        </tr>
                        <tr *ngFor="let x of modelImspectFilter">
                            <td>{{x.createdAt | date: 'dd/MM/yyyy'}}</td>
                            <td>{{x.others}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="divider"></div>


            </div>
        </ng-container>

        <div *ngIf="this.model.status === 'inspect'">
            <h2 class="page-title mb-3">{{'others'|translate}}</h2>
            <div class="input-material">
            <textarea id="newMessage" rows="2" class="form-control" type="text" [(ngModel)]="modelInspectOthers"
                      [readOnly]="flagFinish"
                      required></textarea>
                <label for="newMessage" [ngClass]="{'disabled' : flagFinish}">
                    <span>Escreva aqui</span>
                </label>
            </div>
        </div>

        <div *ngIf="model.status === 'check'">
            <h2 class="page-title mb-3">Observações</h2>
            <div class="input-material">
            <textarea id="newObserv" rows="2" class="form-control" type="text" [(ngModel)]="modelInspectObs"
                      [readOnly]="flagFinish"
                      required></textarea>
                <label for="newObserv" [ngClass]="{'disabled' : flagFinish}">
                    <span>Escreva aqui</span>
                </label>
            </div>
        </div>

<!--        <div class=" section_top p-3 rounded">-->
<!--            <div class="row">-->
<!--                <div class="col-12 col-md-3 d-flex flex-column justify-content-around">-->
<!--                    <div>-->
<!--                        <h2 class="page-title mb-3">{{'prestadorservico'|translate}}</h2>-->
<!--                        <span>{{model.name}}</span>-->
<!--                    </div>-->
<!--                    <p>Assinatura Digital</p>-->
<!--                </div>-->

<!--                <div class="col-12 col-md-9">-->
<!--                    <div class="signature" (click)="actionSignature()">-->
<!--                        <p class="is-cursor" *ngIf="!flagFinish">{{'desenheassinatura'|translate}}</p>-->
<!--                        <div class="signature-thumbnail" [ngClass]="{'d-none': isNullOrUndefined(imageSignature)}">-->
<!--                            <img id="sig-image" [src]="imageSignature" alt="assinatura"/>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="d-flex justify-content-around mt-3" *ngIf="model.status !== 'finished' && !flagFinish">
            <button class="btn btn-danger w-25" (click)="actionFinish()">Encerrar</button>
            <button class="btn btn-warning w-25" (click)="actionSave()">Liberar</button>
        </div>
    </div>

</div>

<!--<div class="paint-container" [ngClass]="{'is-open' : flagSignature}">-->
<!--    <div id="sketch" class="paint-box">-->
<!--        <canvas id="sig-canvas" style="object-fit: cover;"></canvas>-->
<!--        <div class="signature">-->
<!--            <p>{{'desenheassinaturadedo'|translate}}</p>-->
<!--            <button class="clear" id="sig-clearBtn">-->
<!--                <svg-icon src="assets/svg/cross.svg"></svg-icon>-->
<!--                <span>{{'limpar'|translate}}</span>-->
<!--            </button>-->
<!--        </div>-->
<!--        <div class="page-content__buttons button_back">-->
<!--            <button (click)="actionSignature()">-->
<!--                <svg-icon src="assets/svg/arrow.svg"></svg-icon>-->
<!--            </button>-->
<!--        </div>-->
<!--        <div class="page-content__buttons button_save">-->
<!--            <button class="btn btn-warning" id="sig-submitBtn">{{'salvar'|translate}}</button>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="modal fade" id="updateProvider" tabindex="-1" role="dialog"
     aria-labelledby="inspectTruck"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">Informações</h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row">
                    <div class="col-8">
                        <div class="input-material">
                            <input id="name" [(ngModel)]="modelUpdate.name" class="form-control" type="text" required
                                   [maxLength]="250"/>
                            <label for="name">Nome do prestador</label>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="input-material">
                            <input id="cpf" [(ngModel)]="modelUpdate.cpf" class="form-control" type="text" required
                                   [mask]="this.translate.currentLang==='pt'? '000.000.000-00' : ''"/>
                            <label for="cpf">{{'cpf'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="input-material">
                            <input id="licensePlate" [(ngModel)]="modelUpdate.licensePlate" class="form-control"
                                   type="text" required maxlength="7"/>
                            <label for="licensePlate">Placa do Veículo</label>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="input-material">
                            <input id="vehicle" [(ngModel)]="modelUpdate.vehicle" class="form-control" type="text"
                                   required/>
                            <label for="vehicle">Veículo</label>
                        </div>
                    </div>

                    <br>
                    <div class="divider"></div>
                    <br>

                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input id="helperName" maxlength="100" [(ngModel)]="helper.name" name="name"
                                           class="form-control"
                                           type="text"
                                           required/>
                                    <label for="helperName">{{'nomeajudante'|translate}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input id="helperRG" [(ngModel)]="helper.doc" name="name"
                                           class="form-control" type="text"
                                           required
                                           [mask]="this.translate.currentLang==='pt'? '000.000.000-00' : ''"/>
                                    <label for="cpf">{{'cpf'|translate}}</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <button (click)="pushHelpersList()" class="btn btn-block btn-secondary">
                                    <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                                    <span class="pl-2">{{'adicionar'|translate}}</span>
                                </button>
                            </div>

                            <div class="col-12" *ngIf="!isNullOrUndefined(modelUpdate.helper)">
                                <div class="table-responsive">
                                    <table class="table table_regular">
                                        <thead>
                                        <tr>
                                            <th scope="col">{{'nomeajudante'|translate}}</th>
                                            <th scope="col">ID</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let h of modelUpdate.helper;let i = index">
                                            <td>{{h.name}}</td>
                                            <td>{{this.translate.currentLang === 'pt' ? (h.doc| mask:'000.000.000-00') : (h.doc)}}</td>
                                            <td>
                                                <svg-icon (click)="removeHelpers(h.id,i)"
                                                          src="assets/svg/close-circle.svg"></svg-icon>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <a href="javascript:void(0)" (click)="saveProvider()"
                           class="btn btn-block btn-secondary">{{'salvar'|translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-crop-image [cropId]="'uploadEquipment'"
                [cropAspectRatio]="4/2"
                (croppedImageResult)="setCroppedEquipment($event)"></app-crop-image>
