<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon [svgStyle]="{'fill': '#0d2e5d'}" src="assets/svg/occurrence.svg"></svg-icon>
            <h1 class="page-title" style="order: initial">
                <strong>{{'occurrencesReport'|translate}}</strong>
            </h1>

            <div class="d-flex flex-wrap" style="gap: 20px">
                <a class="btn btn-gray-fill flex-grow-1" *ngIf="exportExcel" (click)="actionExcel()">
                    <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                    {{'exportarexcel'|translate}}
                </a>
                <a [routerLink]="'/occurrence/new'" *ngIf="actionNew" class="btn btn-warning flex-grow-1">
                    <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                    {{'adicionar'|translate}}
                </a>
            </div>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input [(ngModel)]="filter.name" id="name" name="name" class="form-control"
                       type="text"
                       required/>
                <label for="name">{{'nomevitima'|translate}}</label>
            </div>
            <div class="input-material">
                <input [(ngModel)]="filter.code" id="code" class="form-control" type="text"
                       required/>
                <label for="code">{{"nbio" | translate}}</label>
            </div>
            <div class="input-material">
                <input id="start" class="form-control js-daterangepicker" type="text"
                       required/>
                <label for="start">{{'timecourse'|translate}}</label>
            </div>
            <div class="input-material select">
                <select name="status" id="status" [(ngModel)]="filter.status"
                        class="form-control" required>
                    <option [ngValue]="undefined">{{'all'|translate}}</option>
                    <option value="pending">{{'pending'|translate}}</option>
                    <option value="done">{{'done'|translate}}</option>
                </select>
                <label for="status">{{"settings.status" | translate}}</label>
            </div>
            <div class="input-material select" *ngIf="labelCountry">
                <select name="country" id="country" (change)="getUnities()"
                        [(ngModel)]="selectedCountryId"
                        class="form-control" required>
                    <option [ngValue]="undefined">{{'all'|translate}}</option>
                    <option *ngFor="let c of countries" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="country">{{'country'|translate}}</label>
            </div>
            <div class="input-material select" *ngIf="labelUnity">
                <select name="unity" id="unity"
                        [(ngModel)]="filter.unityId"
                        class="form-control" required>
                    <option [ngValue]="undefined">{{'occurrence.selectunit'|translate}}</option>
                    <option *ngFor="let c of unities" value="{{c.id}}">{{c.name}}</option>
                </select>
                <label for="unity">{{'unidade'|translate}}</label>
            </div>
            <a (click)="onFilter()" class="btn btn-primary btn-lg text-white">{{'settings.dosearch'|translate}}</a>
        </div>
        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th scope="col">BIO Nº</th>
                    <th scope="col">{{'country'|translate}}</th>
                    <th class="text-center" scope="col">{{'unidade'|translate}}</th>
                    <th class="text-center" scope="col">{{'nomevitima'|translate}}</th>
                    <th class="text-center" scope="col">{{'date'|translate}}</th>
                    <th class="text-center" scope="col">{{'term'|translate}}</th>
                    <th class="text-center" scope="col">{{"settings.status" | translate}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center" *ngIf="isNullOrUndefined(result?.occurrence)">
                    <td colspan="8" class="text-center">
                        <div>
                            {{'nenhumaocorrencia'|translate}}
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let o of result.occurrence|
                paginate: {itemsPerPage: result.PageSize,
                currentPage: result.currentPage,
                totalItems: result.totalCount }">
                    <td>{{o.code}}</td>
                    <td>
                        <img [src]="'assets/images/country/' + o.unity?.country?.flag + '.svg'"
                             width="30px" alt="flag"/>
                    </td>
                    <td class="text-center">{{o.unity.name}}</td>
                    <td class="text-center">
                      <span *ngFor="let v of getVictim(o.users)">
                          {{v.name}}
                        </span>
                    </td>

                    <td class="text-center">{{o.date| date: 'dd/MM/yyyy'}}</td>
                    <td class="text-center">{{o.mitigatingDeadLine | date: 'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <div class="text-center">
                            <div class="d-flex justify-content-center">
                                <span>{{getTranslate(o.status)}}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table_action">
                            <a *ngIf="o.status === 'done'" href="javascript:void('');"
                               [routerLink]="'/occurrence/detail/' + o.id">
                                <svg-icon src="assets/svg/eyes.svg"></svg-icon>
                            </a>
                        </div>
                        <div class="table_action" *ngIf="actionUpdate">

                            <a *ngIf="o.status === 'pending'"
                               [routerLink]="'/occurrence/new/' + o.id">
                                <svg-icon src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <span *ngIf="o.status === 'pending'" (click)="actionRemove(o.id)"><svg-icon
                                    src="assets/svg/close-red.svg" style="cursor: pointer"></svg-icon></span>
                        </div>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
