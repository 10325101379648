import {AfterViewInit, Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {QueryRef} from 'apollo-angular';
import {
    Bloc,
    Camera,
    Country,
    GetBlocByUnityGQL,
    GetBlocByUnityQuery,
    GetCountriesGQL,
    GetCountriesQuery,
    GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery,
    SearchCameraInput,
    SearchCameraQuery,
    Unity,
    SearchCameraGQL,
    CreateCameraGQL,
    UpdateCameraGQL,
    RemoveCameraGQL,
    CreateCameraInput,
    UpdateCameraInput, ChecklistBlocDto, CameraPage, User
} from '../../../../generated/graphql';
import {map, switchMap} from "rxjs/operators";
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss']
})
export class CameraComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    user = new User();
    countries: Country[] = [];
    countryQuery: QueryRef<GetCountriesQuery>;

    // create
    modelCreate: Camera = new Camera();
    selectedCreateUnityId: string;
    selectedCreateBlocId: string;
    selectedCreateCountryId: string;

    createUnities: Array<Unity> = [];
    createUnityQuery: QueryRef<GetUnitiesByCountryQuery>;

    createBlocs: Array<Bloc> = [];
    createBlocsByUnityQuery: QueryRef<GetBlocByUnityQuery>;

    // list
    filterUnityId: string;
    filterBlocId: string;
    filterCountryId: string;

    filter: SearchCameraInput = new SearchCameraInput();
    searchCameraQuery: QueryRef<SearchCameraQuery>;
    cameras: CameraPage = new CameraPage();

    filterUnities: Array<Unity>;
    filterUnityQuery: QueryRef<GetUnitiesByCountryQuery>;

    filterBlocs: Array<Bloc>;
    filterBlocsByUnityQuery: QueryRef<GetBlocByUnityQuery>;

    constructor(
        private searchCameraGQL: SearchCameraGQL,
        private createCamera: CreateCameraGQL,
        private updateCamera: UpdateCameraGQL,
        private removeCamera: RemoveCameraGQL,
        private getCountriesGQL: GetCountriesGQL,
        private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
        private getBlocByUnityGQL: GetBlocByUnityGQL,
        public router: Router,
        public userService: UserService,
        public translate: TranslateService) {
        super(router, translate);
    }

    ngOnDestroy(): void {
        this.destroyModal('cameraModal');
    }

    ngOnInit(): void {
        this.getCountries();
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.createUnityQuery = this.getUnitiesByCountryGQL.watch({countryId: this.isNullOrUndefined(this.selectedCreateCountryId) ? '' : this.selectedCreateCountryId});
        this.createUnityQuery.valueChanges.subscribe(({data}) => {
            this.createUnities = data.getUnitiesByCountry as Array<Unity>;

            this.createBlocsByUnityQuery = this.getBlocByUnityGQL.watch({unityId: this.isNullOrUndefined(this.selectedCreateUnityId) ? '' : this.selectedCreateUnityId});
            this.createBlocsByUnityQuery.valueChanges.subscribe(({data}) => {
                this.createBlocs = data.blocByUnity as Array<Bloc>;
            });
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            const c = data.countries as Array<Country>;
            const x = JSON.stringify(c);
            this.countries = JSON.parse(x) as Array<Country>;
            this.listCameras();
        });
    }

    getUnitiesFilter() {
        this.filterUnityId = null;
        this.filterBlocId = null;
        this.filterUnities = [];
        this.filterBlocs = [];
        if (this.isNullOrUndefined(this.filterCountryId)) {
            return;
        }
        this.filterUnityQuery = this.getUnitiesByCountryGQL.watch({countryId: this.filterCountryId});
        this.filterUnityQuery.valueChanges.subscribe(({data}) => {
            this.filterUnities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    getBlocsFilter() {
        this.filterBlocId = null;
        this.filterBlocs = [];
        if (this.isNullOrUndefined(this.filterUnityId)) {
            return;
        }
        this.filterBlocsByUnityQuery = this.getBlocByUnityGQL.watch({unityId: this.filterUnityId});
        this.filterBlocsByUnityQuery.valueChanges.subscribe(({data}) => {
            this.filterBlocs = data.blocByUnity as Array<Bloc>;
        });
    }

    onFilter() {
        this.filter.bloc = this.filterBlocId;
        this.filter.unity = this.filterUnityId;
        this.filter.country = this.filterCountryId;
        this.filter.page = 1;
        this.searchCameraQuery.refetch({data: this.filter});
    }

    create() {
        this.modelCreate = new Camera();
        this.selectedCreateUnityId = null;
        this.selectedCreateBlocId = null;
        this.selectedCreateCountryId = null;
        this.createUnities = [];
        this.createBlocs = [];
        this.showModal('cameraModal');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.modelCreate.place, text: `Local<br>`},
            {value: this.selectedCreateCountryId, text: `País<br>`},
            {value: this.selectedCreateUnityId, text: `Unidade<br>`},
            {value: this.selectedCreateBlocId, text: `Bloco<br>`},
            {value: this.modelCreate.type, text: `Tipo<br>`},
            {value: this.modelCreate.mode, text: `Modo de Gravação<br>`},
            {value: this.modelCreate.time, text: `Tempo de Gravação<br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.modelCreate.id)) {
            this.actionCreateCamera();
        } else {
            this.actionUpdateCamera();
        }
    }

    actionCreateCamera() {
        const data: CreateCameraInput = {
            place: this.modelCreate.place,
            type: this.modelCreate.type,
            status: !this.isNullOrUndefined(this.modelCreate.status) ? (this.modelCreate.status ? 'ok' : 'error') : 'error',
            mode: this.modelCreate.mode,
            time: this.modelCreate.time,
            bloc: {
                id: this.selectedCreateBlocId,
            }
        };
        this.createCamera.mutate({data: data}).subscribe((result) => {
            this.modelCreate = new Camera();
            this.searchCameraQuery.refetch({data: this.filter});
            this.closeModal('cameraModal');
            this.showMessage(this.translate.instant('success'), this.translate.instant('cameracriada'), 'success');
        });
    }

    actionUpdateCamera() {
        const data: UpdateCameraInput = {
            id: this.modelCreate.id,
            mode: this.modelCreate.mode,
            time: this.modelCreate.time,
            place: this.modelCreate.place,
            type: this.modelCreate.type,
            status: this.modelCreate.status,
            bloc: {
                id: this.selectedCreateBlocId,
            }
        };
        this.updateCamera.mutate({data: data}).subscribe(() => {
            this.modelCreate = new Camera();
            this.searchCameraQuery.refetch({data: this.filter});
            this.showMessage(this.translate.instant('success'), this.translate.instant('cameraeditada'), 'success');
            this.closeModal('cameraModal');
        });
    }

    actionEdit(camera: Camera) {
        this.modelCreate = Object.assign({}, camera);
        this.selectedCreateUnityId = this.modelCreate.bloc.unity.id;
        this.selectedCreateBlocId = this.modelCreate.bloc.id;
        this.selectedCreateCountryId = this.modelCreate.bloc.unity.country.id;
        this.createUnityQuery.refetch({countryId: this.selectedCreateCountryId});
        this.createBlocsByUnityQuery.refetch({unityId: this.selectedCreateUnityId});
        this.showModal('cameraModal');
    }

    listCameras() {
        this.searchCameraQuery = this.searchCameraGQL.watch({data: this.filter});
        this.searchCameraQuery.valueChanges.subscribe(({data}) => {
            this.cameras = data.searchCamera as CameraPage;
        });
    }

    getUnitiesForCreate() {
        this.selectedCreateBlocId = null;
        this.selectedCreateUnityId = null;
        this.createBlocs = [];
        this.createUnities = [];
        if (this.isNullOrUndefined(this.selectedCreateCountryId)) {
            return;
        }
        this.createUnityQuery.refetch({countryId: this.selectedCreateCountryId});
    }

    getBlocsForCreate() {
        this.createBlocs = [];
        this.selectedCreateBlocId = null;
        if (this.isNullOrUndefined(this.selectedCreateUnityId)) {
            return;
        }
        this.createBlocsByUnityQuery.refetch({unityId: this.selectedCreateUnityId});
    }

    actionDelete(cameraId) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('excluircamera'), () => {
            this.removeCamera.mutate({id: cameraId}).subscribe((result) => {
                this.searchCameraQuery.refetch({data: this.filter});
                super.showMessage(this.translate.instant('success'), this.translate.instant('cameraexcluida'), 'success');
            });
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.searchCameraQuery.refetch({data: this.filter});
    }
}
