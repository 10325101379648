<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <svg-icon src="assets/svg/policies.svg"></svg-icon>
            <h1 class="page-title">{{'poloiticaeprocedimento'|translate}}</h1>
            <div class=" d-flex flex-wrap">
                <a (click)="newPolicy()" *ngIf="actionNew"
                   class="btn btn-secondary flex-grow-1">
                    <svg-icon class="mr-1" src="assets/svg/plus-circle.svg"></svg-icon>{{'policiesprocedures.newfile'|translate}}</a>
            </div>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>{{'busca'|translate}}</span>
                </label>
            </div>
            <div class="input-material select" *ngIf="labelCountry">
                <select id="country" [(ngModel)]="filter.country" class="form-control" required>
                    <option [ngValue]="undefined">{{'all'|translate}}</option>
                    <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
                </select>
                <label for="country">{{'country'|translate}}</label>
            </div>
            <a class="btn btn-primary btn-lg text-white offset-md-2" (click)="onSearch()">{{'settings.dosearch'|translate}}</a>
        </div>

        <div class="row" style="row-gap: 30px">

            <div class="col-12 col-md-3" *ngFor="let p of policies">
                <div class="card">
                    <div class="card-body card-policies">
                        <p class="card-policies-p">{{p.title}}</p>
                        <p class="card-policies-2p">{{p.description}}</p>
                        <div class="row justify-content-between mx-0">
                            <p class="card-policies-date">{{'policiesprocedures.authorizedin'|translate}} <strong>{{p.createdAt|date:'MM/yyyy'}}</strong>
                            </p>
<!--                            <a href="{{p.link}}" target="_blank">-->
<!--                                <svg-icon src="assets/svg/submit.svg"></svg-icon>-->
<!--                            </a>-->
                        </div>
                        <div class="row justify-content-around" *ngIf="actionUpdate">
                            <a href="{{p.link}}" target="_blank">
                                <svg-icon src="assets/svg/submit.svg"></svg-icon>
                            </a>|
                            <svg-icon class="is-cursor"  (click)="editPolicy(p)" src="assets/svg/edit.svg"></svg-icon>
                            |
                            <svg-icon class="is-cursor" (click)="actionDelete(p.id)"
                                      src="assets/svg/close-red.svg"></svg-icon>
                        </div>
                        <div class="row justify-content-around" *ngIf="!actionUpdate">
                            <a href="{{p.link}}" target="_blank">
                                <svg-icon src="assets/svg/submit.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isNullOrUndefined(policies)" class="col-12 d-flex justify-content-center my-4">{{'nofound'|translate}}
            </div>
        </div>
    </div>
</div>

<!--Modal-->

<div class="modal fade" id="policiesModal" tabindex="-1" role="dialog" aria-labelledby="userModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{isNullOrUndefined(policy.id) ? 'Editar Arquivo' : 'Novo Arquivo'|translate}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <!--Modal Body-->
            <div class="my-5 px-3 px-md-5">
                <div class="row">
                    <div class="col-12">
                        <div class="input-material">
                            <input id="title" [(ngModel)]="policy.title" class="form-control" type="text" required/>
                            <label for="title">
                                <span>*{{'policiesprocedures.title'|translate}}</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material">
                            <input id="description" [(ngModel)]="policy.description" class="form-control" type="text"
                                   required/>
                            <label for="description">
                                <span>*{{'policiesprocedures.description'|translate}}</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="input-material select" *ngIf="labelCountry">
                            <select id="countryModal" [(ngModel)]="countryId" class="form-control" required>
                                <option disabled value hidden selected></option>
                                <option *ngFor="let c of countries" [value]="c.id">{{c.name}}</option>
                            </select>
                            <label for="countryModal">*{{'country'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material">
                            <input id="link" [(ngModel)]="policy.link" class="form-control" type="text" required/>
                            <label for="link">
                                <span>*Link</span>
                            </label>
                        </div>
                    </div>
                </div>
                <p class="policies-file">{{'policiesprocedures.languageadded'|translate}}</p>
                <section class="policies_card d-flex justify-content-between align-items-center">
                    <span class="policies-country">Português</span>
                    <label class="switch mb-0">
                        <input type="checkbox" [(ngModel)]="policy.isPortuguese" name="checkbox">
                        <span class="slider round"></span>
                    </label>
                    <span class="policies-country">Espanhol</span>
                    <label class="switch mb-0">
                        <input type="checkbox" [(ngModel)]="policy.isSpanish" name="checkbox">
                        <span class="slider round"></span>
                    </label>
                </section>
                <a (click)="actionSave()" class="btn btn-warning btn-block mt-4">{{'salvar'|translate}}</a>
            </div>

        </div>
    </div>
</div>
