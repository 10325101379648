import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    SearchUnityInput, Country, Unity, GetUnitiesGQL,
    GetCountriesQuery, GetCountriesGQL, SearchUnityGQL,
    SearchUnityQuery, CreateUnityInput, UpdateUnityInput, CreateUnityGQL,
    UpdateUnityGQL, CreateUnityCountryInput, RemoveUnityGQL, User, UnityPage
} from '../../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {UserService} from '../../../service/user.service';

declare var $;
declare var moment;

@Component({
    selector: 'app-unity',
    templateUrl: './unity.component.html',
    styleUrls: ['./unity.component.scss']
})
export class UnityComponent extends BaseComponent implements OnInit, OnDestroy {
    user = new User();
    unities: UnityPage = new UnityPage();

    model: Unity = new Unity();
    selectedCountryID: string;

    searchUnityQuery: QueryRef<SearchUnityQuery>;
    countryQuery: QueryRef<GetCountriesQuery>;
    countries: Country[] = [];

    unity: any;
    filter = new SearchUnityInput();

    constructor(public router: Router,
                private searchUnityGQL: SearchUnityGQL,
                private getUnityGQL: GetUnitiesGQL,
                private createUnityGQL: CreateUnityGQL,
                private updateUnityGQL: UpdateUnityGQL,
                private getCountriesGQL: GetCountriesGQL,
                private removeUnityGQL: RemoveUnityGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.filter = {
            start: moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format(),
            end: moment().endOf('day').utcOffset(0, true).format()
        };
        this.setDatePicker('js-datepangepicker', (start, end, label) => {
            this.filter.start = start.utcOffset(0, true).format();
            this.filter.end = end.utcOffset(0, true).format();
        });
        this.getCountry();
        this.getUnity();
    }

    ngOnDestroy(): void {
        const elModalUnity = $('#unityModal');
        if (elModalUnity[0]) {
            elModalUnity.remove();
        }
    }

    create() {
        this.model = new Unity();
        this.selectedCountryID = null;
        this.showModal('unityModal');
    }

    onFilter() {
        this.searchUnityQuery.refetch({input: this.filter});
    }

    getCountry() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries;
        });
    }

    getUnity() {
        this.searchUnityQuery = this.searchUnityGQL.watch({input: this.filter});
        this.searchUnityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.searchUnity as UnityPage;
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome<br>'},
            {value: this.selectedCountryID, text: 'País<br>'},
            {value: this.model.city, text: 'Cidade<br>'}
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('atencao'), `${this.translate.instant('preenchacamposcorretamente')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreatedUnity();
        } else {
            this.actionUpdateUnity();
        }
    }

    actionCreatedUnity() {
        const data: CreateUnityInput = {
            name: this.model.name,
            city: this.model.city,
            country: {
                id: this.selectedCountryID,
            }
        };
        this.createUnityGQL.mutate({data: data}).subscribe(() => {
            this.model = new Unity();
            this.searchUnityQuery.refetch();
            this.showMessage(this.translate.instant('success'), this.translate.instant('unidadecriadacomsucesso'), 'success');
            this.closeModal('unityModal');
        });
    }

    actionUpdateUnity() {
        const data: UpdateUnityInput = {
            id: this.model.id,
            name: this.model.name,
            city: this.model.city,
            country: {
                id: this.selectedCountryID,
            }
        }
        this.updateUnityGQL.mutate({data: data}).subscribe(() => {
            this.model = new Unity();
            this.searchUnityQuery.refetch();
            this.showMessage(this.translate.instant('success'), this.translate.instant('unidadeeditadacomsucesso'), 'success');
            this.closeModal('unityModal');
        });
    }

    actionEdit(unity: Unity) {
        Object.assign(this.model, unity);
        this.selectedCountryID = this.model.country.id;
        this.showModal('unityModal');
    }

    actionDelete(unityId) {
        super.confirmMessage(this.translate.instant('atencao'), this.translate.instant('desejarealmenteexcluiressaunidade'), () => {
            this.removeUnityGQL.mutate({id: unityId}).subscribe((result) => {
                this.searchUnityQuery.refetch();
                super.showMessage(this.translate.instant('success'), this.translate.instant('unidadedeletadacomsucesso'), 'success');
            });
        });
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.getUnity();
    }
}
