<div class="modal fade" id="commentModal" tabindex="-1" role="dialog"
     aria-labelledby="commentModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    Novo {{'comentário' | translate}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body modal_small" style="min-height: initial">
                <div>
                    <!--TEXT AREA-->
                    <div class="input-material">
                                <textarea name="comment" [(ngModel)]="commentModel.comment"
                                          class="input-material form-control"
                                          required maxlength="250">
                                </textarea>
                        <label>{{'Escreva aqui' | translate}}</label>
                    </div>
                    <!--DATA-->
                    <div class="card shadow-none border-0 p-3" style="background-color: #F5F3EC">
                        <div class="row align-items-center">
                            <div class="col-md-4">
                                <p class="modal_text mb-0">Defina o tempo limite para a conclusão do problema.</p>
                            </div>
                            <div class="col-md">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="input-material mb-0">
                                            <input id="userNamee" class="form-control dateLimit" type="text"/>
                                            <label for="userNamee">
                                                <span>{{'Data/Hora'|translate}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="javascript:void('modal')" (click)="actionAddComment()"
                       class="btn btn-block btn-secondary mt-3">
                        {{'salvar'|translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
