<div class="truck page">
    <div class="page-padding">
        <div class="page-header-custom-secondary">
            <svg-icon routerLink="/truck" class="is-cursor" src="assets/svg/back.svg"></svg-icon>
            <h2 class="page-title"><strong>{{'nova'|translate}}</strong>&nbsp; <span>{{'inspecao'|translate}}</span>
            </h2>
        </div>
        <div class="container">

            <div class="card_ret row">
                <div class="col-12 col-md-3">
                    <span><strong>{{'dataehorario'|translate}}</strong> {{'doprocesso'|translate}}</span>
                </div>

                <div class="col-12 col-md-9 row">
                    <div class="col-xl-4 mb-2">
                        <div class="input-material switcher c-default">
                            <label style="line-height: 13px;" class="auto c-default"
                                   for="isTruck">{{'isTruck' | translate}}</label>
                            <div class="custom-control custom-switch">
                                <input type="checkbox" [(ngModel)]="isTruck" name="isTruck"
                                       class="custom-control-input" id="isTruck">
                                <label class="custom-control-label" for="isTruck"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="input-material">
                            <input id="start" class="form-control date-picker" type="text" required/>
                            <label for="start">
                                *{{"truck.date" | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="input-material select" *ngIf="labelUnity">
                        <select name="unity" id="unity" [(ngModel)]="unityID"
                                (change)="getDeliveriesFilter()"
                                class="form-control" required>
                            <option [ngValue]="undefined">{{'occurrence.selectunit'|translate}}</option>
                            <option *ngFor="let c of unities" value="{{c.id}}">{{c.name}}</option>
                        </select>
                        <label class="disabled" for="unity">{{'unidade'|translate}}</label>
                    </div>
                </div>
            </div>

            <div class="card_ret row">
                <div class="col-12 col-md-3">
                    <span><strong>{{'informacoes'|translate}}</strong> {{'domotorista'|translate}}</span>
                </div>
                <div class="row col-md-9">
                    <div class="col-md-6 d-flex mb-2">
                        <div class="input-material flex-grow-1">
                            <input id="driverCpf" [(ngModel)]="truck.driverCpf" name="name" class="form-control"
                                   [mask]="this.translate.currentLang==='pt'? '000.000.000-00' : ''"
                                   type="text" required/>
                            <label for="driverCpf">*{{"cpf"|translate}}</label>
                        </div>
                        <a class="btn btn-primary btn-lg ml-2 text-white" (click)="searchCPF()">
                            <svg-icon src="assets/svg/search.svg"></svg-icon>
                        </a>
                    </div>
                    <div class="col-md-6 mb-2">
                        <div class="input-material">
                            <input id="driverName" [(ngModel)]="truck.driverName" name="name" class="form-control"
                                   type="text" required/>
                            <label class="p-0" for="driverName">*{{'nomemotorista'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-xl-4 mb-2">
                        <div class="input-material">
                            <input id="company" [(ngModel)]="truck.company" name="name" class="form-control" type="text"
                                   required/>
                            <label for="company">*Empresa</label>
                        </div>
                    </div>
                    <div class="mb-2" [ngClass]="hasTrailer?'col-xl-3':'col-xl-4'">
                        <div class="input-material">
                            <input id="licensePlate" [(ngModel)]="truck.licensePlate" name="name" class="form-control"
                                   type="text" required maxlength="7"/>
                            <label style="line-height: 13px;" for="licensePlate">*{{'placacavalo'|translate}}</label>
                        </div>
                    </div>
                    <div class="mb-2" [ngClass]="hasTrailer?'col-xl-2':'col-xl-4'">
                        <div class="input-material switcher c-default">
                            <label style="line-height: 13px;" class="auto c-default"
                                   for="status">{{'temreboque'|translate}}</label>
                            <div class="custom-control custom-switch">
                                <input type="checkbox" [(ngModel)]="hasTrailer" name="hasTrailer"
                                       class="custom-control-input" id="hasTrailer">
                                <label class="custom-control-label" for="hasTrailer"></label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="hasTrailer" class="col-xl-3 mb-2">
                        <div class="input-material">
                            <input id="trailerLicensePlate" [(ngModel)]="truck.trailerLicensePlate" name="name"
                                   class="form-control" type="text" maxlength="7"
                                   required/>
                            <label style="line-height: 13px;"
                                   for="trailerLicensePlate">*{{'placareboque'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-xl-4 mb-2" *ngIf="isTruck">
                        <div class="input-material">
                            <input id="sealNumber" [(ngModel)]="truck.sealNumber" name="name" class="form-control"
                                   type="text"
                                   required/>
                            <label for="sealNumber">{{'nlacre'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-xl-4 mb-2" *ngIf="isTruck">
                        <div class="input-material switcher c-default">
                            <label style="line-height: 13px;" class="auto c-default"
                                   for="status">{{'lacreseguranca'|translate}}</label>
                            <div class="custom-control custom-switch">
                                <input type="checkbox" [(ngModel)]="truck.seal" name="status"
                                       class="custom-control-input" id="status">
                                <label class="custom-control-label" for="status"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 mb-2" *ngIf="isTruck">
                        <div class="input-material switcher c-default">
                            <label style="line-height: 13px;" class="auto c-default"
                                   for="status">{{'lacrefixado'|translate}}</label>
                            <div class="custom-control custom-switch">
                                <input type="checkbox" [(ngModel)]="truck.sealCorrect" name="status2"
                                       class="custom-control-input" id="status2">
                                <label class="custom-control-label" for="status2"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row mt-4">
                            <div class="col-xl-4">
                                <div class="uploaded is-cursor mb-0" (click)="showModal('uploadSealNumber')">
                                    <h5>{{"truck.fotolacre" | translate}}</h5>
                                    <ul>
                                        <li *ngIf="isNullOrUndefined(thumbSeal)">
                                            {{"truck.nophoto" | translate}}
                                        </li>
                                        <li *ngIf="!isNullOrUndefined(thumbSeal)">
                                            <img src="{{thumbSeal}}" alt="seal">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-4"*ngIf="!isTruck">
                                <div class="uploaded is-cursor mb-0" (click)="showModal('uploadAutoPlate')">
                                    <h5>{{"truck.fotoauto" | translate}}</h5>
                                    <ul>
                                        <li *ngIf="isNullOrUndefined(thumbAutoPlate)">
                                            {{"truck.nophoto" | translate}}
                                        </li>
                                        <li *ngIf="!isNullOrUndefined(thumbAutoPlate)">
                                            <img src="{{thumbAutoPlate}}" alt="licensePlate">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-4" *ngIf="isTruck">
                                <div class="uploaded is-cursor mb-0" (click)="showModal('uploadLicensePlate')">
                                    <h5>{{"truck.fotocavalo" | translate}}</h5>
                                    <ul>
                                        <li *ngIf="isNullOrUndefined(thumbLicensePlate)">
                                            {{"truck.nophoto" | translate}}
                                        </li>
                                        <li *ngIf="!isNullOrUndefined(thumbLicensePlate)">
                                            <img src="{{thumbLicensePlate}}" alt="licensePlate">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="hasTrailer" class="col-xl-4">
                                <div class="uploaded is-cursor mb-0" (click)="showModal('uploadTrailerLicensePlate')">
                                    <h5>{{"truck.fotoreboque" | translate}}</h5>
                                    <ul>
                                        <li *ngIf="isNullOrUndefined(thumbTrailerLicensePlate)">
                                            {{"truck.nophoto" | translate}}
                                        </li>
                                        <li class="ng-star-inserted"
                                            *ngIf="!isNullOrUndefined(thumbTrailerLicensePlate)">
                                            <img src="{{thumbTrailerLicensePlate}}" alt="seal">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="card_ret row">
                <div class="col-12 col-md-3">
                    <span><strong>{{'informacoes'|translate}}</strong> {{'ajudante'|translate}}</span>
                </div>

                <div class="row col-12 col-md-9">
                    <div class="col-12 col-md-4 mt-2">
                        <div class="input-material">
                            <input id="helperName" maxlength="100" [(ngModel)]="helper.name" name="name"
                                   class="form-control"
                                   type="text"
                                   required/>
                            <label style="line-height: 13px;" for="helperName">*{{'nomeajudante'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mt-2 ">
                        <div class="input-material">
                            <input id="helperRG" [(ngModel)]="helper.rg" name="name"
                                   [mask]="this.translate.currentLang==='pt'? '00.000.000-0' : ''"
                                   class="form-control" type="text"
                                   required/>
                            <label for="helperRG">*Nº {{'rg'|translate}}</label>
                        </div>
                    </div>
                    <button (click)="pushHelpersList()" class="btn btn-warning col-12 col-md-4 mt-2">
                        <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                        <span class="pl-2">{{'adicionar'|translate}}</span>
                    </button>
                    <div class="col-12" *ngIf="!isNullOrUndefined(helpers)">
                        <div class="table-responsive">
                            <table class="table table_regular">
                                <thead>
                                <tr>
                                    <th scope="col">{{'nomeajudante'|translate}}</th>
                                    <th scope="col">Nº {{'rg'|translate}}</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let h of helpers;let i = index">
                                    <td>{{h.name}}</td>
                                    <td>{{this.translate.currentLang==='pt'? (h.rg| mask:'00.000.000-0') : (h.rg)}}</td>
                                    <td>
                                        <svg-icon (click)="removeHelpers(h.id,i)"
                                                  src="assets/svg/close-circle.svg"></svg-icon>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

            <div class="card_ret row">
                <div class="col-12 col-md-3">
                    <span>
                        <strong>
                          {{'qualo'|translate}}
                         </strong>
                        {{'motivodaentrada'|translate}}
                     </span>
                </div>

                <div class="row col-12 col-md-9 mb-2">
                    <div class="col-12 col-md-6 mb-2">
                        <button class="btn btn-select " (click)="entranceMotive ='shipment'"
                                [ngClass]="{'active':entranceMotive=='shipment'}">
                            <span class="pl-2">{{'coleta'|translate}}</span>
                        </button>
                    </div>
                    <div class="col-12 col-md-6 mb-2">
                        <button class="btn btn-select " (click)="entranceMotive ='delivery'"
                                [ngClass]="{'active':entranceMotive=='delivery'}">
                            <span class="pl-2">{{'entrega'|translate}}</span>
                        </button>
                    </div>
                    <div class="w-100 d-flex flex-wrap align-items-center justify-content-between">
                        <div class="col-12 col-md-12 mb-2">
                            <div class="input-material" *ngIf="entranceMotive!='delivery'">
                                <input id="place" [(ngModel)]="truck.place" name="name" class="form-control"
                                       type="text"
                                       required/>
<!--                                <label *ngIf="entranceMotive == 'delivery'"-->
<!--                                       for="name">{{'localde'|translate}} {{'entrega'|translate}}</label>-->
                                <label *ngIf="entranceMotive != 'delivery'"
                                       for="name">{{'localde'|translate}} {{'coleta' |translate}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="w-100 d-flex flex-wrap align-items-center justify-content-between"
                         *ngIf="entranceMotive=='delivery'">
                        <div class="col-12 col-md-8 mb-2">
                            <div class="input-material select">
                                <select [(ngModel)]="truck.place" name="delivery" id="selectEntrega"
                                        class="form-control" required>
                                    <option *ngFor="let d of deliveries" value="{{d.value}}">{{d.value}}</option>
                                </select>
                                <label for="selectEntrega">{{'localde'|translate}} {{'entrega'|translate}}</label>
                            </div>
                        </div>

                        <div class="col-12 col-md-8 mb-2">
                            <div class="input-material">
                                <input id="name" [(ngModel)]="receipt.receipt" name="name" class="form-control"
                                       type="text"
                                       required/>
                                <label for="name">{{'numerofiscal'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <button (click)="pushReceiptList()" class="btn btn-warning w-100">
                                <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                                <span class="pl-2">{{'adicionar'|translate}}</span>
                            </button>
                        </div>
                        <div class="col-12" *ngIf="!isNullOrUndefined(receipts)">
                            <div class="table-responsive">
                                <table class="table table_regular">
                                    <thead>
                                    <tr>
                                        <th scope="col">{{'numerofiscal'|translate}}</th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let r of receipts;let i = index">
                                        <td>{{r.receipt}}</td>
                                        <td>
                                            <svg-icon (click)="removeReceipt(r.id,i)"
                                                      src="assets/svg/close-circle.svg"></svg-icon>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>

            </div>


            <div class="card_ret card_ret-truck row" *ngIf="isTruck">
                <div class="col-12 col-md-3">
                    <span>
                        <strong>
                      {{'checklistTruck'|translate}}
                         </strong>
                        {{'inspecao'|translate}}
                     </span>
                </div>

                <div class="row col-12 col-md-9 pt-3 mb-2 justify-content-end">
                    <div class="col-12 col-md-4 mb-2">
                        <button class="btn btn-select " (click)="checklistType ='vehicle'"
                                [ngClass]="{'active':checklistType=='vehicle'}">
                            <span class="pl-2">{{'cavalo'|translate}}</span>
                        </button>
                    </div>
                    <div *ngIf="this.hasTrailer" class="col-12 col-md-4 mb-2">
                        <button class="btn btn-select " (click)="checklistType ='trailer'"
                                [ngClass]="{'active':checklistType=='trailer'}">
                            <span class="pl-2">{{'reboque'|translate}}</span>
                        </button>
                    </div>


                </div>


                <div class="row col-12"
                     *ngIf="checklistType=='vehicle'">
                    <div class="col-12 col-lg-6 mb-2">
                        <img class="truck_image" src="../../../../assets/images/truck.png" alt="">
                    </div>
                    <div class="col-12 col-lg-6 mb-2">
                        <div class="truck_scroll">
                            <ng-container *ngFor="let c of checklistsVehicle,let i = index">
                                <div class="truck_scroll_card_comment">
                                    <div class="top top-inspection">
                                        <div class="d-flex">
                                            <b>{{i + 1}}</b>
                                            <p class="card_ret-truck-p">{{(c.type).toLowerCase().replace(" ", "")  | translate}}</p>
                                        </div>
                                        <div class="d-flex flex-wrap justify-content-center" style="gap: 10px">
                                            <div class="check-btn" (click)="c.statusTruck = 'accept'; removeFile(i,c)"
                                                 [ngClass]="{'btn-success': c.statusTruck === 'accept'}">
                                                <svg-icon src="assets/svg/check.svg"></svg-icon>
                                            </div>
                                            <div (click)="c.statusTruck = 'error'" class="check-btn"
                                                 [ngClass]="{'btn-danger': c.statusTruck === 'error'}">
                                                <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                                            </div>
                                            <div (click)="c.statusTruck = 'notApply'" class="check-btn"
                                                 [ngClass]="{'btn-warning': c.statusTruck === 'notApply'}">
                                                <svg-icon src="assets/svg/icon_N_A.svg"></svg-icon>
                                            </div>
                                            <div *ngIf="c.statusTruck === 'error'"
                                                 (click)="modalUploadCheckList(i,c.name)"
                                                 class="check-btn">
                                                <svg-icon [svgStyle]="{'fill':'#707070'}"
                                                          src="assets/svg/upload.svg"></svg-icon>
                                            </div>
                                            <div (click)="modalComment(i,c.name)"
                                                 *ngIf="!isNullOrUndefined(c.statusTruck)"
                                                 class="check-btn">
                                                <svg-icon src="assets/svg/comment.svg"></svg-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!isNullOrUndefined(c.photo)=== false&&c.status === 'error'||!isNullOrUndefined(c.comment)"
                                         class="row_comment d-flex" style="gap: 15px">
                                        <img style="height: 78px;margin-top: 8px;"
                                             *ngIf="c.statusTruck === 'error'&&!isNullOrUndefined(c.photo)"
                                             src="{{c.photo}}" alt="img">
                                        <p *ngIf="!isNullOrUndefined(c.comment)">{{c.comment}}</p>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap  justify-content-between" *ngIf="checklistType=='trailer'">
                    <div class="col-12 col-md-6 mb-2">
                        <img class="truck_image" src="assets/images/trailer.png" alt="">
                    </div>
                    <div class="col-12 col-md-6 mb-2">
                        <div class="truck_scroll">
                            <ng-container *ngFor="let c of checklistsTrailer,let i = index">
                                <div class="truck_scroll_card_comment">
                                    <div class="top">
                                        <div class="d-flex">
                                            <b>{{i + 1}}</b>
                                            <p>{{(c.type).toLowerCase().replace(" ", "")  | translate}}</p>
                                        </div>
                                        <div class="d-flex justify-content-center" style="gap: 10px">
                                            <div class="check-btn" (click)="c.statusTruck = 'accept'; removeFile(i,c)"
                                                 [ngClass]="{'btn-success': c.statusTruck === 'accept'}">
                                                <svg-icon src="assets/svg/check.svg"></svg-icon>
                                            </div>
                                            <div (click)="c.statusTruck = 'error'" class="check-btn"
                                                 [ngClass]="{'btn-danger': c.statusTruck === 'error'}">
                                                <svg-icon src="assets/svg/close-secondary.svg"></svg-icon>
                                            </div>
                                            <div (click)="c.statusTruck = 'notApply'" class="check-btn"
                                                 [ngClass]="{'btn-warning': c.statusTruck === 'notApply'}">
                                                <svg-icon src="assets/svg/icon_N_A.svg"></svg-icon>
                                            </div>
                                            <div *ngIf="c.statusTruck === 'error'"
                                                 (click)="modalUploadCheckList(i,c.name)"
                                                 class="check-btn">
                                                <svg-icon [svgStyle]="{'fill':'#707070'}"
                                                          src="assets/svg/upload.svg"></svg-icon>
                                            </div>
                                            <div *ngIf="!isNullOrUndefined(c.statusTruck)" class="check-btn"
                                                 (click)="modalComment(i,c.name)">
                                                <svg-icon src="assets/svg/comment.svg"></svg-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!isNullOrUndefined(c.photo === false)&&c.status === 'error'||!isNullOrUndefined(c.comment)"
                                         class="row_comment d-flex" style="gap: 15px">
                                        <img style="height: 78px;margin-top: 8px;"
                                             *ngIf="c.statusTruck === 'error'&&!isNullOrUndefined(c.photo)"
                                             src="{{c.photo}}" alt="img">
                                        <p *ngIf="!isNullOrUndefined(c.comment)">{{c.comment}}</p>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

<!--            <div class="card_ret row">-->
<!--                <div class="col-12 col-md-3">-->
<!--                    <span class="mb-3">-->
<!--                        <strong>-->
<!--                        {{'motorista'|translate}}-->
<!--                         </strong>-->
<!--                     </span>-->
<!--                    <div class="input-material mb-3">-->
<!--                        <input id="date" [(ngModel)]="truck.driverName" class="form-control" type="text" required/>-->
<!--                        <label for="date">*-->
<!--                            {{'nomemotorista'|translate}}-->
<!--                        </label>-->
<!--                    </div>-->
<!--                    <p>{{'assinaturadigital'|translate}}</p>-->
<!--                </div>-->

<!--                <div class="col-12 col-md-9">-->
<!--                    <div class="signature" (click)="actionSignature()">-->
<!--                        <p class="is-cursor">{{'desenheassinatura'|translate}}</p>-->
<!--                        <div class="signature-thumbnail"-->
<!--                             [ngClass]="{'d-none': isNullOrUndefined(imageSignature)}">-->
<!--                            <img id="sig-image" [src]="imageSignature" alt="assinatura"/>-->
<!--                        </div>-->
<!--                        <textarea id="sig-dataUrl" class="form-control d-none" rows="5"></textarea>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

            <div class="row col-12 justify-content-center">
                <div class="col-md-4 col-12">
                    <button (click)="actionSave()" class="btn btn-warning w-100">{{'salvar'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="commentTruckCreate" tabindex="-1" role="dialog"
     aria-labelledby="commentTruck"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    {{'inspecaocomentario'|translate}}
                </h5>
                <button type="button" class="pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body justify-content-between modal-body--small">
                <div class="row px-4">
                    <div class="col-12">
                        <p>
                            Descrição da divergência.
                        </p>
                        <div class="input-material">
                            <textarea id="newMessage" rows="5" [(ngModel)]="newMessage" class="form-control" type="text"
                                      required></textarea>
                            <label for="newMessage">
                                <span>{{'escrevaaqui'|translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row col-12 justify-content-center mt-3">
                    <div class="col-md-6">
                        <button (click)="saveComment()"
                                class="btn btn-block btn-warning w-100">{{'salvar'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="paint-container" [ngClass]="{'is-open' : flagSignature}">
    <div id="sketch" class="paint-box">
        <canvas id="sig-canvas" style="object-fit: cover;"></canvas>
<!--        <div class="signature">-->
<!--            <p>{{'desenheassinaturadedo'|translate}}</p>-->
<!--            <button class="clear" id="sig-clearBtn">-->
<!--                <svg-icon src="assets/svg/cross.svg"></svg-icon>-->
<!--                <span>{{'limpar'|translate}}</span>-->
<!--            </button>-->
<!--        </div>-->
<!--        <div class="page-content__buttons button_back">-->
<!--            <button (click)="actionSignature()">-->
<!--                <svg-icon src="assets/svg/arrow.svg"></svg-icon>-->
<!--            </button>-->
<!--        </div>-->
        <div class="page-content__buttons button_save">
            <button class="btn btn-warning" id="sig-submitBtn">{{'salvar'|translate}}</button>
        </div>
    </div>
</div>

<app-crop-image [cropId]="'uploadSealNumber'"
                [cropAspectRatio]="2"
                (croppedImageResult)="setCroppedSeal($event)"></app-crop-image>

<app-crop-image [cropId]="'uploadLicensePlate'"
                [cropAspectRatio]="2"
                (croppedImageResult)="setCroppedLicensePlate($event)"></app-crop-image>

<app-crop-image [cropId]="'uploadAutoPlate'"
                [cropAspectRatio]="2"
                (croppedImageResult)="setCroppedAuto($event)"></app-crop-image>

<app-crop-image [cropId]="'uploadTrailerLicensePlate'"
                [cropAspectRatio]="2"
                (croppedImageResult)="setCroppedTrailerLicensePlate($event)"></app-crop-image>

<app-crop-image [cropId]="'uploadCheckList'"
                [cropAspectRatio]="2"
                (croppedImageResult)="setCroppedCheckList($event)"></app-crop-image>
