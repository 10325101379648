import {Component, OnInit} from "@angular/core";
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {AuthInput, AuthType, CreateUserInput, ForgotPasswordGQL, LoginGQL} from '../../../generated/graphql';
import {environment} from '../../../environments/environment';

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
    modelUser: CreateUserInput = new CreateUserInput();
    croppedImage: any = null;

    modelIdentifier = '';
    modelPassword = '';

    thumpPath: string | ArrayBuffer;
    file: File = null;
    model: any = {
        code: '',
        identifier: '',
        redirectUri: window.location.protocol + '//' + window.location.host + window.location.pathname
    };


    token = '';
    error = '';
    modelForgotCPF = '';

    constructor(public router: Router,
                public route: ActivatedRoute,
                public translate: TranslateService,
                public userService: UserService,
                private forgotPasswordGQL: ForgotPasswordGQL,
                public login: LoginGQL) {
        super(router, translate);
    }

    ngOnInit(): void {
    }


    onLogin() {

        const arrValidateFields = [
            {value: this.modelIdentifier, text: `${this.translate.instant('ID')}<br>`},
            {value: this.modelPassword, text: `${this.translate.instant('Senha')}<br>`}
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }

        let input: AuthInput = {
            identifier: this.modelIdentifier,
            password: this.modelPassword,
        }

        this.login.mutate({data: input}).subscribe(
            (data) => {
                const a: any = data;
                this.saveLocalUser(a.data.login);
            }, (err) => super.onError(err)
        );
    }

    saveLocalUser(data) {
        const token = data as AuthType;
        this.userService.updateUser(token.user, true, token.token);
        this.router.navigate(['/']);
    }

    actionForgotPassword() {
        const arrValidateFields = [
            {value: this.modelForgotCPF, text: 'ID <br />'}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningFillCorrect')} <br><br> ${stringError}`, 'warning');
            return;
        }
        if (!this.isCPFValid(this.modelForgotCPF)) {
            return;
        }
        this.forgotPasswordGQL.mutate({input: this.modelForgotCPF}).subscribe((result) => {
            this.showMessage(this.translate.instant('baseComponent.success'),
                this.translate.instant('baseComponent.warningEmailSend'), 'success');
            this.closeModal('modalForgot');
        });
    }

    onClearModelUser(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.modelUser[str] = '';
            }
        });
    }

    redirectAuth() {
        const clientId = environment.client_id;
        const tenantId = environment.tenant_id;
        const p = window.location.pathname.split('/');
        const virtualPath = p.length >= 3 ? '/' + p[1] : '';
        const redirectUrl = window.location.origin + virtualPath + '/authenticate';
        window.location.href = 'https://login.microsoftonline.com/' + tenantId + '/oauth2/v2.0/authorize?client_id=' + clientId + '&response_type=code&redirect_uri=' + redirectUrl + '&response_mode=query&scope=openid%20offline_access%20https://graph.microsoft.com/mail.read&state=12345';
    }
}
